/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component } from 'react';
import { css } from 'emotion';
import axios from 'axios';
import Button from '../../ui/Button';
import BinaryRadioButton from '../ui/BinaryRadioButton';
import ActionButton from '../../ui/ActionButton';
import colors from '../../../css/colors';
import { toolTipWrap, toolTip } from '../SharedFormStyles';
import { showNextButton, getNextLink } from '../FormStepperFunctions';
import withProperty from '../WithProperty';
import MultiFileUpload from '../ui/MultiFileUpload';
import getBase64 from '../../FileUploads';
import SaveAndHoldButton from '../SaveAndHoldButton';
import SubmitFormButton from '../SubmitFormButton';
import withUser from '../../../lib/WithUser';
import AssessToInput from '../AssessToInput';
import GoToProofingWorkspace from '../GoToProofingWorkspace';
import WithDocumentStation from '../ui/DocumentStation/WithDocumentStation';
import ReusableTopOfForm from '../ReusableTopOfForm';

const searchTypeId = 60;

class EcbSearch extends Component {
  state = {
    hasEcb: false,
    loaderStatus: false,
    attachedFiles: [],
    owners: '',
    searchId: 0,
    additionalInfo: '',
  };

  componentDidMount = async () => {
    axios
      .get(`/api/searches/GetSearchByType/${searchTypeId}/${+this.props.match.params.propertyid}`)
      .then(({ data }) => {
        this.props.setSearchId(data.id);
        this.setState({ searchId: data.id });
      });
    const { data: savedData } = await axios.get(
      `/api/searches/getSaved?propertyId=${+this.props.match.params.propertyid}&searchTypeId=${searchTypeId}`
    );

    if (savedData) {
      this.setState({ ...savedData });
    }
  };

  onFilesAdded = files => {
    this.setState({ attachedFiles: [...this.state.attachedFiles, ...files] });
  };

  onFileRemoved = file => {
    this.setState({
      attachedFiles: this.state.attachedFiles.filter(f => f !== file),
    });
  };

  handlePreview = async () => {
    const { data: html } = await axios.post('/api/searches/CreatePDF', {
      template: 'ecbSearch',
      data: await this.getFormContent(),
    });
    this.setState({ pdfHtml: html });
  };

  handleDownload = async () => {
    this.setState({ loaderStatus: true });
    await axios.post('/api/searches/DownloadPDF', {
      template: 'EcbSearch',
      data: await this.getFormContent(),
    });
    const nextLink = getNextLink(
      this.props.property.orderId,
      searchTypeId,
      this.props.match.params.propertyid
    );
    if (nextLink == null) {
      this.props.history.push(`/orders/order-information/${this.props.property.orderId}`);
    } else {
      this.props.history.push(nextLink);
    }
    this.setState({ loaderStatus: false });
  };

  getFormContent = async () => {
    const { additionalInfo, hasEcb, owners } = this.state;

    const promises = this.state.attachedFiles.map(async f => {
      const base64 = await getBase64(f);
      return { name: f.name, base64String: base64 };
    });
    const files = await Promise.all(promises);
    return {
      date: new Date(),
      searchType: 'ECB search By Address',
      hasEcb,
      additionalInfo,
      additionalInformation: additionalInfo,
      propertyId: +this.props.match.params.propertyid,
      searchTypeId,
      searchDocumentType: 'Municipal Attachment',
      attachments: files,
      owners,
      runDate: this.props.runDate,
    };
  };

  render() {
    const { hasEcb, pdfHtml, loaderStatus, owners } = this.state;
    const showNext = showNextButton(this.props.property.orderId, searchTypeId);
    const params = new URLSearchParams(this.props.location.search);
    const isEdit = params.get('isEdit');
    return (
      <div css={{ display: 'flex', height: '100%' }}>
        <div css={{ width: '50%' }}>
          <div css={{ width: 654 }}>
            <ReusableTopOfForm
              handlePreview={this.handlePreview}
              searchHeader="ECB Search By Address"
              propertyId={this.props.match.params.propertyid}
              searchTypeId={searchTypeId}
              property={this.props.property}
              getFormContent={this.getFormContent}
              template="EcbSearch"
              searchId={this.state.searchId}
            />
            {/* <div css={{ display: 'flex', justifyContent: 'space-between' }}>
              <h1 css={{ marginBottom: 32 }}>ECB Search By Address</h1>
              <GoToProofingWorkspace
                propertyId={+this.props.match.params.propertyid}
                searchTypeId={searchTypeId}
              />
              <div style={{ position: 'fixed', right: 70, top: 220, zIndex: 500 }}>
                <div css={toolTipWrap}>
                  <ActionButton
                    icon="PreviewIcon"
                    backgroundColor={colors.blackText}
                    onClick={this.handlePreview}
                  />
                  <span css={toolTip}>
                    Preview
                    <span />
                  </span>
                </div>
              </div>
            </div> */}
            {this.state.searchId > 0 && <WithDocumentStation searchId={this.state.searchId} />}

            <AssessToInput
              value={owners}
              onChange={e => this.setState({ owners: e.target.value })}
              propertyId={+this.props.match.params.propertyid}
            />

            <BinaryRadioButton
              headerText="Records Found?"
              trueText="Yes"
              falseText="No"
              checkedState={hasEcb}
              onChange={checked => this.setState({ hasEcb: checked })}
            />
            <div css={{ margin: '32px 0' }}>
              <textarea
                css={{
                  resize: 'none',
                  backgroundColor: colors.greyOrder,
                  borderRadius: 3,
                  width: 654,
                  height: 192,
                  marginBottom: 24,
                  ':focus': { outline: 0 },
                }}
                value={this.state.additionalInfo}
                onChange={evt => this.setState({ additionalInfo: evt.target.value })}
                placeholder="Additional Information"
              />
            </div>
            <div
              css={{
                marginTop: 43,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
                <SaveAndHoldButton
                  propertyId={+this.props.match.params.propertyid}
                  searchTypeId={searchTypeId}
                  formData={this.getFormContent}
                  afterSubmitRoute={() =>
                    this.props.history.push(`/orders/order-information/${this.props.property.orderId}`)
                  }
                  userId={this.props.user.id}
                  orderId={this.props.property.orderId}
                  disabled={loaderStatus}
                />
                <div css={{ marginLeft: 8 }}>
                  <SubmitFormButton
                    searchTypeId={searchTypeId}
                    onSubmit={this.handleDownload}
                    propertyId={+this.props.match.params.propertyid}
                    reactFormal={false}
                    userId={this.props.user.id}
                    orderId={this.props.property.orderId}
                    loadingStatus={loaderStatus}
                    buttonText={showNext ? 'Next' : `${isEdit ? 'Save' : 'Submit'}`}
                    getFormContent={this.getFormContent}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          css={{
            width: '100%',
            borderStyle: 'solid',
            borderWidth: 2,
            padding: 16,
            position: 'sticky',
            top: 25,
            marginLeft: 24,
          }}
        >
          <iframe style={{ width: '100%', height: '100%' }} srcDoc={pdfHtml} />
        </div>
      </div>
    );
  }
}
export default withProperty(withUser(EcbSearch), searchTypeId, true);
