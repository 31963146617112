import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Checkbox, Icon, Modal } from 'semantic-ui-react';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import { convertToBase64 } from 'lib/PublicFunctions';
import produce from 'immer';
import TextField from 'components/ui/TextField';
import TextArea from 'components/forms/ui/TextArea';
import Button from 'components/ui/Button';
import ImportedFileTag from 'components/new-order-entry/Documents/ImportedFileTag';
import { Link } from 'react-router-dom';
import { PopOut, XIcon } from 'components/ui/icons';
import convertToFileDownload from 'components/FileDownloads';
import moment from 'moment';

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;
const Close = styled.button`
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  background-color: #fff;
  border: none;
  cursor: pointer;
`;
const ActualContent = styled.div`
  padding: 0 35px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 9px;
  margin-top: 40px;
  justify-content: flex-end;
  position: relative;
`;

const DocumentOptions = styled.div`
  border: 1.4px dashed #e5e5ea;
  padding: 8px;
  border-radius: 4px;
  width: 376px;
`;
const UploadButton = styled.button`
  background-color: #ff5252;
  border-radius: 5px;
  color: #fff;
  height: 32px;
  padding: 5px 16px;
  margin-right: 16px;
  font-weight: bold;
`;
const DragDrop = styled.span`
  padding-left: 16px;
  border-left: 2px solid #e5e5ea;
`;

const IconButton = styled.button`
  background-color: #f7f7f9;
  border-radius: 5.87px;
  height: 40px;
  width: 40px;
  min-width: 40px;
  color: #8e8e93;
`;
export const Overlay = styled.div`
  background-color: rgba(253, 253, 253, 0.65);
  bottom: 0px;
  height: 100%;
  left: 0px;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 100%;
  z-index: 50;
`;
const SavedModal = styled.div`
  border-radius: 5px;
  background-color: #f5f5f5;
  width: 270px;
  height: 48px;
  color: #ff867f;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  right: 0px;
`;
interface Props {
  open: boolean;
  onClose: any;
  municipalityId: number;
}
interface File {
  base64?: string;
  actualFileName?: string;
  lastDateUploaded?: string;
  docId?: number;
}
interface VprUasInfo {
  id: number;
  municipalityId: number;
  vprNotApplicable: boolean;
  uasNotApplicable: boolean;
  opraEmail: string;
  opraLink: string;
  notes: string;
  vprLastUpdatedFiles: File[];
  uasLastUpdatedFiles: File[];
  vprFrequencyUpdate: string;
  uasFrequencyUpdate: string;
  vprUploadedBy?: string;
  uasUploadedBy?: string;
  uasLastRequested?: string;
  vprLastRequested?: string;
}

const initalVprUasInfo: VprUasInfo = {
  id: null,
  vprNotApplicable: false,
  uasNotApplicable: false,
  opraEmail: '',
  opraLink: '',
  notes: '',
  vprLastUpdatedFiles: [],
  uasLastUpdatedFiles: [],
  vprFrequencyUpdate: '',
  uasFrequencyUpdate: '',
  municipalityId: 0,
};
const VprUasModal = (props: Props) => {
  const [vprUasInfo, setVprUasInfo] = useState<VprUasInfo>(initalVprUasInfo);
  const [vprOverlayOpen, setVprOverlayOpen] = useState(false);
  const [uasOverlayOpen, setUasOverlayOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [savedPopup, setSavedPopup] = useState(false);
  const [openVprOverride, setOpenVprOverride] = useState(false);
  const [vprOverrideInfo, setVprOverrideInfo] = useState('');
  const [previewLoading, setPreviewLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const defaultText =
    '1. list of properties that are registered as Vacant/Abandon and any fees paid or open. We need the information so it can be reported and any open fees get paid before closing. If a date range is required, please go back to the date of the original ordinance, if that is not possible please use 2015 Forward.';

  // const onDropVpr = async (files: any) => {
  //   const file = files[0];
  //   const base64 = await convertToBase64(file);
  //   const fileObj: File = {
  //     ...vprUasInfo.vprLastUpdatedFile,
  //     base64,
  //     actualFileName: file.name,
  //   };
  //   changeVprUasInfo('vprLastUpdatedFile', fileObj);
  // };
  // const onDropUas = async (files: any) => {
  //   const file = files[0];
  //   const base64 = await convertToBase64(file);
  //   const fileObj: File = {
  //     ...vprUasInfo.uasLastUpdatedFiles,
  //     base64,
  //     actualFileName: file.name,
  //   };
  //   changeVprUasInfo('uasLastUpdatedFiles', fileObj);
  // };
  const onDropVpr = async (newFiles: any) => {
    const arr: File[] = [];
    for (let file of newFiles) {
      const base64 = await convertToBase64(file);
      const tempFile: File = {
        base64,
        actualFileName: file.name,
      };
      arr.push(tempFile);
    }
    const newArray = produce(vprUasInfo.vprLastUpdatedFiles, draft => {
      for (let file of arr) {
        draft.push(file);
      }
    });
    changeVprUasInfo('vprLastUpdatedFiles', newArray);
  };
  const onDropUas = async (newFiles: any) => {
    const arr: File[] = [];
    for (let file of newFiles) {
      const base64 = await convertToBase64(file);
      const tempFile: File = {
        base64,
        actualFileName: file.name,
      };
      arr.push(tempFile);
    }
    const newArray = produce(vprUasInfo.uasLastUpdatedFiles, draft => {
      for (let file of arr) {
        draft.push(file);
      }
    });
    changeVprUasInfo('uasLastUpdatedFiles', newArray);
  };
  const onRemoveVprFileClick = (index: any) => {
    const newVprFiles = vprUasInfo.vprLastUpdatedFiles.filter((f, i) => i !== index);
    changeVprUasInfo('vprLastUpdatedFiles', newVprFiles);
  };
  const onRemoveUasFileClick = (index: any) => {
    const newUasFiles = vprUasInfo.uasLastUpdatedFiles.filter((f, i) => i !== index);
    changeVprUasInfo('uasLastUpdatedFiles', newUasFiles);
  };
  const changeVprUasInfo = (label: keyof Partial<VprUasInfo>, value: any) => {
    const newVprUasInfo = produce(vprUasInfo, draft => {
      (draft[label] as keyof VprUasInfo) = value;
    });
    setVprUasInfo(newVprUasInfo);
  };
  const getVprUasInfo = async () => {
    const { data } = await axios.get(`/api/vpruas/GetVprUas/${props.municipalityId}`);
    if (data) {
      setVprUasInfo(data);
    }
  };

  const handlePreview = async () => {
    setPreviewLoading(true);
    const { data } = await axios.post('/api/searches/GetPdfCreatorDocument', {
      template: 'vpropra',
      data: { noteText: vprOverrideInfo, propertyId: 5, searchTypeId: 20 },
    });
    convertToFileDownload(data.base64, data.fileName);
    setPreviewLoading(false);
  };

  const onSubmit = async () => {
    setLoader(true);
    await axios.post(`/api/vpruas/AddVprUasInfo`, { ...vprUasInfo, municipalityId: props.municipalityId });
    setLoader(false);
    setSavedPopup(true);
    setTimeout(() => {
      setSavedPopup(false);
      props.onClose();
    }, 1000);
  };
  useEffect(() => {
    if (props.municipalityId) {
      getVprUasInfo();
    }
  }, [props.municipalityId]);

  const onSendOpra = async (programType: 'VPR' | 'UAS') => {
    setSubmitLoading(true);
    const subroute = programType === 'VPR' ? 'sendVprOpra' : 'sendUasOpra';

    const { data } = await axios.post(`/api/vpruas/${subroute}/${props.municipalityId}`, {
      overrideText: vprOverrideInfo,
    });
    if (data.errorMessage) {
      alert(data.errorMessage);
    }
    await getVprUasInfo();
    setOpenVprOverride(false);
    setVprOverrideInfo('');
    setSubmitLoading(false);
  };

  useEffect(() => {
    if (vprUasInfo.vprNotApplicable) {
      setVprOverlayOpen(true);
    } else {
      setVprOverlayOpen(false);
    }
  }, [vprUasInfo?.vprNotApplicable]);

  useEffect(() => {
    if (vprUasInfo.uasNotApplicable) {
      setUasOverlayOpen(true);
    } else {
      setUasOverlayOpen(false);
    }
  }, [vprUasInfo?.uasNotApplicable]);

  return (
    <Modal
      open={props.open}
      style={{ minHeight: 780, width: 650, zIndex: 500, paddingBottom: 24 }}
      onClose={props.onClose}
    >
      <ModalContent>
        <Close onClick={props.onClose}>
          <Icon name="close" />
        </Close>
        <ActualContent>
          <div style={{ borderBottom: '1px solid #e5e5ea', paddingBottom: 24, paddingTop: 16 }}>
            <div style={{ display: 'flex', marginBottom: 16, justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ fontSize: 24, fontWeight: 'bold', marginRight: 24 }}>VPR</div>
                <Checkbox
                  label="N/A"
                  checked={vprUasInfo?.vprNotApplicable}
                  onChange={(e, { checked }) => {
                    {
                      changeVprUasInfo('vprNotApplicable', checked);
                    }
                  }}
                />
                {!vprUasInfo.vprNotApplicable && (
                  <Button
                    black
                    overRidestyle={{ height: 24, marginLeft: 16 }}
                    onClick={() => setOpenVprOverride(true)}
                  >
                    Send Vpr Opra
                  </Button>
                )}
                {vprUasInfo.vprLastRequested && (
                  <div style={{ marginLeft: 8 }}>
                    Last sent: {moment(vprUasInfo.vprLastRequested).format('MM/DD/YY')}
                  </div>
                )}
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>Every</div>
                <TextField
                  onChange={e => changeVprUasInfo('vprFrequencyUpdate', e.target.value)}
                  value={vprUasInfo?.vprFrequencyUpdate}
                  width={'40px'}
                  styles={{ marginLeft: 8 }}
                  height={24}
                />
                <div style={{ marginLeft: 8 }}>days</div>
              </div>
            </div>
            {openVprOverride && (
              <div style={{ backgroundColor: '#f5f5f5', borderRadius: 5, padding: 16 }}>
                <Button
                  onClick={() => setVprOverrideInfo(defaultText)}
                  black
                  overRidestyle={{ height: 32, marginBottom: 8 }}
                >
                  Add to Existing
                </Button>
                <TextArea
                  value={vprOverrideInfo || ''}
                  onChange={(e: any) => setVprOverrideInfo(e.target.value)}
                  styles={{
                    marginTop: 8,
                    width: 498,
                    height: 100,
                    borderRadius: 5,
                    resize: 'vertical',
                    ':focus': {
                      outline: 'none',
                    },
                  }}
                  label="Vpr Override Text:"
                  placeholder=""
                  maxLength={52000}
                />
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 8, gap: 8 }}>
                  <Button
                    loadingStatus={previewLoading}
                    onClick={() => handlePreview()}
                    black
                    overRidestyle={{ height: 32 }}
                  >
                    Download Preview
                  </Button>
                  <Button
                    loadingStatus={submitLoading}
                    onClick={() => onSendOpra('VPR')}
                    overRidestyle={{ height: 32 }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            )}
            <div style={{ position: 'relative' }}>
              {vprOverlayOpen && <Overlay />}
              <Dropzone onDrop={onDropVpr}>
                {({ getRootProps, getInputProps, isDragActive }) => {
                  return (
                    <DocumentOptions>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <UploadButton>Upload Document</UploadButton>
                        <DragDrop>
                          {isDragActive ? <span>drop here</span> : <span>or drag and drop files</span>}
                        </DragDrop>
                      </div>
                    </DocumentOptions>
                  );
                }}
              </Dropzone>
              <div
                style={{
                  display: 'flex',
                  //alignItems: 'center',
                  position: 'relative',
                  flexDirection: 'column',
                }}
              >
                {vprUasInfo.vprLastUpdatedFiles?.map((file, index) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        position: 'relative',
                        gap: 16,
                      }}
                    >
                      <div key={file.actualFileName}>
                        <ImportedFileTag
                          fileNameToDisplay={file.actualFileName}
                          onRemoveClick={e => {
                            e.stopPropagation();
                            onRemoveVprFileClick(index);
                          }}
                          changeCustomFileName={() => {}}
                          noMargin={true}
                          minWidth={'376px'}
                          noWidth={true}
                        />
                      </div>
                      {file.lastDateUploaded && (
                        <div>
                          <div>Last Updated </div>
                          <div>{file.lastDateUploaded}</div>
                        </div>
                      )}
                      {file.docId && (
                        <Link to={`/api/documents/getDocument/${file.docId}`} target="_blank" replace>
                          <IconButton>
                            <PopOut color="#FF5252" />
                          </IconButton>
                        </Link>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', marginBottom: 16, marginTop: 24, justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ fontSize: 24, fontWeight: 'bold', marginRight: 24 }}>UAS</div>
              <Checkbox
                label="N/A"
                checked={vprUasInfo?.uasNotApplicable}
                onChange={(e, { checked }) => {
                  {
                    changeVprUasInfo('uasNotApplicable', checked);
                  }
                }}
              />
              {vprUasInfo.uasLastRequested && !vprUasInfo.uasNotApplicable && (
                <Button
                  black
                  overRidestyle={{ height: 24, marginLeft: 16 }}
                  onClick={() => onSendOpra('UAS')}
                >
                  Send Uas Opra
                </Button>
              )}
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>Every</div>
              <TextField
                onChange={e => changeVprUasInfo('uasFrequencyUpdate', e.target.value)}
                value={vprUasInfo?.uasFrequencyUpdate}
                width={'40px'}
                styles={{ marginLeft: 8 }}
                height={24}
              />
              <div style={{ marginLeft: 8 }}>days</div>
            </div>
          </div>
          <div style={{ position: 'relative' }}>
            {uasOverlayOpen && <Overlay />}
            <Dropzone onDrop={onDropUas}>
              {({ getRootProps, getInputProps, isDragActive }) => {
                return (
                  <DocumentOptions>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <UploadButton>Upload Document</UploadButton>
                      <DragDrop>
                        {isDragActive ? <span>drop here</span> : <span>or drag and drop files</span>}
                      </DragDrop>
                    </div>
                  </DocumentOptions>
                );
              }}
            </Dropzone>
            <div
              style={{
                display: 'flex',
                paddingBottom: 24,
                position: 'relative',
                flexDirection: 'column',
              }}
            >
              {vprUasInfo.uasLastUpdatedFiles?.map((file, index) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      position: 'relative',
                      gap: 8,
                    }}
                  >
                    <div key={file.actualFileName}>
                      <ImportedFileTag
                        fileNameToDisplay={file.actualFileName}
                        onRemoveClick={e => {
                          e.stopPropagation();
                          onRemoveUasFileClick(index);
                        }}
                        changeCustomFileName={() => {}}
                        noMargin={true}
                        minWidth={'376px'}
                        noWidth={true}
                      />
                    </div>
                    {file.lastDateUploaded && (
                      <div>
                        <div>Last Updated </div>
                        <div>{file.lastDateUploaded}</div>
                      </div>
                    )}
                    {file.docId && (
                      <Link to={`/api/documents/getDocument/${file.docId}`} target="_blank" replace>
                        <IconButton>
                          <PopOut color="#FF5252" />
                        </IconButton>
                      </Link>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div style={{ borderTop: '1px solid #e5e5ea', paddingTop: 16 }}>
            <div style={{ display: 'flex' }}>
              <TextField
                onChange={e => changeVprUasInfo('opraEmail', e.target.value)}
                value={vprUasInfo?.opraEmail}
                width={'245px'}
                label="Opra Email Address"
              />
              <div style={{ marginLeft: 8 }}>
                <TextField
                  onChange={e => changeVprUasInfo('opraLink', e.target.value)}
                  value={vprUasInfo?.opraLink}
                  width={'245px'}
                  label="Opra Link"
                />
              </div>
            </div>
            <div style={{ marginTop: 16 }}>
              <TextArea
                value={vprUasInfo.notes || ''}
                onChange={(e: any) => changeVprUasInfo('notes', e.target.value)}
                styles={{
                  marginTop: 8,
                  width: 498,
                  height: 54,
                  borderRadius: 5,
                  resize: 'vertical',
                  ':focus': {
                    outline: 'none',
                  },
                }}
                label="Notes:"
                placeholder=""
                maxLength={52000}
              />
            </div>
          </div>
          <ButtonContainer>
            <Button secondary onClick={props.onClose}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                onSubmit();
              }}
              loadingStatus={loader}
            >
              Submit
            </Button>
            {savedPopup && <SavedModal>Successfully Saved</SavedModal>}
          </ButtonContainer>
        </ActualContent>
      </ModalContent>
    </Modal>
  );
};

export default VprUasModal;
