/** @jsx jsx */
import { jsx } from '@emotion/core';
import * as React from 'react';
import axios from 'axios';
import { Link, withRouter } from 'react-router-dom';

import colors from '../../../css/colors';
import { XIconLarge } from '../../ui/icons';
import GeneralInfoSection from './GeneralInfoSection';
import MainDetailsSection from './MainDetailsSection';
import NamesSection from './NamesSection';
import PropertiesSection from './PropertiesSection';
import Spinner from '../../ui/Spinner';
import qs from 'qs';
import withUser from '../../../lib/WithUser';
import { Button } from 'semantic-ui-react';
import RegularButton from 'components/ui/Button';
import { SetOrderForRecentlyViewed } from 'lib/PublicFunctions';
import RunAutomation from './RunAutomation';
import ToFollowModal from './ToFollowModal';
import { ArrowLeft } from 'components/HelperDocuments/icons';
import XButton from 'components/ui/XButton';
import RulesIcon from './RulesIcon';
import CustomModal from 'components/ui/CustomModal';
import EmailRequestLetterDropdown from 'components/forms/EmailRequestLetterDropdown';

const layoutStyles = {
  paddingBottom: 32,
  // maxWidth: 1288,
  paddingTop: 24,
};
const sideBarWrap = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'stretch',
  marginBottom: 32,
  '@media(min-width: 1260px)': {
    marginBottom: 0,
    display: 'inline-block',
    marginRight: 32,
    verticalAlign: 'top',
    maxWidth: 350,
  },
  '@media(min-width: 1320px)': {
    maxWidth: 410,
  }
};
const statusDot = {
  backgroundColor: 'green',
  borderRadius: '50%',
  display: 'inline-block',
  height: 6,
  lineHeight: '1',
  marginBottom: 1,
  marginRight: 4,
  width: 6,
};

export const statusStyles = {
  display: 'flex',
  alignItems: 'center',
  color: 'green',
  // marginTop: 8,
  fontWeight: 600,
  fontSize: 12,
  textTransform: 'uppercase',
  marginLeft: 8,
};

const closeButton = {
  alignItems: 'center',
  backgroundColor: colors.greyOrder,
  borderRadius: '50%',
  cursor: 'pointer',
  display: 'inline-flex',
  height: 56,
  justifyContent: 'center',
  width: 56,
  color: colors.blackText,
  marginLeft: 24,
  '&:hover': {
    ' svg': { color: colors.redLight },
  },
};

class OrderInformation extends React.Component {
  // state = { isEdit: false, editForm: false };
  // const { isEdit, editForm } = this.state;
  state = {
    isPurchase: false,
    selectedProperty: null,
    subComponentLoadingStates: {},
    showLoader: true,
    // currentUserInFile: null,
    // acknowlegedCurrentUser: false,
    orderStatus: '',
    confirmCancelModalOpen: false,
    confirmPutOnHoldModalOpen: false,
    confirmReopenModalOpen: false,
    followModalOpen: false,
    properties: [],
    automationRunning: false,
    isFollowing: false,
    toFollowInfo: {},
    isRush: false,
    isSuperRush: false,
    isNameEdit: false,
    modalOpen: false,
    clientName: null
  };

  async componentDidUpdate(prevProps) {
    if (prevProps.match.params.propertyId !== this.props.match.params.propertyId) {
      const { propertyId } = this.props.match.params;
      if (propertyId !== null) {
        const { data } = await axios.get(`/api/property/getProperty/${propertyId}`);
        this.setState({ selectedProperty: data });
      }
    }
  }

  setProperties = properties => {
    this.setState({ properties });
  };

  componentDidMount = async () => {
    const {
      user: { id: userId },
      match: {
        params: { orderId, propertyAddress },
      },
    } = this.props;
    this.getOrderStatus();
    this.getOrderLocation();

    await this.getToFollowOrder();
    this.getOrderDetails();
    localStorage.removeItem('selectedProperties');

    const viewedByUser = this.props.history.location.state?.viewedByUser;
    if (!viewedByUser) await axios.post('/api/orders/AddUserOrderView', { userId, orderId });

    const { propertyId } = this.props.match.params;
    let propertyData = null;
    if (propertyId !== null && propertyId !== -1) {
      const { data } = await axios.get(`/api/property/getProperty/${propertyId}`);
      this.setState({ selectedProperty: data });
      propertyData = data;
      this.setState({clientName: data.clientName})
    } else {
      if(this.state.properties[0]?.id){
        const { data } = await axios.get(`/api/property/getProperty/${this.state.properties[0].id}`);
        this.setState({clientName: data.clientName})
      }
      
    }
    if (!propertyData) {
      let { data } = await axios.get(`/api/orders/getOrderProperties/${this.props.match.params.orderId}`);
      propertyData = data[0];
    }
    SetOrderForRecentlyViewed(
      orderId,
      this.state.titleNumber,
      propertyData?.address,
      propertyData?.municipality
    );
    if (this.state.orderStatus === 'Cancelled') {
      this.setState({ modalOpen: true });
    }
    this.getSearchesForOrder();
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Optional: smooth scrolling animation
    });
  };
  getToFollowOrder = async () => {
    const {
      match: {
        params: { orderId },
      },
    } = this.props;
    const { data } = await axios.get(`/api/OrdersToFollow/GetToFollowOrder/${orderId}`);
    this.setState({
      toFollowInfo: data,
      isFollowing: Boolean(data),
    });
  };
  getOrderLocation = async () => {
    const { data } = await axios.get(`/api/orders/getOrderLocation/${this.props.match.params.orderId}`);
    this.setState({ orderLocation: data });
  };
  // componentWillUnmount = () => {
  //   const { user: { id: userId }, match: { params: { orderId } } } = this.props;
  //   axios.post('/api/orders/UnlockOrderCurrentViewer', { userId, orderId })
  // }
  setTitleNumber = titleNumber => {
    this.setState({ titleNumber });
  };
  getOrderDetails = async () => {
    const orderResponse = await axios.get(`/api/orders/getorderdetails/${this.props.match.params.orderId}`);
    this.setState({
      isRush: (orderResponse.data || {}).isRush || false,
      isSuperRush: (orderResponse.data || {}).isSuperRush || false,
    });
  };
  getOrderStatus = async () => {
    const {
      user: { id: userId },
      match: {
        params: { orderId },
      },
    } = this.props;
    const { data } = await axios.get(`/api/orders/getOrderStatus/${orderId}`);
    this.setState({ orderStatus: data });
  };

  setIsPurchase = value => {
    this.setState({ isPurchase: value });
  };
  setIsRush = value => {
    this.setState({ isRush: value });
  };
  setIsSuperRush = value => {
    this.setState({ isSuperRush: value });
  };
  setIsNameEdit = value => {
    this.setState({ isNameEdit: value });
  };
  // setSelectedProperty = (property) => {
  //   if (property) {
  //     this.props.history.push(`/orders/order-information/${this.props.match.params.orderId}/${property.id}`);
  //   } else {
  //     this.props.history.push(`/orders/order-information/${this.props.match.params.orderId}`);
  //   }
  // };

  // unlockFile = () => {
  //   const {
  //     user: { id: userId },
  //     match: {
  //       params: { orderId },
  //     },
  //   } = this.props;
  //   axios.post('/api/orders/UnlockOrderCurrentViewer', { userId, orderId });
  // };
getSearchesForOrder = async () =>{
  const queryString = qs.stringify({
    userId: this.props.userId
  });
    const { data } = await axios.get(
      `/api/property/getSearchesWithEta/${this.state.selectedProperty.id}?${queryString}`
    );
}
  onSubcomponentStateChanged = (name, status) => {
    const copy = { ...this.state.subComponentLoadingStates };
    copy[name] = status;
    this.setState({
      subComponentLoadingStates: copy,
      showLoader: Object.keys(copy).some(k => copy[k]),
    });
  };

  // setAcknowledgedCurrentUser = () => {
  //   sessionStorage.setItem('acknowledged', true);
  //   this.setState({ acknowlegedCurrentUser: true });
  // };

  onClickPutOnHold = async () => {
    const {
      match: {
        params: { orderId },
      },
    } = this.props;
    await axios.post(`/api/orders/holdOrder/${orderId}`);
    this.setState({ confirmPutOnHoldModalOpen: false });
    this.getOrderStatus();
  };

  onClickCancel = async () => {
    const {
      match: {
        params: { orderId },
      },
    } = this.props;
    await axios.post(`/api/orders/cancelOrder/${orderId}`);
    this.setState({ confirmCancelModalOpen: false });
    this.getOrderStatus();
  };

  onClickReopen = async () => {
    const {
      match: {
        params: { orderId },
      },
    } = this.props;
    await axios.post(`/api/orders/activateOrder/${orderId}`);
    this.setState({ confirmReopenModalOpen: false });
    this.getOrderStatus();
  };

  render() {
    const ON_HOLD = 'On Hold';
    const ACTIVE = 'Active';
    const CANCELLED = 'Cancelled';
    const {
      match: { params },
      user: { id: userId },
    } = this.props;
    const queryParams = qs.parse(this.props.location.search.toLowerCase().replace('?', ''));
    const {
      orderStatus,
      confirmCancelModalOpen,
      confirmPutOnHoldModalOpen,
      confirmReopenModalOpen,
      followModalOpen,
    } = this.state;
    return (
      <div css={layoutStyles}>
        {this.state.modalOpen && (
          <CancelledModal
            onSubmit={() => this.setState({ modalOpen: false })}
            onClose={() => this.props.history.push('/orders/1')}
          />
        )}
        {this.state.showLoader && <Spinner />}
        {/* {this.state.currentUserInFile && !this.state.acknowlegedCurrentUser && (
          <CurrentViewerModal
            currentUserInFile={this.state.currentUserInFile}
            history={this.props.history}
            setAcknowledgedCurrentUser={this.setAcknowledgedCurrentUser}
            orderId={params.orderId}
            userId={userId}
            superUserUnlockFile={this.unlockFile}
          />
        )} */}
        {/* {editForm && <EditPage />} */}
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingBottom: 32,
            alignItems: 'center',
          }}
        >
          <div className="df aic">
            <div style={{ fontWeight: 'bold', fontSize: 24 }}>Order Information</div>
            <div
              css={[
                statusStyles,
                orderStatus === ON_HOLD && {
                  color: 'orange',
                  span: { backgroundColor: 'orange' },
                },
                orderStatus === CANCELLED && {
                  color: 'grey',
                  span: { backgroundColor: 'grey' },
                },
              ]}
            >
              <span css={statusDot}></span>
              <div>{orderStatus}</div>
            </div>
            {(this.state.isRush || this.state.isSuperRush) && (
              <div style={{ color: '#ff5252', marginLeft: 16, fontWeight: 'bold' }}>
                {this.state.isSuperRush ? `Super Rush Order` : `Rush Order`}
              </div>
            )}
          </div>

          <RunAutomation orderId={this.props.match.params.orderId} />

          <div className="df aic">
            {/* {(this.state.selectedProperty || this.props.match.params.orderId) && (
              <div style={{ marginRight: 16 }}>
                <RulesIcon
                  municipalityId={this.state.selectedProperty?.municipalityId}
                  orderId={this.props.match.params.orderId}
                  subMunicipalityId={this.state.selectedProperty?.subMunicipalityId}
                  countyId={this.state.selectedProperty?.countyId}
                  clientId={this.state.selectedProperty?.clientId}
                  propertyId={this.state.selectedProperty?.id}
                  locationTypeId={this.state.selectedProperty?.locationTypeId}
                  padding="6px 6px 0px 5px"
                />
              </div>
            )} */}
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: 8 }}>
                {this.state.isFollowing ? (
                  <RegularButton
                    onClick={() => this.setState({ followModalOpen: true })}
                    onSubmit={this.onClickCancel}
                  >
                    Following
                  </RegularButton>
                ) : (
                  <RegularButton
                    onClick={() => this.setState({ followModalOpen: true })}
                    onSubmit={this.onClickCancel}
                    secondary
                  >
                    Follow
                  </RegularButton>
                )}
              </div>
              {orderStatus === ACTIVE && (
                <RegularButton
                  onClick={() => this.setState({ confirmPutOnHoldModalOpen: true })}
                  // onSubmit={this.onClickPutOnHold}
                  secondary
                >
                  Put On Hold
                </RegularButton>
              )}
              {(orderStatus === ON_HOLD || orderStatus === 'Cancelled') && (
                <RegularButton
                  onClick={() => this.setState({ confirmReopenModalOpen: true })}
                  onSubmit={this.onClickReopen}
                >
                  Re-Open
                </RegularButton>
              )}
              <div style={{ marginLeft: 8 }}>
                {(orderStatus === 'Active' || orderStatus === 'OnHold') && (
                  <RegularButton
                    onClick={() => this.setState({ confirmCancelModalOpen: true })}
                    onSubmit={this.onClickCancel}
                    secondary
                  >
                    Cancel
                  </RegularButton>
                )}
              </div>
            </div>

            {confirmCancelModalOpen && (
              <ConfirmCancelModal
                onClose={() => this.setState({ confirmCancelModalOpen: false })}
                onSubmit={this.onClickCancel}
              />
            )}
            {confirmPutOnHoldModalOpen && (
              <ConfirmPutOnHoldModal
                onClose={() => this.setState({ confirmPutOnHoldModalOpen: false })}
                onSubmit={this.onClickPutOnHold}
              />
            )}
            {confirmReopenModalOpen && (
              <ConfirmReopenModal
                onClose={() => this.setState({ confirmReopenModalOpen: false })}
                onSubmit={this.onClickReopen}
              />
            )}
            {followModalOpen && (
              <ToFollowModal
                openState={followModalOpen}
                onClose={() => this.setState({ followModalOpen: false })}
                actionFunction={isFollowing => this.setState({ isFollowing })}
                orderId={parseInt(params.orderId)}
                toFollowInfo={this.state.toFollowInfo}
              />
            )}

            {this.state.isNameEdit ? (
              <span style={{ marginLeft: 8 }}>
                <XButton
                  link={queryParams.hasOwnProperty('fromentry') ? '/orders/new-order' : `/orders`}
                  buttonText="Leave"
                  header="Leave Without Saving"
                  info="Changes were made to the names that were not yet saved. Leaving without saving will undo these changes."
                  cancelButtonText="No, Take Me Back"
                />
              </span>
            ) : (
              <Link to={queryParams.hasOwnProperty('fromentry') ? '/orders/new-order' : `/orders`}>
                <div
                  // onClick={() => {
                  //   this.unlockFile();
                  // }}
                  css={closeButton}
                >
                  <XIconLarge />
                </div>
              </Link>
            )}
          </div>
        </div>
        {this.state.orderLocation === 2 && (
          <div style={{ marginBottom: '8px', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <EmailRequestLetterDropdown orderId={this.props.match.params.orderId} />
          </div>
        )}

        <div css={sideBarWrap}>
          <GeneralInfoSection
            orderId={params.orderId}
            setIsPurchase={this.setIsPurchase}
            onLoadStatusChanged={this.onSubcomponentStateChanged}
            setTitleNumber={this.setTitleNumber}
            setIsRush={this.setIsRush}
            setIsSuperRush={this.setIsSuperRush}
          />
          <PropertiesSection
            openValidationModal={this.openValidationModal}
            orderId={params.orderId}
            selectedProperty={this.state.selectedProperty}
            setSelectedProperty={this.setSelectedProperty}
            onLoadStatusChanged={this.onSubcomponentStateChanged}
            setProperties={this.setProperties}
          />
          <NamesSection
            orderId={params.orderId}
            isPurchase={this.state.isPurchase}
            setIsPurchase={this.setIsPurchase}
            onLoadStatusChanged={this.onSubcomponentStateChanged}
            isEdit={this.state.isNameEdit}
            setIsEdit={this.setIsNameEdit}
          />
        </div>
        <MainDetailsSection
          onLoadStatusChanged={this.onSubcomponentStateChanged}
          // setSelectedProperty={this.setSelectedProperty}
          selectedProperty={this.state.selectedProperty}
          properties={this.state.properties}
          orderId={params.orderId}
          tab={params.tab}
          currentUserInFile={this.state.currentUserInFile}
          userId={this.props.user.id}
          user={this.props.user}
          clientName={this.state.clientName}
          clientId={this.state.selectedProperty?.clientId}
        />
        {/* <UndoDeletedSearch /> */}
      </div>
    );
  }
}

function ConfirmCancelModal({ onClose, onSubmit }) {
  return (
    <CustomModal open={open} onClose={onClose} size="small" moreStyles={{ top: '40%' }}>
      <div style={{ fontSize: 16 }}>Are you sure you want to cancel this order?</div>
      <div style={{ marginTop: 30, display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={onClose} secondary>
          Never Mind
        </Button>
        <Button
          onClick={onSubmit}
          style={{ backgroundColor: '#ff5252', color: '#fff', marginLeft: 8 }}
          content="Yes"
        />
      </div>
    </CustomModal>
  );
}

function ConfirmPutOnHoldModal({ onClose, onSubmit }) {
  return (
    <CustomModal open={open} onClose={onClose} size="small" moreStyles={{ top: '40%' }}>
      <div style={{ fontSize: 16 }}>Are you sure you want to put this order on hold?</div>
      <div style={{ marginTop: 30, display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={onClose} secondary>
          Never Mind
        </Button>
        <Button
          onClick={onSubmit}
          style={{ backgroundColor: '#ff5252', color: '#fff', marginLeft: 8 }}
          content="Yes"
        />
      </div>
    </CustomModal>
  );
}

function ConfirmReopenModal({ onClose, onSubmit }) {
  return (
    <CustomModal open={open} onClose={onClose} size="small" moreStyles={{ top: '40%' }}>
      <div style={{ fontSize: 16 }}>Are you sure you want to reopen this order?</div>
      <div style={{ marginTop: 30, display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={onClose} secondary>
          Never Mind
        </Button>
        <Button
          onClick={onSubmit}
          style={{ backgroundColor: '#ff5252', color: '#fff', marginLeft: 8 }}
          content="Yes"
        />
      </div>
    </CustomModal>
  );
}
function CancelledModal({ onClose, onSubmit }) {
  return (
    <CustomModal open={open} onClose={onSubmit} size="small" moreStyles={{ top: '40%' }}>
      <div style={{ fontSize: 16 }}>
        This order is cancelled.
        <br /> Are you sure you want to proceed?
      </div>
      <div style={{ marginTop: 30, display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={onClose} secondary>
          No, Take Me Back
        </Button>
        <Button
          style={{ backgroundColor: '#ff5252', color: '#fff', marginLeft: 8 }}
          onClick={onSubmit}
          content="Yes"
        />
      </div>
    </CustomModal>
  );
}
export default withRouter(withUser(OrderInformation));
