import MsgReader, { FieldsData } from '@kenjiuno/msgreader';
import { convertToBase64 } from 'lib/PublicFunctions';

export const getMsgFileMetaData: (file: File) => Promise<FieldsData> = async (file: File)  => {
  const base64 = (await convertToBase64(file)) as string;
  const msgDataHandler = new MsgReader(Buffer.from(base64, 'base64'));
  return msgDataHandler.getFileData();
};
export const getMsgFileMetaDataFromBase64: (fileBase64: string) => Promise<FieldsData> = async (fileBase64: string)  => {
  const msgDataHandler = new MsgReader(Buffer.from(fileBase64, 'base64'));
  return msgDataHandler.getFileData();
};
