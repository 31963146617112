/** @jsx jsx */
import { jsx, Global } from '@emotion/core';
import * as React from 'react';

import colors from '../../../css/colors';
import Button from '../../ui/Button';
import axios from 'axios';
import FilterDropdown from '../../ui/FilterDropdown';
import convertToFileDownload from '../../FileDownloads';
import CustomModal from '../../ui/CustomModal';
import { Checkbox, Icon } from 'semantic-ui-react';
import { SettingName, SettingOption } from '../../../types';
import RulesIcon from './RulesIcon';
import { Link, useHistory } from 'react-router-dom';
import { decodeLink } from './CreateDocument';
import qs from 'qs';

const titleStyles = {
  backgroundColor: colors.greyOrder,
  height: 72,
  width: 463,
  borderRadius: 5,
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 22,
  fontWeight: 'bold',
  fontSize: 18,
};
const documentStyles = {
  backgroundColor: colors.grey,
  borderRadius: 5,
  width: 320,
  height: 48,
  marginTop: 16,
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 16,
  ':hover':{
    color: '#ff5252'
  }
};

export default class SendToClient extends React.Component {
  state = {
    contacts: [],
    client: {},
    sendStatus: false,
    error: false,
    downloadEmail: true,
    sendViaIntegration: false,
    trackerProError: null,
    riversideUploadmessage: '',
  };
  
  async componentDidMount() {
    let searchIds = [];
    let continReceiver = [];
    if (this.props.selectedDocuments.every(d => d.searches?.length === 1)) {
      searchIds = this.props.selectedDocuments.reduce((pre, curr) => {
        const searchId = curr.searches[0].id;
        if (!pre.includes(searchId)) {
          pre.push(searchId);
        }
        return pre;
      }, searchIds);
    }

    const queryParams = qs.stringify({ searchIds });

    const { data: continReceivers } = await axios.get(`/api/searches/getContinReceiver?${queryParams}`);
    if ((continReceivers || []).length > 0) {
      continReceiver = continReceiver.concat(
        continReceivers.map(continReceiverData => ({
          ...continReceiverData,
          selected: true,
          continReceiver: true,
          fromOrderEntry: continReceiverData.fromOrderEntry,
          email: continReceiverData.emailAddress,
        }))
      );
    }

    const { orderId } = this.props;
    const [contactsResponse, clientResponse] = await Promise.all([
      axios.get(`/api/orders/getClientContacts/${orderId}`),
      axios.get(`/api/orders/getClient/${orderId}`),
    ]);
    const { data: orderlocation } = await axios.get(`/api/orders/getOrderLocation/${orderId}`);
    const { data } = await axios.get(
      `/api/clients/GetClientSetting/${clientResponse.data.id}?setting=${SettingName.sendviaemail}`
    );
    const sendViaEmailClient = data === SettingOption.yes;
    const allDocsTp = this.props.selectedDocuments.every(d => d.searches.every(s => s.trackerproId));
    this.setState({
      contacts: continReceiver.concat(
        contactsResponse.data.map(c => {
          return {
            ...c,
            selected:
              orderlocation === 3
                ? (c.defaultForLocationTypeId === 3 || (!c.defaultForLocationTypeId && c.defaultContact)) 
                : c.defaultContact,
          };
        })
      ),
      client: {
        ...clientResponse.data,
        selected:
          clientResponse.data.defaultContact && continReceiver.filter(cr => !cr.fromOrderEntry).length < 1,
      },
      downloadEmail: !clientResponse.data?.trackerProId > 0 || !allDocsTp || sendViaEmailClient,
      sendViaIntegration: clientResponse.data?.trackerProId > 0 || [41, 860].includes(clientResponse.data.id),
    });
  }

  onSubmit = async () => {
    const { client, contacts } = this.state;
    const { onClose, selectedDocuments, clearSelectedDocs } = this.props;
    const error =
      !client.selected &&
      contacts.filter(c => c.selected).length === 0 &&
      (!this.state.sendViaIntegration || this.state.downloadEmail) && ![41, 860].includes(client.id);
    if (!error) {
      this.setState({ sendStatus: true });
      let emails = [];
      if (client.selected) {
        emails = [client.email];
      }
      emails = contacts.reduce(
        (arr, contact) => (contact.selected ? [...arr, contact.email] : [...arr]),
        emails
      );
      const docsString = selectedDocuments
        .map(
          (d, i) =>
            `documentIds[${i}].id=${d.id}&documentIds[${i}].name=${
              d.documentType !== null
                ? d.documentType
                : d.fileName.includes('billingDoc.pdf')
                ? 'BillingDocument'
                : 'Order Correspondence'
            }&`
        )
        .join('');
      const { data } = await axios.get(
        `/api/documents/sendToClient?${docsString.replace('#', '')}${emails
          .map((e, i) => `emailAddresses[${i}]=${e}&`)
          .join('')}&clientId=${client.id}&downloadEmail=${this.state.downloadEmail}&sendViaIntegration=${
          this.state.sendViaIntegration
        }`
      );
      if (data.base64 && data.fileName) {
        convertToFileDownload(data.base64, data.fileName);
      }
      if (data.message) {
        alert(data.message);
      }
      if (data.isTrackerProOrder) {
        if (
          data.tpResults.some(
            i =>
              !i.value.item1 || i.value.item2.includes('Failed to save the document through the integration')
          )
        ) {
          this.setState({ trackerProError: true });
        } else if (data.tpResults.every(i => i.value.item1)) {
          this.setState({ trackerProError: false });
        }
      } else {
        onClose();
      }
      clearSelectedDocs();
      this.setState({ sendStatus: false });
    } else {
      this.setState({ error });
    }
  };

  handleEmailCheck = (e, { checked }) => {
    this.setState({ downloadEmail: checked });
  };

  handleIntegrationCheck = (e, { checked }) => {
    if (!checked) {
      this.setState({ downloadEmail: true });
    }
    this.setState({ sendViaIntegration: checked });
  };
  openFormClick = async (propertyId, link) => {
    const computedLink = await decodeLink(propertyId, link);
    window.open(`${computedLink}?isEdit=true`, "_self");
  };
  render() {
    const { contacts, client, sendStatus, error } = this.state;
    const { onClose, selectedDocuments } = this.props;
    let list = [];
    if (contacts.length > 0 || client.length > 0) {
      list = [...list, ...contacts];
      list = list.filter(c => c.email !== null);
    }
    const selectedContacts = list.filter(c => c.selected);
    const riversideClients = [50, 439, 323, 321, 306];
    
    return (
      <React.Fragment>
        <CustomModal onClose={onClose} notCloseOverlay={true} moreStyles={{ maxWidth: 542 }}>
          <div style={{ display: 'flex', marginTop: -24, marginBottom: 24 }}>
            <RulesIcon clientId={client.id} orderId={this.props.orderId} padding="6px 6px 0px 5px" />
          </div>
          <div css={titleStyles}>Send to client - {client.name}</div>
          <div css={{ marginBottom: 32 }}>
            <div css={{ fontSize: 14, marginTop: 45 }}>Send to</div>
            <div css={{ marginTop: 8 }}>
              <FilterDropdown
                overRideStyles={{
                  width: 320,
                }}
                displaySearch={true}
                searchPlaceholderText="Search"
                buttonText={
                  selectedContacts.length > 0
                    ? `${selectedContacts[0].email} ${
                        selectedContacts.length > 1 ? `+${selectedContacts.length - 1}` : ''
                      }`
                    : 'Select Contacts...'
                }
                onCheckChanged={({ item }, checked) => {
                  this.setState({ error: false });
                  if (item.isClient) {
                    this.setState(state => ({
                      client: { ...state.client, selected: checked },
                    }));
                  } else {
                    this.setState(state => ({
                      contacts: state.contacts.map(c =>
                        c.id === item.id ? { ...c, selected: checked } : { ...c }
                      ),
                    }));
                  }
                }}
                checkboxItems={list.map(c => {
                  return {
                    item: {
                      displayText: `${c.email}${
                        c.fromOrderEntry ? ' (Order Contact)' : c.continReceiver ? ' (Contin Receiver)' : ''
                      }`,
                      isClient: c.isClient,
                      id: c.id,
                    },
                    checked: c.selected,
                  };
                })}
              />
            </div>
          </div>
          <div css={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <div css={{ fontSize: 16 }}>Documents</div>
              {selectedDocuments.map(d => {
                return (
                <div key={d.id} style={{display: 'flex', gap: 8}}>
                  <div css={documentStyles}>
                      <span onClick={() => d.documentLink && this.openFormClick(this.props.propertyId, d.documentLink )} style={{cursor:'pointer'}}>{d.documentType !== null ? d.documentType : 'Order Correspondence'}</span>
                  </div>
                  <Icon
                    style={{ marginTop: 16, cursor: 'pointer' }}
                    name="close"
                    onClick={() => this.props.removeDocument(d.id)}
                  />
                </div>
              )})}
            </div>
            <div />
          </div>
          {(client.trackerProId > 0 || [41, 860].includes(client.id)) && (
            <div style={{ marginTop: 16 }}>
              <Checkbox
                checked={this.state.downloadEmail}
                onChange={this.handleEmailCheck}
                label="Download Email "
              />
            </div>
          )}
          {(client.trackerProId > 0 || [41, 860].includes(client.id)) && (
            <div style={{ marginTop: 16 }}>
              <Checkbox
                checked={this.state.sendViaIntegration}
                onChange={this.handleIntegrationCheck}
                label="Send Via Integration"
              />
            </div>
          )}
          <div>{error && <div css={{ color: colors.red }}>Please select contacts</div>}</div>
          <div css={{ marginTop: 80 }}>
            <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
              {this.state.trackerProError !== null && (
                <div css={{ color: this.state.trackerProError ? 'red' : 'black' }}>
                  {this.state.trackerProError
                    ? 'There was an issue with some of the files we sent to Tracker Pro. Please check notes section for more details!!'
                    : 'Documents successfully sent to Tracker Pro'}
                </div>
              )}
            </div>
            <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
              {this.state.riversideUploadmessage && <div>{this.state.riversideUploadmessage}</div>}
            </div>
            <div css={{ marginTop: 12, display: 'flex', justifyContent: 'flex-end' }}>
              <Button secondary onClick={onClose} disabled={sendStatus}>
                Cancel
              </Button>
              <Button
                loadingStatus={this.props.previewLoading}
                overRidestyle={{ marginLeft: 10 }}
                onClick={() => this.props.handlePreviewClick(this.props.selectedDocuments, this.state.client)}
              >
                Preview
              </Button>
              {this.state.trackerProError != null ? (
                <Button
                  overRidestyle={{ marginLeft: 10 }}
                  onClick={this.props.onClose}
                  disabled={error}
                  loadingStatus={sendStatus}
                >
                  OK
                </Button>
              ) : (
                <Button
                  overRidestyle={{ marginLeft: 10 }}
                  onClick={this.onSubmit}
                  disabled={error}
                  loadingStatus={sendStatus}
                >
                  Send
                </Button>
              )}
            </div>
          </div>
        </CustomModal>
      </React.Fragment>
    );
  }
}
