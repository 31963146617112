import styled from '@emotion/styled';
import { useState } from 'react';
import * as React from 'react';
import axios from 'axios';
import { Icon, Input, Modal } from 'semantic-ui-react';
import { Loader } from 'semantic-ui-react';
import Button from 'components/ui/Button';
import moment from 'moment';
import qs from 'qs';
import convertToFileDownload from 'components/FileDownloads';
import Select from 'react-select';

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;
const Close = styled.button`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  background-color: #fff;
  border: none;
  cursor: pointer;
`;
const Header = styled.div`
  padding: 0 24px 24px 24px;
  font-size: 16px;
  font-weight: 700;
`;
const Information = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  /* margin-top: 24px; */
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 24px 0 0 0;
  margin-top: 48px;
`;
const DocumentOptions = styled.div`
  border: 1.4px dashed #e5e5ea;
  padding: 4px;
  border-radius: 4px;
  width: 424px;
  height: 190px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 50px 100px;
`;
const UploadButton = styled.button`
  background-color: #333;
  border-radius: 5px;
  color: #fff;
  height: 48px;
  padding: 5px 16px;
  margin-right: 16px;
  font-weight: bold;
  width: 220px;
  margin-top: 16px;
`;
const DragDrop = styled.span`
  padding-left: 16px;
`;

interface Props {
  modalOpen: boolean;
  onClose: any;
  setShowEmailModal: (x: boolean) => void;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
}

const ClientOptions = [
  { value: 41, label: 'Judicial Title' },
  { value: 860, label: 'Judicial Research' },
];
const TpInvoice = (props: Props) => {
  const [submitting, setSubmitting] = useState(false);
  const [fromDate, setFromDate] = useState<string>(moment().subtract(2, 'weeks').format('yyyy-MM-DD'));
  const [toDate, setToDate] = useState<string>(moment().format('yyyy-MM-DD'));
  const [billedDate, setBilledDate] = useState<string>(moment().format('yyyy-MM-DD'));
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [invoiceNumber, setInvoiceNumber] = useState<string>('');
  const [selectedClient, setSelectedClient] = useState<DropdownOption>(null);


  const validate = () => {
    const fromDateError = !fromDate ? ['Please add a from Date'] : [];
    const toDateError = !toDate ? ['please add a to date'] : [];
    const billingDateError = !billedDate ? ['please add a Billed Date'] : [];
    const invoiceNumberError = !invoiceNumber ? ['please add a Invoice Number'] : [];
    const clientError = !selectedClient?.value? ['please select a client'] : [];
    const errorMessages = []
      .concat(fromDateError)
      .concat(toDateError)
      .concat(billingDateError)
      .concat(invoiceNumberError)
      .concat(clientError);

    setErrorMessages(errorMessages);
    return errorMessages.length === 0;
  };

  const CreateJudicialInvoice = async () => {
    const validated = validate();
    if (!validated) {
      return;
    }

    setSubmitting(true);
    var queryString = qs.stringify({
      fromDate,
      toDate,
      billedDate,
      invoiceNumber,
      clientId: selectedClient.value
    });
    const { data, status } = await axios.get(`api/billing/processJudicialInvoice?${queryString}`);
    if (status !== 200) {
      setSubmitting(false);
      setErrorMessages([data.message]);
      return;
    }
    data.forEach((file: { base64: string; fileName: string }) => {
      convertToFileDownload(file.base64, file.fileName);
    });

    setSubmitting(false);
  };
  return (
    <Modal
      open={props.modalOpen}
      style={{ width: 550, padding: 16, zIndex: 500, borderRadius: 5 }}
      onClose={props.onClose}
    >
      <ModalContent>
        <Close onClick={props.onClose}>
          <Icon name="close" />
        </Close>
        <Header>Download Judicial Excel Invoice</Header>
        <Information>
          <div style={{ display: 'flex', gap: 16 }}>
            <Input
              type="date"
              label="From Date"
              value={fromDate}
              onChange={e => setFromDate(e.target.value)}
            />
            <Input type="date" label="To Date" value={toDate} onChange={e => setToDate(e.target.value)} />
          </div>
          <div style={{ display: 'flex', gap: 16 }}>
            <Input
              type="date"
              label="Billed Date"
              value={billedDate}
              onChange={e => setBilledDate(e.target.value)}
            />
            <Input
              type="text"
              label="Invoice #"
              value={invoiceNumber}
              onChange={e => setInvoiceNumber(e.target.value)}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
            <div>Select Client</div>
            <Select
              options={ClientOptions}
              value={selectedClient}
              onChange={e => setSelectedClient(e)}
              isClearable
            />
          </div>
        </Information>
        <ButtonContainer>
          <Button large disabled={submitting} onClick={CreateJudicialInvoice} overRidestyle={{ width: 423 }}>
            {submitting ? <Loader inline size="small" active /> : 'Download'}
          </Button>
        </ButtonContainer>
        <div style={{ padding: 24 }}>
          {errorMessages.map((message, index) => (
            <div style={{ color: 'red', marginTop: 8 }} key={index}>
              {message}
            </div>
          ))}
        </div>
      </ModalContent>
    </Modal>
  );
};
export default TpInvoice;
