import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import axios from 'axios';
import { DownCarotIcon, RightCarotIcon, SearchGlassIcon } from '../../../ui/icons';
import { Assignee, SearchCategories, Property, DropdownOption, Search } from '../../types';
import Employees from './Employees';
import Vendors from './Vendors';
import Statuses from './Statuses';
import { useOrderEntry } from '../../OrderEntryContext';
import qs from 'qs';
import { fiveBoros } from '../../../../lib/PublicFunctions';
import NjCounties from '../../../../NjCounties.json';

const OuterSearchType = styled.div`
  cursor: pointer;
  margin-bottom: 16px;
  background: #ffffff;
  border: 1px solid #e5e5ea;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.149);
  border-radius: 5px;
  position: absolute;
  top: 62px;
  left: 78px;
  z-index: 500;
  width: 272px;
  max-height: 280px;
  overflow: auto;
  padding: 16px 16px 8px 16px;
`;
const SearchStyles = styled.input`
  height: 32px;
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  width: 216px;
  padding-left: 34px;
  :focus {
    border: 1px solid #e5e5ea;
  }
`;
const AssigneeField = styled.div`
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 272px;
  height: 48px;
  margin-left: 78px;
  margin-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
`;
const AssignmentType = styled.span`
  background-color: #e5e5ea;
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  padding: 4px 8px;
  margin-right: 12px;
`;


interface Props {
  addAssigneeClick?: (i: Assignee) => void;
  type: string;
  employees: any;
  vendors: any;
  statuses: any;
  assignees?: any;
  searches: any;
  isFirstType?: boolean;
  category: SearchCategories;
  selectedEmployee: any;
  searchTypes: Search[];
}

const njCountyIds = NjCounties.map(njc => njc.Id);

const AssignDropdown = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isExpandedEmployees, setIsExpandedEmployees] = useState(false);
  const [isExpandedVendors, setIsExpandedVendors] = useState(false);
  const [isExpandedStatuses, setIsExpandedStatuses] = useState(false);
  const [searchText, setSearchText] = useState<string>();
  //const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isSelected, setIsSelected] = useState(false);
  const {
    properties,
    searchesAssignObj,
    specialOrderSearches,
    searchTypesToRequested,
    searchTypesToDataTrace,
    dataTraceMunis,
  } = useOrderEntry();
  const isEmployee =
    searchText &&
    props.employees.filter((st: any) => {
      return st.name.toLowerCase().includes(searchText);
    }).length > 0;
  const isVendor =
    searchText &&
    props.vendors.filter((st: any) => {
      return st.name.toLowerCase().includes(searchText);
    }).length > 0;
  const isStatus =
    searchText &&
    props.statuses.filter((st: any) => {
      return st.name.toLowerCase().includes(searchText);
    }).length > 0;
  const handleSearchTextChange = (searchText: any) => {
    setSearchText(searchText);
  };
  useEffect(() => {
    window.addEventListener('click', () => {
      setIsOpen(false);
    });
    return () => {
      window.removeEventListener('click', () => {
        setIsOpen(false);
      });
    };
  }, []);
  useEffect(() => {
    getDefaultAssignee().then((assignee: any) => {
      props.addAssigneeClick(assignee);
    });
  }, [JSON.stringify(props.employees), JSON.stringify(Object.keys(searchesAssignObj)), JSON.stringify(Object.keys(properties))]);

  const getMuniIds = (
    property: Property
  ): { countyId: number; municipalityId?: number; subMunicipalityId?: number } => {
    if (property.countyOnly) {
      const { id } = property.selectedCounty;
      return {
        countyId: id,
        subMunicipalityId: undefined,
        municipalityId: undefined,
      };
    }
    const { countyId, municipalityId, subMunicipalityId } = property?.selectedCity;

    return { countyId, municipalityId, subMunicipalityId };
  };

  const GetAbstractVendorByCounty = (countyId: number) => {
    const executiveCounties = [1825, 1846, 1853, 1863]; //kings, bronx, queens, new york
    if (executiveCounties.includes(countyId)) {
      return "Moody Eagle Abstracts LLC";
    }
    else {
      return "Ryco Information Services, Inc.";
    }
  }

  const getDefaultAssignee = async () => {
    const taxOnly = (searchesAssignObj.Municipal || []).length === 0;
    if (props.category === 'B & P') {
      const { data } = await axios.get('/api/users/defaultassignee');
      return props.employees.find((e: any) => e.id === data.id);
    }
    if (properties.find((p) => fiveBoros.includes(p.selectedCity.municipality)) && props.searchTypes.find((cs) => specialOrderSearches.includes(cs.type))) {
      return props.employees.find((e: any) => e.name === 'Regina Laws')
    }
    if (
      properties.find(
        p => dataTraceMunis.includes(p.selectedCity.municipality) && !p.selectedCity.subMunicipality
      ) &&
      props.searchTypes.find(cs => searchTypesToDataTrace.includes(cs.code))
    ) {
      return props.employees.find((e: any) => e.name === 'Justin Lim');
    }
    if (
      properties.find(
        p => dataTraceMunis.includes(p.selectedCity.municipality) && !p.selectedCity.subMunicipality
      ) &&
      props.searchTypes.find(cs => searchTypesToRequested.includes(cs.code))
    ) {
      return props.statuses.find((v: any) => {
        return v.name.toLowerCase() === 'requested'
       } )
    }


    if (properties.length > 0 && ['Municipal'].includes(props.category) || (['Municipal', 'Tax'].includes(props.category) && (properties.find((p) => fiveBoros.includes(p.selectedCity.municipality)) || properties.find(p => njCountyIds.includes(p.selectedCity.countyId)) ))) {
      const property = properties[0];
      const { countyId, municipalityId, subMunicipalityId } = getMuniIds(property);
      const queryString = qs.stringify({ countyId, municipalityId, subMunicipalityId });
      const { data } = await axios.get(`/api/users/getMuniDefaultAssignee?${queryString}`);
      return props.employees.find((e: any) => e.id === data?.id);
    }
    if (props.category === 'Abstract') {
      let vendor: string;
      properties.forEach(p => {
        const { countyId } = getMuniIds(p);
        let defaultVendor = GetAbstractVendorByCounty(countyId);
        if (vendor == undefined) {
          vendor = defaultVendor
        }
        else if (vendor != defaultVendor) {
          vendor = null;
          return;
        };
      });
      return props.vendors.find((v: any) => v.name?.includes(vendor))
    }
  };

  return (
    <React.Fragment>
      <div style={{ position: 'relative' }}>
        <div
          style={{ position: 'relative' }}
          onClick={(e: any) => {
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
        >
          <AssigneeField
            onClick={(e: any) => {
              e.stopPropagation();
              //props.addAssigneeClick(e.target.value);
              setIsOpen(!isOpen);
            }}
          >
            {(props.isFirstType ||
              properties.find(p => fiveBoros.includes(p.selectedCity.municipality)) ||
              properties.find(
                p => dataTraceMunis.includes(p.selectedCity.municipality) && !p.selectedCity.subMunicipality
              ) ||
              isSelected) &&
            props.selectedEmployee ? (
              <span>{`${props.selectedEmployee.name}`}</span>
            ) : (
              <span style={{ color: '#E5E5EA' }}>Select assignee</span>
            )}
            {props.selectedEmployee && props.selectedEmployee.isStatus ? (
              <span />
            ) : (
              ((props.isFirstType && props.selectedEmployee) || isSelected) && (
                <AssignmentType>
                  {props.selectedEmployee && props.selectedEmployee.isVendor
                    ? 'Vendor'
                    : props.selectedEmployee && `${props.selectedEmployee.assignmentType}`}
                </AssignmentType>
              )
            )}
            <span style={{ position: 'absolute', top: 12, right: 8 }}>
              <DownCarotIcon />
            </span>
          </AssigneeField>
        </div>
        {isOpen && (
          <OuterSearchType
            onClick={(e: any) => {
              e.stopPropagation();
            }}
          >
            <SearchGlassIcon style={{ position: 'absolute', top: 26, left: 28 }} />
            <SearchStyles
              type="text"
              onChange={(e: any) => {
                handleSearchTextChange(e.target.value.toLowerCase());
              }}
              defaultValue={searchText}
            />
            <div
              onClick={() => setIsExpandedEmployees(!isExpandedEmployees)}
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <span style={{ fontWeight: 'bold', marginBottom: 8, marginTop: 8 }}>Employees</span>
              <span>{isExpandedEmployees || isEmployee ? <DownCarotIcon /> : <RightCarotIcon />}</span>
            </div>
            {(isExpandedEmployees || isEmployee) && (
              <Employees
                employees={props.employees.filter((st: any) => {
                  return st.name.toLowerCase().includes(searchText || '');
                })}
                addAssigneeClick={(i: any) => {
                  setIsOpen(false);
                  props.addAssigneeClick(i);
                }}
                isSelected={() => setIsSelected(true)}
              />
            )}
            <div
              onClick={() => setIsExpandedVendors(!isExpandedVendors)}
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <span style={{ fontWeight: 'bold', marginBottom: 8, marginTop: 8 }}>Vendors</span>
              <span>{isExpandedVendors || isVendor ? <DownCarotIcon /> : <RightCarotIcon />}</span>
            </div>
            {(isExpandedVendors || isVendor) && (
              <Vendors
                vendors={props.vendors.filter((st: any) => {
                  return st.name.toLowerCase().includes(searchText || '');
                })}
                addAssigneeClick={(i: any) => {
                  setIsOpen(false);
                  props.addAssigneeClick(i);
                }}
                isSelected={() => setIsSelected(true)}
              />
            )}
            <div
              onClick={() => setIsExpandedStatuses(!isExpandedStatuses)}
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <span style={{ fontWeight: 'bold', marginBottom: 8, marginTop: 8 }}>Statuses</span>
              <span>{isExpandedStatuses || isStatus ? <DownCarotIcon /> : <RightCarotIcon />}</span>
            </div>
            {(isExpandedStatuses || isStatus) && (
              <Statuses
                statuses={props.statuses.filter((st: any) => {
                  return st.name.toLowerCase().includes(searchText || '');
                })}
                addAssigneeClick={(i: any) => {
                  setIsOpen(false);
                  props.addAssigneeClick(i);
                }}
                isSelected={() => setIsSelected(true)}
              />
            )}
          </OuterSearchType>
        )}
      </div>
    </React.Fragment>
  );
};

export default AssignDropdown;
