/** @jsx jsx */
import { jsx } from '@emotion/core';
import axios from 'axios';
import React, { useState, useRef, useEffect } from 'react';
import colors from '../../css/colors';
import { DatePicker } from 'react-widgets';
import { Link } from 'react-router-dom';
import { FaStar } from "react-icons/fa6";
import moment from 'moment';
import 'semantic-ui-css/semantic.min.css';
import styled from '@emotion/styled';
import { Grid, GridColumn, Table, Icon, Popup, List, Checkbox, Dropdown } from 'semantic-ui-react';
import ReactToPrint from 'react-to-print';
import GridRow from 'semantic-ui-react/dist/commonjs/collections/Grid/GridRow';
import Select from 'react-select';
import Button from '../ui/Button';
import ReportAssignModal from './ReportAssignModal';
import ReportUnassignModal from './ReportUnassignModal';
import qs from 'query-string';
import CurrentAssignment from './CurrentAssignment';
import KeyPressAssign from './KeyPressAssign';

const defaultSelectedCounties = [
  {
    value: 1825,
    label: 'Bronx',
  },
  {
    value: 1846,
    label: 'Kings',
  },
  {
    value: 1853,
    label: 'New York',
  },
  {
    value: 1863,
    label: 'Queens',
  },
  {
    value: 1865,
    label: 'Richmond',
  },
];

const fromStyles = {
  width: 300,
  display: 'inline-block',
};
const toStyles = {
  width: 300,
  marginLeft: 16,
  display: 'inline-block',
};
const totalResults = {
  display: 'none',
  '@media print': {
    display: 'block',
  },
};
const rushIcon = {
  color: colors.redActive,
  '&:hover': {
    color: colors.redLight,
    cursor: 'pointer',
  },
  '@media print': {
    display: 'none',
  },
};
const superRushIcon = {
  color: '#c50f2a',
  '&:hover': {
    color: 'red',
    cursor: 'pointer',
  },
  '@media print': {
    display: 'none',
  },
};

const noPrint = {
  '@media print': {
    display: 'none',
  },
};

const CurrentAssignWrapper = styled.div`
  margin-bottom: 24px;
  margin-top: 24px;
`;

const getInitials = str => {
  if (!str) return;

  return str
    .split(' ')
    .map(n => n[0].toUpperCase())
    .join('.');
};

const searchTypes = [
  { id: 0, text: 'All', value: 0 },
  { id: 1, text: 'Municipal', value: 1 },
  { id: 2, text: 'Tax', value: 2 },
  { id: 3, text: 'Abstract', value: 3 },
];
const statusTypes = [
  { id: 2, text: 'In Progress', value: 2 },
  { id: 13, text: 'Waiting for Info', value: 13 },
  { id: 14, text: 'Waiting for Examiner', value: 14 },
];
export default function InProgressReport() {
  const [reportData, setReportData] = useState([]);
  const [showReport, setShowReport] = useState(false);
  const [fromDate, setFromDate] = useState(new Date(moment().subtract(1, 'years').format()));
  const [toDate, setToDate] = useState(new Date());
  const [counties, setCounties] = useState([]);
  const [selectedSearchType, setSelectedSearchtype] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedCounties, setSelectedCounties] = useState(defaultSelectedCounties);
  const [clients, setClients] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [assignModal, setAssignModal] = useState(false);
  const [unassignModal, setUnassignModal] = useState(false);
  // const [city, setCity] = useState('')
  // const [town, setTown] = useState('')
  // const [village, setVillage] = useState('')
  const [searchesToAssign, setSearchestoAssign] = useState([]);
  const [loading, setLoading] = useState(false);

  const formatDate = date => moment(date).format('M/D/YYYY');

  useEffect(() => {
    axios.get('/api/municipalities/GetCountyOnlyNames').then(({ data }) => {
      setCounties(data);
    });
    axios.get('/api/users/forAssignment').then(({ data }) => {
      setUsers(data);
    });
    axios.get('/api/clients/getclientnames').then(({ data }) => {
      setClients(data);
    });
  }, []);

  const updateOrder = (order, updateRush, updateSuperRush) => {
    axios.post(`/api/orders/updategeneralinfo/${order.orderId}`, {
      clientId: order.clientId,
      titleNumber: order.titleNumber,
      isRush: updateRush ? true : order.isRush,
      isSuperRush: updateSuperRush ? true : order.isSuperRush,
    });
    setReportData(rd =>
      rd.map(data =>
        data.orderId === order.orderId
          ? {
              ...data,
              isRush: updateRush ? true : data.isRush,
              isSuperRush: updateSuperRush ? true : data.isSuperRush,
            }
          : data
      )
    );
  };

  async function handleSubmitClick() {
    setLoading(true);
    const counties = (selectedCounties || []).map(sc => sc.value);
    const users = (selectedUsers || []).map(su => su.value);
    const clients = (selectedClients || []).map(sc => sc.value);
    const queryString = qs.stringify({
      from: formatDate(fromDate),
      to: formatDate(toDate),
      selectedCounties: counties,
      selectedClients: clients,
      selectedUsers: users,
      selectedSearchType,
      selectedStatus,
    });

    const { data } = await axios.get(`/api/reports/inProgressReport?${queryString}`);
    setReportData(data);

    setLoading(false);
    setShowReport(true);
  }

  const onAssign = () => {
    handleSubmitClick();
    setSearchestoAssign([]);
    setAssignModal(false);
    setUnassignModal(false);
  };

  const handleCheckChange = (searchId, idx) => (e, { checked }) => {
    if (e.shiftKey && checked && searchesToAssign.length > 0) {
      const lastChecked = searchesToAssign[searchesToAssign.length - 1];
      const reportDataIndexOfLastChecked = reportData.findIndex(r => r.searchId === lastChecked);
      const searchIds = reportData
        .filter((r, i) => {
          if (idx > reportDataIndexOfLastChecked) {
            return i > reportDataIndexOfLastChecked && i <= idx;
          } else {
            return i >= idx && i < reportDataIndexOfLastChecked;
          }
        })
        .map(r => r.searchId);
      const newSearchewstoAssign = [...searchesToAssign].concat(searchIds);
      setSearchestoAssign([...new Set(newSearchewstoAssign)]);
    } else {
      setSearchestoAssign(searches =>
        checked ? searches.concat([searchId]) : searches.filter(s => s !== searchId)
      );
    }
  };

  const tableRef = useRef(null);

  const renderResults = () => {
    return (
      <Table size="small" compact className="tableTwelve">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell css={noPrint} />
            <Table.HeaderCell>Client</Table.HeaderCell>
            <Table.HeaderCell>Address</Table.HeaderCell>
            <Table.HeaderCell>Municipality</Table.HeaderCell>
            <Table.HeaderCell>Hds#</Table.HeaderCell>
            <Table.HeaderCell>Title Number</Table.HeaderCell>
            <Table.HeaderCell>Date Added</Table.HeaderCell>
            <Table.HeaderCell>Search Type</Table.HeaderCell>
            <Table.HeaderCell>Assigned To</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Rush Status</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {reportData.map((d, idx) => {
            const muniType = d['municipalityType'] || '';
            const subMuniType = d['subMunicipalityType'] || '';
            return (
              <Table.Row key={idx}>
                <Table.Cell css={noPrint} collapsing>
                  <Checkbox
                    checked={searchesToAssign.includes(d.searchId)}
                    onChange={handleCheckChange(d.searchId, idx)}
                  />
                </Table.Cell>
                <Table.Cell>{d.isPriorityClient && <FaStar color='red' />}{d['clientName']}</Table.Cell>
                <Table.Cell> {d['address']}</Table.Cell>
                <Table.Cell>
                  {d['subMunicipality']
                    ? `${subMuniType.slice(0, 1)} - ${d['subMunicipality']}, ${d['countyName']}`
                    : `${muniType?.slice(0, 1)} - ${d['municipality']}, ${d['countyName']}`}
                </Table.Cell>
                <Table.Cell>{`HDS${d['orderId']}`}</Table.Cell>
                <Table.Cell>
                  <Link
                    to={{
                      pathname: `/orders/order-information/${d.orderId}`,
                    }}
                    target="none"
                  >
                    {d['titleNumber']}
                  </Link>
                </Table.Cell>
                <Table.Cell>{formatDate(d['orderDate'])}</Table.Cell>
                <Table.Cell>{d['searchType']}</Table.Cell>
                <Table.Cell>{d['assignedTo'] || 'Unassigned'}</Table.Cell>
                <Table.Cell>{d['status']}</Table.Cell>
                <Table.Cell>{d.isSuperRush ? 'Super Rush' : d.isRush ? 'Rush' : ''}</Table.Cell>
                <Table.Cell css={!d.isRush ? rushIcon : {}}>
                  {!d.isRush && !d.isSuperRush ? (
                    <Popup
                      content="Mark Rush"
                      trigger={
                        <Icon
                          name="lightning"
                          circular
                          //css={rushicon}
                          onClick={() => {
                            if (d.isRush) return;
                            updateOrder(d, true, false);
                          }}
                        />
                      }
                    ></Popup>
                  ) : (
                    ''
                  )}
                </Table.Cell>
                <Table.Cell css={!d.isSuperRush ? superRushIcon : {}}>
                  {!d.isSuperRush ? (
                    <Popup
                      content="Mark Super Rush"
                      trigger={
                        <Icon
                          name="warning"
                          circular
                          //css={rushicon}
                          onClick={() => {
                            if (d.isSuperRush) return;
                            updateOrder(d, false, true);
                          }}
                        />
                      }
                    ></Popup>
                  ) : (
                    ''
                  )}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  };

  return (
    <div css={{ paddingBottom: 32 }}>
      <KeyPressAssign searches={searchesToAssign} onAssign={onAssign} />
      <div className="report">
        <style>
          {`.report {padding: 15px} .totalResults {display: block;} .tableTwelve td {max-width: 160px;overflow: hidden; text-overflow: ellipsis;white-space: nowrap; padding: .2em .3em !important;}`}
        </style>
        <h1 css={{ marginBottom: 16 }}>In Progress Report</h1>
        <CurrentAssignWrapper>
          <CurrentAssignment searchStatus="In Progress" orders={reportData} />
        </CurrentAssignWrapper>
        <Grid>
          <GridRow columns={2}>
            <GridColumn width={8}>
              <div style={fromStyles}>
                <label>From</label>
                <DatePicker format="M/D/YYYY" value={fromDate} time={false} onChange={e => setFromDate(e)} />
              </div>
              <div style={toStyles}>
                <label>To</label>
                <DatePicker format="M/D/YYYY" value={toDate} time={false} onChange={e => setToDate(e)} />
              </div>
            </GridColumn>
          </GridRow>
          <GridRow columns={6}>
            <GridColumn width={3}>
              <Select
                options={counties.map(c => ({
                  label: `${c.name}`,
                  value: c.id,
                }))}
                onChange={selections => {
                  setSelectedCounties(selections);
                }}
                value={selectedCounties}
                placeholder="Select Counties"
                isClearable={true}
                isMulti
              />
            </GridColumn>
            <GridColumn width={3}>
              <Select
                options={users.map(u => ({
                  label: `${u.name}`,
                  value: u.id,
                }))}
                onChange={selections => {
                  setSelectedUsers(selections);
                }}
                value={selectedUsers}
                placeholder="Select Users"
                isClearable={true}
                isMulti
              />
            </GridColumn>
            <GridColumn width={3}>
              <Select
                options={clients.map(c => ({
                  label: `${c.name}`,
                  value: c.id,
                }))}
                onChange={selections => {
                  setSelectedClients(selections);
                }}
                value={selectedClients}
                placeholder="Select Clients"
                isClearable={true}
                isMulti
              />
            </GridColumn>
            <Grid.Column width={2}>
              <Dropdown
                style={{ height: 38 }}
                options={searchTypes}
                onChange={(__, { value }) => setSelectedSearchtype(value)}
                placeholder="Filter Search Category"
                clearable
                selection
                value={selectedSearchType}
                labeled
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <Dropdown
                style={{ height: 38 }}
                options={statusTypes}
                onChange={(__, { value }) => setSelectedStatus(value)}
                placeholder="Filter Status"
                clearable
                selection
                value={selectedStatus}
                labeled
              />
            </Grid.Column>
            <Grid.Column>
              <span
                css={{
                  marginLeft: 16,
                  verticalAlign: 'bottom',
                  textAlign: 'right',
                }}
              >
                <Button
                  loadingStatus={loading}
                  onClick={() => handleSubmitClick()}
                  overRidestyle={{ height: 38 }}
                >
                  Submit
                </Button>
              </span>
            </Grid.Column>
          </GridRow>
        </Grid>
        {showReport && !reportData.length && (
          <Grid>
            <GridRow>
              {selectedStatus === 2
                ? 'No items are In Progress.'
                : selectedStatus === 13
                ? 'No items are Waiting for Info.'
                : selectedStatus === 14
                ? 'No items are Waiting for Examiner.'
                : 'No items'}
            </GridRow>
          </Grid>
        )}
        {Boolean(showReport && reportData.length) && (
          <React.Fragment>
            <div style={{ marginTop: 50 }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>{`Total Items: ${reportData.length}`}</div>
                  <div style={{ marginLeft: 35 }}>
                    <ReportAssignModal
                      trigger={
                        <Button disabled={searchesToAssign.length === 0} onClick={() => setAssignModal(true)}>
                          Assign
                        </Button>
                      }
                      searchesToAssign={searchesToAssign}
                      onAssign={onAssign}
                      setAssignModalOpen={setAssignModal}
                      assignModalOpen={assignModal}
                    />
                  </div>
                  <div style={{ marginLeft: 8 }}>
                    <ReportUnassignModal
                      trigger={
                        <Button
                          disabled={searchesToAssign.length === 0}
                          onClick={() => setUnassignModal(true)}
                          secondary
                        >
                          Unassign
                        </Button>
                      }
                      searchesToAssign={searchesToAssign}
                      onAssign={onAssign}
                      setUnassignModalOpen={setUnassignModal}
                      unassignModalopen={unassignModal}
                    />
                  </div>
                </div>
                <div style={{ marginRight: 120 }}>
                  <ReactToPrint
                    trigger={() => <Button secondary>Print</Button>}
                    content={() => tableRef.current}
                  />
                </div>
              </div>
              <Grid.Row columns={2}>
                <GridColumn width={12}>
                  <div style={{ overflow: 'auto' }} className="report" ref={tableRef}>
                    {renderResults()}
                    <div css={totalResults}>{`Total Items: ${reportData.length}`}</div>
                  </div>
                </GridColumn>
              </Grid.Row>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
