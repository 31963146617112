import React, { useState } from 'react';
import { changeInstallmentYears, getYearsForDropDown, populateInstallmentsOnCheck } from '../../Functions';
import { customStyles, statusOptions, textBoxStyles, waterSewerTaxTypes } from '../../TaxObjectsAndStyles';
import * as types from '../../types';
import Select from 'react-select';
import colors from 'css/colors';
import ToggleCheckBox from 'components/ui/CheckBox/ToggleCheckBox';
import MultipleInstallment from './MultipleInstallment';
import SingleInstallment from './SingleInstallment';
import FullTaxAmount from './FullTaxAmount';
import Relevies from './Relevies';
import ChargeBacks from './ChargeBacks';
import Exemptions from './Exemptions';
import DifferentAssessment from './DifferentAssessment';
import { useNysTaxSearch } from '../../NysTaxSearchContext';
import TextArea from 'components/forms/ui/TextArea';
import Checkbox from 'components/ui/CheckBox';
import RochesterEmbellishments from './RochesterEmbellishments';

export const smallCustomStyles = {
  container: (provided: any) => ({
    ...provided,
    width: 252,
  }),
  control: (provided: any, state: { isFocused: boolean }) => ({
    ...provided,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused ? '1px solid #ff5252' : `1px solid ${colors.grey}`,
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};
interface PropTypes {
  taxDetails: types.SplitTaxCollected;
  taxIndex: number;
}

const rocklandNote =
  'Note: Town/County taxes are payable in full or in 4 quarterly Installments. Owner/Purchaser must contact the tax office if they choose to pay quarterly.\n';

const TaxSection = (props: PropTypes) => {
  const { taxDetails, taxIndex } = props;
  const [includeRebateNote, setIncludeRebateNote] = useState(false);
  const [specialDistrictCharges, setSpecialDistrictCharges] = useState(false);
  const [safetyInspectionNote, setSafetyInspectionNote] = useState(false);
  const [mvbNote, setMvbNote] = useState(false);
  const [propManagementNote, setPropManagementNote] = useState(false);
  const [etpaFeeNote, setEtpaFeeNote] = useState(false);
  const {
    fullListOfSchoolAuth,
    boundSelectedSchoolDistrict,
    selectedSchoolDistrict,
    propertyTaxes: fullListOfTaxes,
    setPropertyTaxes,
    handlePropertyTaxesChanges,
    handleSelectSchoolDistrictChange: selectSchoolDistrictChangeHandler,
    handleRemoveItemFromPropertyTaxesDetails,
    property,
    client,
    handleBasicStar,
    applyAmountToAll
  } = useNysTaxSearch();
  if ([...waterSewerTaxTypes].includes(taxDetails.taxDescriptionType.type)) {
    return null;
  }
  const yearOptions = getYearsForDropDown();
  const schoolAuthOpts = fullListOfSchoolAuth.map(t => {
    return {
      value: t.taxAuthorityId,
      label: t.taxAuthorityName,
    };
  });

  const handlePopulateInstallmentsOnCheck = (multipleInstallments: boolean) => {
    const additionalText = taxDetails.taxDescriptionType.type === 'Town/County' ? rocklandNote : null;
    const newState = populateInstallmentsOnCheck(
      taxIndex,
      multipleInstallments,
      fullListOfTaxes,
      property.county,
      additionalText
    );
    setPropertyTaxes(newState);
  };
  const handleAddInstallment = (taxIndex: number) => {
    let installments: types.Installment[] =
      taxDetails.installments !== undefined ? [...taxDetails.installments] : [];

    const baseInstallment: types.Installment = {
      date: {
        month: { label: undefined, value: undefined },
        day: { label: undefined, value: undefined },
        year: { label: undefined, value: undefined },
      },
      amount: '',
      status: {value: '', label: ''},
      statusNote: '',
    };
    installments.push(baseInstallment);
    handlePropertyTaxesChanges('installments', installments, taxIndex);
  };
  const [additionalInfoLength, setAdditionalInfoLength] = React.useState(0);

  const closingIds = [121, 288, 362, 420, 623];

  return (
    <div>
      {taxDetails.taxDescriptionType.type === 'School' && fullListOfSchoolAuth.length > 1 && (
        <Select
          styles={customStyles}
          options={schoolAuthOpts}
          onChange={selectSchoolDistrictChangeHandler}
          value={boundSelectedSchoolDistrict}
        />
      )}
      {(taxDetails.taxDescriptionType.type !== 'School' ||
        fullListOfSchoolAuth.length === 1 ||
        selectedSchoolDistrict) && (
        <div style={{ marginTop: 32 }}>
          <span style={{ marginBottom: 8, display: 'inline-block' }}>
            {taxDetails.taxDescriptionType.type} Payments/Status for
          </span>
          <div>
            <Select
              options={yearOptions}
              placeholder="Select year"
              onChange={(option: { value: number; label: string }) => {
                const newState = changeInstallmentYears(taxIndex, option.value, fullListOfTaxes);
                setPropertyTaxes(newState);
              }}
              value={yearOptions.find(o => o.value === taxDetails.yearApplicable)}
              styles={smallCustomStyles}
            />
          </div>
          <div key={`taxAuthority${taxDetails.id}`}>
            {taxDetails.installmentMonths.length > 1 && taxDetails.areInstallmentsOptional && (
              <div style={{ marginTop: 10 }}>
                <span
                  style={{
                    marginBottom: 8,
                    display: 'inline-block',
                  }}
                >
                  Paid in multiple installments?
                </span>

                <ToggleCheckBox
                  label="Multiple Installments"
                  value={taxDetails.paidInMultipleInstallments}
                  onChange={(e: boolean) => {
                    handlePopulateInstallmentsOnCheck(e);
                    if (e && property.county === 'Rockland') {
                      setAdditionalInfoLength(rocklandNote.length + 1);
                    }
                    if (!e && property.county === 'Rockland') {
                      setAdditionalInfoLength(length => length - rocklandNote.length);
                    }
                  }}
                />
              </div>
            )} 
            {taxDetails.installmentMonths.length > 1 && taxDetails.paidInMultipleInstallments ? (
              <React.Fragment>
                {taxDetails.installments.map((i, index) => {
                  return (
                    <MultipleInstallment
                      installment={i}
                      index={index}
                      multipleInstallments={taxDetails.installments.length > 1}
                      optionalInstallments={taxDetails.areInstallmentsOptional}
                      taxIndex={taxIndex}
                      changeFunction={handlePropertyTaxesChanges}
                      removeFunction={handleRemoveItemFromPropertyTaxesDetails}
                      yearOptions={getYearsForDropDown()}
                      applyAmountToAll={applyAmountToAll}
                    />
                  );
                })}
                {/* <span
                  style={{
                    fontWeight: 'bold',
                    color: colors.redLight,
                    cursor: 'pointer',
                  }}
                  onClick={() => handleAddInstallment(taxIndex)}
                >
                  Add Installment
                </span> */}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <SingleInstallment taxIndex={taxIndex} installment={taxDetails.singleInstallment} />
                <FullTaxAmount
                  taxDetails={taxDetails}
                  taxIndex={taxIndex}
                  changeFunction={handlePropertyTaxesChanges}
                />
              </React.Fragment>
            )}
            {taxDetails.taxDescriptionType.type.toLowerCase() !== 'school' && (
              <Relevies
                taxDetails={taxDetails}
                taxIndex={taxIndex}
                showInstallmentRelevieOption={taxDetails.paidInMultipleInstallments && taxDetails.hasRelevies}
                handleChangeFunction={handlePropertyTaxesChanges}
                handleRemoveFunction={handleRemoveItemFromPropertyTaxesDetails}
              />
            )}
            <ChargeBacks taxDetails={taxDetails} taxIndex={taxIndex} county={property.county}/>
            <Exemptions
              taxDetails={taxDetails}
              taxIndex={taxIndex}
              changeFunction={handlePropertyTaxesChanges}
              isPriorItem={false}
              removeFunction={handleRemoveItemFromPropertyTaxesDetails}
            />
            <DifferentAssessment taxDetails={taxDetails} taxIndex={taxIndex} />
            {taxDetails.taxDescriptionType.type.includes('City') &&
              property.municipality === 'Rochester' &&
              property.county === 'Monroe' &&
              property.subMunicipality === null &&
              closingIds.includes(client) && (
                <RochesterEmbellishments taxDetails={taxDetails} taxIndex={taxIndex} />
              )}
            <div style={{ display: 'flex', gap: 16, marginTop: 24 }}>
              <div>
                <Checkbox
                  label="Include Rebate Note"
                  checked={includeRebateNote}
                  onCheckChanged={(checked: any) => {
                    handleBasicStar(checked, taxIndex);
                    setIncludeRebateNote(!includeRebateNote);
                  }}
                />
              </div>
              {taxDetails.taxDescriptionType.type !== 'School' && (
                <Checkbox
                  label="Special District Charges"
                  checked={specialDistrictCharges}
                  onCheckChanged={(checked: any) => {
                    setSpecialDistrictCharges(checked);
                    if (checked) {
                      handlePropertyTaxesChanges(
                        'additionalInformation',
                        (taxDetails.additionalInformation ? taxDetails.additionalInformation : '') +
                          `${taxDetails.additionalInformation !== '' ? '\n' : ''}Note: Tax amount shown is for special district charges`,
                        taxIndex
                      );
                    } else {
                      handlePropertyTaxesChanges(
                        'additionalInformation',
                        taxDetails.additionalInformation.replace(
                          `${taxDetails.additionalInformation !== '' ? '\n' : ''}Note: Tax amount shown is for special district charges`,
                          ''
                        ),
                        taxIndex
                      );
                    }
                  }}
                />
              )}
              {taxDetails.taxDescriptionType.type === 'County' && property.municipality === 'Yonkers' && (
                <Checkbox
                  label="Safety Inspection Note"
                  checked={safetyInspectionNote}
                  onCheckChanged={(checked: any) => {
                    setSafetyInspectionNote(checked);
                    if (checked) {
                      handlePropertyTaxesChanges(
                        'additionalInformation',
                        (taxDetails.additionalInformation ? taxDetails.additionalInformation : '') +
                          'Note: County tax includes Safety Inspection Fee of $\n',
                        taxIndex
                      );
                    } else {
                      handlePropertyTaxesChanges(
                        'additionalInformation',
                        taxDetails.additionalInformation.replace(
                          'Note: County tax includes Safety Inspection Fee of $\n',
                          ''
                        ),
                        taxIndex
                      );
                    }
                  }}
                />
              )}
              <Checkbox
                label="Tax w/o Ex N/A"
                caps={false}
                checked={taxDetails.additionalInformation?.includes(`${taxDetails.additionalInformation && taxDetails.additionalInformation?.trim() !==  `Tax w/o Exemptions: N/A`? '\n' : ''}Tax w/o Exemptions: N/A`) || false}
                onCheckChanged={(checked: any) => {
                  if (checked) {
                    handlePropertyTaxesChanges(
                      'additionalInformation',
                      (taxDetails.additionalInformation ? taxDetails.additionalInformation : '') +
                        `${taxDetails.additionalInformation ? '\n' : ''}Tax w/o Exemptions: N/A`,
                      taxIndex
                    );
                  } else {
                    handlePropertyTaxesChanges(
                      'additionalInformation',
                      taxDetails.additionalInformation.replace(`${taxDetails.additionalInformation?.trim() !== 'Tax w/o Exemptions: N/A'? '\n': '' }Tax w/o Exemptions: N/A`, ''),
                      taxIndex
                    );
                  }
                }}
              />
            </div>
            {taxDetails.taxDescriptionType.type.includes('City') && property.municipality === 'Schenectady' && (
                <div style={{display:'flex',gap: 16, marginTop: 8}}>
                <Checkbox
                  label="MVB"
                  checked={mvbNote}
                  onCheckChanged={(checked: any) => {
                    setMvbNote(checked);
                    if (checked) {
                      handlePropertyTaxesChanges(
                        'additionalInformation',
                        (taxDetails.additionalInformation ? taxDetails.additionalInformation : '') +
                          '\nMunicipal voucher bill by R. Wolbert, Invoice # in the amount of $ - Open / Payable to City \n',
                        taxIndex
                      );
                    } else {
                      handlePropertyTaxesChanges(
                        'additionalInformation',
                        taxDetails.additionalInformation.replace(
                          '\nMunicipal voucher bill by R. Wolbert, Invoice # in the amount of $ - Open / Payable to City \n',
                          ''
                        ),
                        taxIndex
                      );
                    }
                  }}
                />
                <Checkbox
                  label="Prop. Mngmnt"
                  checked={propManagementNote}
                  onCheckChanged={(checked: any) => {
                    setPropManagementNote(checked);
                    if (checked) {
                      handlePropertyTaxesChanges(
                        'additionalInformation',
                        (taxDetails.additionalInformation ? taxDetails.additionalInformation : '') +
                          '\nProperty management by R. Wolbert, Bill # in the amount of $ - Open / Payable to City \n',
                        taxIndex
                      );
                    } else {
                      handlePropertyTaxesChanges(
                        'additionalInformation',
                        taxDetails.additionalInformation.replace(
                          '\nProperty management by R. Wolbert, Bill # in the amount of $ - Open / Payable to City \n',
                          ''
                        ),
                        taxIndex
                      );
                    }
                  }}
                />
                </div>
              )}
              {property.municipality === 'Yonkers' && 
              <div style={{marginTop:8}}>
                <Checkbox
                  label="ETPA Fee Note"
                  checked={etpaFeeNote}
                  onCheckChanged={(checked: any) => {
                    setEtpaFeeNote(checked);
                    if (checked) {
                      handlePropertyTaxesChanges(
                        'additionalInformation',
                        (taxDetails.additionalInformation ? taxDetails.additionalInformation : '') +
                          'Note: City/School tax includes ETPA Fee of $\n',
                        taxIndex
                      );
                    } else {
                      handlePropertyTaxesChanges(
                        'additionalInformation',
                        taxDetails.additionalInformation.replace(
                          'Note: City/School tax includes ETPA Fee of $\n',
                          ''
                        ),
                        taxIndex
                      );
                    }
                  }}
                />
              </div>}
            <TextArea
              maxLength={taxDetails.taxDescriptionType.type === 'Town/County' ? 500 : 524288}
              styles={textBoxStyles}
              placeholder="Additional Information"
              onChange={(e: any) => {
                handlePropertyTaxesChanges('additionalInformation', e.target.value, taxIndex);
                setAdditionalInfoLength(e.target.value.length + 1);
              }}
              error={undefined}
              label={undefined}
              value={taxDetails.additionalInformation}
            />
            {taxDetails.taxDescriptionType.type === 'Town/County' && additionalInfoLength > 500 && (
              <span style={{ color: 'red' }}>
                There is a 500 character limit on this field. Please add any additional information as an
                attachement.
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default TaxSection;
