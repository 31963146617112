import { SetSubStatusObj } from 'components/ProofingWorkspace/types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import Partition from 'lodash.partition';
import { useRouteMatch } from 'react-router';
import {
  getCorrectSavedSchoolFormat,
  placeSchoolInCorrectPlace,
  getDaysOfMonth,
  getPropertyApiCall,
  massagePropertyTaxesForPdf,
  massageSavedData,
  massageWaterTaxesForPdf,
  selectSchoolDistrictChangeHandler,
  splitTaxingAuthoritiesAndTaxesCollected,
} from './Functions';
import {
  BuildingClassType,
  ContextType,
  Property,
  SplitTaxCollected,
  StateTaxingAuthority,
  WaterTax,
  TaxingAuthorityBackend,
  PropertyTaxVariables,
  RpadProperty,
  IframeOpenType,
  SplitTaxAuthority,
  InstallmentDate,
  Exemption,
} from './types';
import {
  arrearsOptions,
  createWaterTaxItem,
  defaultPriorItem,
  defaultProperty,
  defaultPropertyTaxVariables,
  defaultSplitTaxCollected,
  defaultWaterTax,
  dollarFormatter,
  months,
  washingtonVilleWater,
  waterSewerTaxTypes,
} from './TaxObjectsAndStyles';
import axios from 'axios';
import produce from 'immer';
import qs from 'qs';
import { uniqBy } from 'lodash';
let dragAndDropCounter = 1;
const searchTypeId = 20;

const NysTaxSearchContext = createContext<ContextType | undefined>(undefined);

export const NysTaxSearchProvider = (props: any) => {
  const [startTime, setStartTime] = useState<Date>(new Date());
  const [rpadLoading, setRpadLoading] = useState<boolean>(true);
  const [condo, setCondo] = useState<string>('');
  const [receiptNumber, setReceiptNumber] = useState<string>('');
  const [itemNumber, setItemNumber] = useState<string>('');
  const [iframeOpen, setIframeOpen] = useState<IframeOpenType>({ overlayOpen: false, modalOpen: false });
  const [loaderStatus, setLoaderStatus] = useState<boolean>(false);
  const [additionalWaterInformation, setAdditionalWaterInformation] = useState<string>('');
  const [arrearsInformation, setArrearsInformation] = useState<string>('');
  const [arrearsOption, setArrearsOption] = useState<string>(arrearsOptions[0]);
  const [downloadForEdit, setDownloadForEdit] = useState<boolean>(false);
  const [editButton, setEditButton] = useState<boolean>(true);
  const [isContin, setIsContin] = useState<boolean>(false);
  const [overrideTaNames, setOverrideTaNames] = useState<any>([]);
  const [parentParcel, setParentParcel] = useState<string>('');
  const [pdfHtml, setPdfHtml] = useState<string>('');
  const [property, setProperty] = useState<Property>(defaultProperty);
  const [propertyAccountNumber, setPropertyAccountNumber] = useState<string>('');
  const [propertyTaxes, setPropertyTaxes] = useState<Array<SplitTaxCollected>>([]);
  const [removedTaxingAuthorities, setRemovedTaxingAuthorities] = useState<Array<number>>([]);
  const [runDate, setRunDate] = useState<Date>(new Date());
  const [schoolTaxes, setSchoolTaxes] = useState<Array<SplitTaxCollected>>([]);
  const [sectionOpen, setSectionOpen] = useState<string | boolean>('Property');
  const [selectedSchoolDistrict, setSelectedSchoolDistrict] = useState<SplitTaxCollected>(null);
  const [taxingAuthorities, setTaxingAuthorities] = useState<Array<StateTaxingAuthority>>([]);
  const [waterTaxes, setWaterTaxes] = useState<Array<WaterTax>>([]);
  const [boundSelectedSchoolDistrict, setBoundSelectedSchoolDistrict] = useState<{
    value: number;
    label: string;
  }>(null);
  const [substatus, setSubstatus] = useState<SetSubStatusObj>(null);
  const [searchId, setSearchId] = useState<number>(0);
  const [fullListOfSchoolAuth, setFullListOfSchoolAuth] = useState<Array<SplitTaxCollected>>([]);
  const [fullListOfTaxingAuth, setFullListOfTaxingAuth] = useState<Array<StateTaxingAuthority>>([]);
  const [priorItems, setPriorItems] = useState<Array<SplitTaxCollected>>([]);
  const [propertyTaxVariables, setPropertyTaxVariables] = useState<PropertyTaxVariables>(
    defaultPropertyTaxVariables
  );
  const [modalOpen, setModalOpen] = useState<IframeOpenType>({ modalOpen: false, overlayOpen: false });
  const [taxLinks, setTaxLinks] = useState([]);
  const [client, setClient] = useState(null);
  const [buildingClassModalOpen, setBuildingClassModalOpen] = useState(false);
  const [buildingClassTypes, setBuildingClassTypes] = useState<BuildingClassType[]>([]);
  const [selectedProperty, setSelectedProperty] = useState({ value: null, label: '' });
  const [documentKey, setDocumentKey] = useState(0);
  const [dimensions, setDimensions] = useState('');
  const [priorItemsCollapseAll, setPriorItemsCollapseAll] = useState(false);
  const [searchStatus, setSearchStatus] = useState('');
  const params: any = useRouteMatch();

  useEffect(() => {
    runOnLoadFunction();
    dragAndDropCounter++;
  }, [selectedProperty]);

  const runOnLoadFunction = async () => {
    let {
      savedData,
      county,
      rpadProperty,
      propertyFromApi,
      buildingClassTypes,
      continResult,
      taxAuthFromServer,
      clientName,
      titleNumber,
      status,
    } = await runApiCallsOnLoad();
    if (savedData) {
      return handleSavedData(
        taxAuthFromServer,
        savedData,
        propertyFromApi,
        continResult,
        buildingClassTypes,
        clientName,
        titleNumber,
        status
      );
    }
    handleNoSavedData(
      rpadProperty,
      buildingClassTypes,
      taxAuthFromServer,
      county,
      continResult,
      propertyFromApi,
      clientName,
      titleNumber
    );
  };
  const handleSavedData = async (
    taxAuthFromServer: TaxingAuthorityBackend[],
    savedData: any,
    property: Property,
    continResult: { isContin: boolean },
    buildingClassTypes: BuildingClassType[],
    clientName: string,
    titleNumber: string,
    status: string
  ) => {
    const reworkedSavedData: any = massageSavedData(savedData, dragAndDropCounter, clientName);

    let {
      taxingAuthorities: reworkedTaxingAuthorites,
      taxesCollected,
    } = splitTaxingAuthoritiesAndTaxesCollected(taxAuthFromServer);
    const dividedTaxes = Partition(taxesCollected, i =>
      waterSewerTaxTypes.includes(i.taxDescriptionType.type)
    );

    let { fullListOfSchoolTaxAuths, boundObj, filteredTaxAuths, schoolTaxesToSave } = handleSavedDataSchool(
      taxAuthFromServer,
      dividedTaxes,
      reworkedSavedData
    );
    if (filteredTaxAuths.length > 0) {
      const hasSchoolAuth = filteredTaxAuths.some(ta =>
        ta.taxesCollected?.some(tc => tc.taxDescriptionType.type.toLowerCase().includes('school'))
      );
      if (
        !hasSchoolAuth &&
        savedData.isComingFromAutomation &&
        property?.county?.toLowerCase() === 'nassau'
      ) {
        reworkedTaxingAuthorites = taxAuthFromServer;
      } else {
        reworkedTaxingAuthorites = reworkedSavedData.taxingAuthorities;
      }
    }
    reworkedSavedData.overrideTaNames = formatOverrideNames(reworkedSavedData, filteredTaxAuths);
    const propertyTaxesToSave = formatPropertyTaxes(taxesCollected, reworkedSavedData, schoolTaxesToSave);
    const propTaxVars = await getPropertyVariables(buildingClassTypes, reworkedSavedData);
    const taxingListFull: StateTaxingAuthority[] = taxAuthFromServer.map(ta => {
      const isWaterTaxingAuthority =
        ta.taxesCollected?.find(t => t?.taxDescriptionType?.containsWater) !== undefined;
      return { ...ta, isWaterTaxingAuthority };
    });
    if (!continResult?.isContin && status === 'Completed' && savedData.runDate) {
      setRunDate(new Date(savedData.runDate));
    }
    if (savedData.isComingFromAutomation) {
      boundObj = null;
      if (fullListOfSchoolTaxAuths?.length > 1) {
        reworkedTaxingAuthorites = taxAuthFromServer;
        schoolTaxesToSave = [];
      }
    }
    let waterCollectedTaxes = [];
    let waterInformation = null;
    if (savedData.isComingFromAutomation) {
      const taxes = splitTaxingAuthoritiesAndTaxesCollected(taxAuthFromServer);
      if (property.municipality === 'Fallsburg' && property.subMunicipality === null) {
        waterInformation =
          '\nNote: As of January 1st, 2022 the Town of Fallsburg Water Department requires a $25 check and a long response time in order to provide updated water account information. We can no longer provide updated water information on our Contins. Please order final readings directly with the Water Department prior to closing.';
      }
      if (property.municipality === 'Wallkill' && property.subMunicipality === null) {
        waterInformation =
          '\nNote: The Town of Wallkill Water Department requires a FOIL Request and a long response time in order to provide water/sewer account information. Please order final readings directly with the Water Department prior to closing (845) 342-1668.';
      }
      if (property.municipality === 'Liberty' && property.subMunicipality === 'Liberty') {
        waterInformation =
          '\nNote: As of June 7th, 2024, the Village of Liberty Water Department requires a $25 check and a long response time in order to provide updated water account information. We can no longer provide updated water information on our Contins. Please order final readings directly with the Water Department prior to closing.';
      }
      reworkedSavedData.additionalWaterInformation = waterInformation || '';
      const fullListOfTaxAuth = taxes.taxingAuthorities.map(ta => {
        const isWaterTaxingAuthority =
          ((taxes.taxesCollected || []).filter(tc => tc?.taxAuthorityName === ta.name) || []).filter(
            tc => tc?.taxDescriptionType?.containsWater
          ).length > 0;
        return { ...ta, isWaterTaxingAuthority };
      });
      var index = 1;
      waterCollectedTaxes =
        property.subMunicipality === 'Washingtonville'
          ? [washingtonVilleWater]
          : ['Nassau', 'Suffolk'].includes(savedData.propertyDetails.county)
          ? []
          : uniqBy(
              taxes.taxingAuthorities
                .flatMap(ta => {
                  return ta.taxesCollected?.map((tc: any) => {
                    if (tc.taxDescriptionType.containsWater) {
                      return createWaterTaxItem(tc, index++, savedData.propertyDetails.county, clientName);
                    }
                  });
                })
                .filter(t => t),
              'taxDescriptionType.label'
            );
    }

    saveInfoToState(
      true,
      clientName,
      titleNumber,
      {
        ...reworkedSavedData.propertyDetails,
        ...property,
        assessTo: reworkedSavedData.isComingFromAutomation
          ? property.assessTo
          : reworkedSavedData.propertyDetails.assessTo,
      },
      schoolTaxesToSave,
      reworkedTaxingAuthorites || taxAuthFromServer,
      propertyTaxesToSave,
      waterCollectedTaxes.length > 0 ? waterCollectedTaxes : reworkedSavedData.waterTaxes,
      fullListOfSchoolTaxAuths,
      continResult.isContin,
      propTaxVars,
      waterInformation || additionalWaterInformation,
      taxingListFull,
      reworkedSavedData.arrearsInformation,
      reworkedSavedData.arrearsOption,
      boundObj,
      reworkedSavedData.additionalWaterInformation,
      reworkedSavedData.itemNumber,
      reworkedSavedData.propertyAccountNumber,
      reworkedSavedData.receiptNumber,
      reworkedSavedData.parentParcel,
      reworkedSavedData.overrideTaNames,
      reworkedSavedData.condo,
      reworkedSavedData.priorItems || [],
      reworkedSavedData.isComingFromAutomation
    );
  };

  const formatOverrideNames = (reworkedSavedData: any, filteredTaxAuths: SplitTaxAuthority[]) => {
    let override = reworkedSavedData.overrideTaNames;
    if (override) {
      const objKeys = Object.keys(override);
      for (let key of objKeys) {
        const taxAuth = filteredTaxAuths.find((ta: any) => ta.id === +key);
        if (taxAuth) {
          taxAuth.name = override[key];
        }
      }
    } else {
      override = [];
    }
    return override;
  };

  const handleSelectSchool = (school: string) => {
    if (!fullListOfSchoolAuth || fullListOfSchoolAuth.length < 2) {
      return;
    }
    const foundSchool = fullListOfSchoolAuth?.find(t =>
      t.taxAuthorityName?.toLowerCase().includes(school?.toLowerCase().trim())
    );
    if (foundSchool) {
      var boundSchool = {
        value: foundSchool.taxAuthorityId,
        label: foundSchool.taxAuthorityName,
      };
      setBoundSelectedSchoolDistrict(boundSchool);
      handleSelectSchoolDistrictChange(boundSchool);
    }
  };

  const getPropertyVariables = async (buildingClassTypes: BuildingClassType[], reworkedSavedData: any) => {
    const rpadProperty = await getRpadProperty(buildingClassTypes);
    const propTaxVars = {
      landAssessment:
        reworkedSavedData.landAssessment === null || reworkedSavedData.landAssessment === undefined
          ? rpadProperty.landAssessment
          : reworkedSavedData.landAssessment,
      totalAssessment:
        reworkedSavedData.totalAssessment === null || reworkedSavedData.totalAssessment === undefined
          ? rpadProperty.totalAssessment
          : reworkedSavedData.totalAssessment,
      buildingClass: reworkedSavedData.buildingClass || rpadProperty.buildingClass,
      propertySize: reworkedSavedData.propertySize || rpadProperty.propertySize,
      buildingClassTypes,
    };
    return propTaxVars;
  };

  const formatPropertyTaxes = (
    taxesCollected: SplitTaxCollected[],
    reworkedSavedData: any,
    schoolTaxesToSave: SplitTaxCollected[]
  ) => {
    const spreadPropertyTaxes = [
      ...taxesCollected.filter(
        t =>
          !reworkedSavedData.propertyTaxes.some(
            (p: SplitTaxCollected) => p.taxDescriptionType.id === t.taxDescriptionType.id
          )
      ),
    ];
    let propertyTaxes = [...spreadPropertyTaxes, ...reworkedSavedData.propertyTaxes];
    propertyTaxes = propertyTaxes.filter(i => !waterSewerTaxTypes.includes(i.taxDescriptionType.type));
    const propertyTaxSchool = propertyTaxes.filter(pt => pt.taxDescriptionType.type.includes('School'));
    if (propertyTaxSchool.length > 1) {
      propertyTaxes = propertyTaxes.filter(pt => !pt.taxDescriptionType.type.includes('School'));
      propertyTaxes.push(schoolTaxesToSave[0]);
    }
    propertyTaxes.sort((a, b) => a.taxDescriptionType.listOrder - b.taxDescriptionType.listOrder);
    return propertyTaxes;
  };

  const createLdNote = (pt: SplitTaxCollected, isErie: boolean, isDuchess: boolean) => {
    if (pt.additionalInformation?.toLowerCase().includes('taxes will be available')) {
      return;
    }
    const spansMultipleYears = pt.taxPeriodStartingMonth > 0;
    const month =
      isErie && pt.taxDescriptionType.type.toLowerCase().includes('town')
        ? { month: 0, day: 15 }
        : isErie && pt.taxDescriptionType.type.toLowerCase() === 'school'
        ? { month: 8, day: 15 }
        : isDuchess && pt.taxDescriptionType.type.toLowerCase().includes('town')
        ? { month: 0, day: 1 }
        : pt.installmentMonths[0];
    const yearOfYearApplicable = new Date(`1/1/${pt.yearApplicable}`).getFullYear();
    pt.additionalInformation = `Note: The ${yearOfYearApplicable + 1}${
      spansMultipleYears ? `/${yearOfYearApplicable + 2}` : ''
    } Taxes will be available ${month.month + 1}/${month.day}/${(yearOfYearApplicable + 1)
      .toString()
      .substring(yearOfYearApplicable.toString().length - 2)}`;
  };

  const overrideTaxAuthsNamesForLd = (taxingAuthoritesToSave: SplitTaxAuthority[]) => {
    let result: any[] = [];
    taxingAuthoritesToSave.map(ta => {
      if (!ta.name.toLowerCase().includes('payee')) {
        if (ta.taxesCollected === null || ta.taxesCollected?.length === 0) {
          result = { ...result, [ta.id]: `Delinquent Tax Payee:\n${ta.name}` };
        } else {
          result = { ...result, [ta.id]: `${ta.name} Payee:` };
        }
      }
    });
    return result;
  };

  const saveInfoToState = (
    isComingFromSavedData: boolean,
    clientName: string,
    titleNumber: string = '',
    propertyToSave: Property,
    schoolTaxesToSave: SplitTaxCollected[],
    taxingAuthsToSave: SplitTaxAuthority[],
    propertyTaxesToSave: SplitTaxCollected[],
    waterTaxesToSave: WaterTax[],
    fullSchoolAuthToSave: SplitTaxCollected[],
    isContinToSave: boolean,
    propertyTaxVarsToSave: PropertyTaxVariables,
    additionalWaterInformationToSave: string,
    fullListTaxAuthToSave: StateTaxingAuthority[],
    arrearsInformation?: string,
    arrearsOptionToSave?: string,
    boundObj?: any,
    additionalWaterInfo?: string,
    itemNumberToSave?: string,
    propertyAccountNumberToSave?: any,
    receiptNumberToSave?: any,
    parentParcelToSave?: string,
    overrideTaNamesToSave?: any,
    condoToSave?: string,
    priorItemsToSave?: SplitTaxCollected[],
    isComingFromAutomation?: boolean
  ) => {
    const mappedTaxAuths = taxingAuthsToSave.map((ta: TaxingAuthorityBackend) => {
      const isWaterTaxingAuthority =
        (ta.taxesCollected || []).filter(tc => tc?.taxDescriptionType?.containsWater).length > 0;
      return { ...ta, isWaterTaxingAuthority };
    });
    if (!isComingFromSavedData || isComingFromAutomation) {
      const clientIsLd =
        clientName.toLowerCase() === 'closing usa, llc - ld' ||
        clientName.includes('Westcor Land Title') ||
        (clientName.toUpperCase() === 'CLOSING USA, LLC' &&
          (titleNumber.toString().includes('-') || titleNumber.toString().includes('/'))) ||
        titleNumber.toString().toLowerCase().includes('ld');
      if (clientIsLd) {
        propertyTaxesToSave.map(pt =>
          createLdNote(pt, propertyToSave.county === 'Erie', propertyToSave.county === 'Dutchess')
        );
        fullSchoolAuthToSave.map(pt =>
          createLdNote(pt, propertyToSave.county === 'Erie', propertyToSave.county === 'Dutchess')
        );
        overrideTaNamesToSave = overrideTaxAuthsNamesForLd(taxingAuthsToSave);
      }
      if (propertyToSave.county.toLowerCase() === 'nassau' && clientIsLd && !isComingFromAutomation) {
        propertyTaxesToSave.map(pt => {
          if (pt.taxDescriptionType.id !== 1) {
            for (let i = 0; i < pt.installmentMonths.length; i++) {
              const month = pt.installmentMonths[i];
              const spansMultipleYears = pt.taxPeriodStartingMonth > 0;
              const thisYear = new Date(`1/1/${pt.yearApplicable}`).getFullYear();
              const yearOfYearApplicable = spansMultipleYears && i > 0 ? thisYear + 1 : thisYear;
              const date = new Date(`${month.month + 2}/10/${yearOfYearApplicable}`);
              pt.installmentMonths[i] = { month: date.getMonth() + 1, day: 10 };
              if (pt.installments[i]) {
                pt.installments[i].date = {
                  day: { value: date.getDate(), label: `${date.getDate()}` },
                  month: months.find(m => m.value === date.getMonth()),
                  year: { value: yearOfYearApplicable, label: `${yearOfYearApplicable}` },
                };
              }
            }
          }
        });
      }
    }

    setRpadLoading(false);
    setProperty(propertyToSave);
    setSchoolTaxes(schoolTaxesToSave);
    setTaxingAuthorities(mappedTaxAuths);
    setPropertyTaxes(propertyTaxesToSave);
    setWaterTaxes(waterTaxesToSave);
    setFullListOfSchoolAuth(fullSchoolAuthToSave);
    setIsContin(isContinToSave);
    setPropertyTaxVariables(propertyTaxVarsToSave);
    setAdditionalWaterInformation(additionalWaterInformationToSave);
    setFullListOfTaxingAuth(fullListTaxAuthToSave);
    setOverrideTaNames(overrideTaNamesToSave ?? []);
    if (isComingFromSavedData) {
      const school = propertyTaxesToSave.find(
        pt => pt.taxDescriptionType.type === 'School' && pt.hasRelevies
      );
      if (school) {
        school.hasChargeBacks = true;
        school.chargeBacks = school.relevies;
        school.hasRelevies = false;
        school.relevies = [];
      }
      if (arrearsInformation) {
        setArrearsInformation(arrearsInformation);
      }
      setArrearsOption(arrearsOptionToSave === '' ? arrearsOptions[0] : arrearsOptionToSave);
      setBoundSelectedSchoolDistrict(boundObj);
      setAdditionalWaterInformation(additionalWaterInfo);
      setSelectedSchoolDistrict(schoolTaxesToSave[0]);
      setItemNumber(itemNumberToSave);
      setPropertyAccountNumber(propertyAccountNumberToSave);
      setReceiptNumber(receiptNumberToSave);
      setParentParcel(parentParcelToSave);
      setOverrideTaNames(overrideTaNamesToSave);
      setCondo(condoToSave);
      setPriorItems(priorItemsToSave);
    }
  };

  const handleSavedDataSchool = (
    taxAuthFromServer: TaxingAuthorityBackend[],
    dividedTaxes: [SplitTaxCollected[], SplitTaxCollected[]],
    reworkedSavedData: any
  ) => {
    const allSchoolTaxAuthorities = dividedTaxes[1].filter(t => t.taxDescriptionType.type === 'School');
    dividedTaxes = placeSchoolInCorrectPlace(dividedTaxes, reworkedSavedData);
    const usedSchoolTaxAuthorities = dividedTaxes[1].filter(t => t.taxDescriptionType.type === 'School');
    const schoolTaxDropdownObj = {
      value: usedSchoolTaxAuthorities[0]?.taxAuthorityId,
      label: usedSchoolTaxAuthorities[0]?.taxAuthorityName,
    };

    let reworkedTaxAuths: TaxingAuthorityBackend[] = [];

    if (allSchoolTaxAuthorities.length) {
      reworkedTaxAuths = taxAuthFromServer.filter((ta: TaxingAuthorityBackend) => {
        if (!ta.taxesCollected || ta.taxesCollected.length > 1) {
          return true;
        }
        if (ta.taxesCollected[0].taxDescriptionType.type !== 'School') {
          return true;
        }
        return ta.name === usedSchoolTaxAuthorities[0]?.taxAuthorityName;
      });
    }
    const schoolTaxesToSave =
      reworkedSavedData.propertyTaxes.filter((t: SplitTaxCollected) =>
        t.taxDescriptionType.type.includes('School')
      ) || [];

    if (schoolTaxesToSave.length === 0) {
      schoolTaxesToSave.push(allSchoolTaxAuthorities[0]);
    }
    return {
      fullListOfSchoolTaxAuths: allSchoolTaxAuthorities,
      boundObj: schoolTaxDropdownObj,
      filteredTaxAuths: reworkedTaxAuths,
      schoolTaxesToSave,
    };
  };

  const handleNoSavedData = async (
    rpadProperty: RpadProperty,
    buildingClassTypes: BuildingClassType[],
    taxAuthFromServer: TaxingAuthorityBackend[],
    county: string,
    continResult: { isContin: boolean },
    propertyFromApi: any,
    clientName: string,
    titleNumber: string
  ) => {
    rpadProperty = await getRpadProperty(buildingClassTypes);

    const taxes = splitTaxingAuthoritiesAndTaxesCollected(taxAuthFromServer);
    const dividedTaxes = Partition(taxes.taxesCollected, i =>
      waterSewerTaxTypes.includes(i.taxDescriptionType.type)
    );
    const allSchoolTaxAuthorities = dividedTaxes[1].filter(t => t.taxDescriptionType.type === 'School');
    placeSchoolInCorrectPlace(dividedTaxes);
    const schoolTaxesToSave = dividedTaxes[1].filter(t => t.taxDescriptionType.type === 'School');
    let additionalWaterInformationText = '';
    if (propertyFromApi.municipality === 'Fallsburg' && propertyFromApi.subMunicipality === null) {
      additionalWaterInformationText =
        '\nNote: As of January 1st, 2022 the Town of Fallsburg Water Department requires a $25 check and a long response time in order to provide updated water account information. We can no longer provide updated water information on our Contins. Please order final readings directly with the Water Department prior to closing.';
    }
    if (propertyFromApi.municipality === 'Wallkill' && propertyFromApi.subMunicipality === null) {
      additionalWaterInformationText =
        '\nNote: The Town of Wallkill Water Department requires a FOIL Request and a long response time in order to provide water/sewer account information. Please order final readings directly with the Water Department prior to closing (845) 342-1668.';
    }
    if (propertyFromApi.municipality === 'Liberty' && propertyFromApi.subMunicipality === 'Liberty') {
      additionalWaterInformationText =
        '\nNote: As of June 7th, 2024, the Village of Liberty Water Department requires a $25 check and a long response time in order to provide updated water account information. We can no longer provide updated water information on our Contins. Please order final readings directly with the Water Department prior to closing.';
    }
    const propTaxVars = {
      landAssessment: rpadProperty?.landAssessment || propertyTaxVariables.landAssessment,
      totalAssessment: rpadProperty?.totalAssessment || propertyTaxVariables.totalAssessment,
      buildingClass: rpadProperty?.buildingClass || propertyTaxVariables.buildingClass,
      propertySize: rpadProperty?.propertySize || propertyTaxVariables.propertySize,
      buildingClassTypes,
    };

    const fullListOfTaxAuth = taxes.taxingAuthorities.map(ta => {
      const isWaterTaxingAuthority =
        ((taxes.taxesCollected || []).filter(tc => tc?.taxAuthorityName === ta.name) || []).filter(
          tc => tc?.taxDescriptionType?.containsWater
        ).length > 0;
      return { ...ta, isWaterTaxingAuthority };
    });
    var index = 1;
    const waterCollectedTaxes = ['Nassau', 'Suffolk'].includes(county)
      ? []
      : uniqBy(
          taxes.taxingAuthorities
            .flatMap(ta => {
              return ta.taxesCollected?.map((tc: any) => {
                if (tc.taxDescriptionType.containsWater) {
                  return createWaterTaxItem(tc, index++, county, clientName);
                }
              });
            })
            .filter(t => t),
          'taxDescriptionType.label'
        );

    saveInfoToState(
      false,
      clientName,
      titleNumber,
      propertyFromApi,
      schoolTaxesToSave,
      taxes.taxingAuthorities,
      dividedTaxes[1],
      waterCollectedTaxes.length > 0
        ? waterCollectedTaxes
        : [{ ...defaultWaterTax(county, dragAndDropCounter, clientName) }],
      allSchoolTaxAuthorities,
      continResult.isContin,
      propTaxVars,
      additionalWaterInformationText,
      fullListOfTaxAuth
    );

    dragAndDropCounter++;
  };

  const getRpadProperty = async (buildingClassTypes: BuildingClassType[]) => {
    const res = await axios.get(`/api/property/getNysAssessment?propertyId=${+params.params.propertyid}`);
    if (res.data) {
      const rpadProp = {
        propertySize: res.data.acres,
        landAssessment: res.data.landValue,
        totalAssessment: res.data.totalPropertyValue,
        buildingClassTypes,
        buildingClass: buildingClassTypes.find((bc: any) => bc.label.includes(res.data.propertyClass)) || {
          value: '',
          label: '',
          isVacantLand: false,
        },
        lotDepth: res.data.lotDepth,
        lotWidth: res.data.lotWidth,
      };
      setPropertyTaxVariables(rpadProp);
      setDimensions(`${res.data.lotWidth} X ${res.data.lotDepth}`);
      return rpadProp;
    }
    return {
      propertySize: '',
      landAssessment: '',
      totalAssessment: '',
      buildingClassTypes: [],
      buildingClass: { isVacantLand: false, label: '', value: '' },
      lotDepth: '',
      lotWidth: '',
    } as RpadProperty;
  };

  const getBuildingTypes = async () => {
    const { data } = await axios.get(`/api/property/GetBuildingTypes/`);
    const buildingClassTypes = data
      .filter((bc: BuildingClassType) => bc.stateType !== 'NJ')
      .map((b: BuildingClassType) => ({
        value: b.building,
        label: b.building,
        ...b,
      }));
    setBuildingClassTypes(buildingClassTypes);
    return buildingClassTypes;
  };

  const runApiCallsOnLoad = async () => {
    const { data: search } = await axios.get(
      `/api/searches/GetSearchByType/${searchTypeId}/${+params.params.propertyid}`
    );
    setSearchId(search.id);
    setSearchStatus(search.currentStatus);

    setRpadLoading(true);
    const { data: savedData } = await axios.get(
      `/api/searches/getSaved?propertyId=${
        selectedProperty.value || +params.params.propertyid
      }&searchTypeId=20`
    );
    const {
      data: { county },
    } = await axios.get(`/api/property/GetProperty/${+params.params.propertyid}`);
    const { data: clientName } = await axios.get(`/api/clients/GetClientName/${search.id}`);
    let rpadProperty = {} as RpadProperty;

    let { taxingAuthorities: taxAuthFromServer, ...propertyFromApi } = await getPropertyApiCall(
      +params.params.propertyid
    );
    const buildingClassTypes = await getBuildingTypes();

    const { data: continResult } = await axios.get(`/api/searches/istaxcontin/${+params.params.propertyid}`);

    const { data: titleNumber } = await axios.get(`/api/orders/gettitlenumber/${search.id}`);

    return {
      savedData,
      county,
      rpadProperty,
      propertyFromApi,
      continResult,
      taxAuthFromServer,
      clientName,
      titleNumber,
      buildingClassTypes,
      status: search.currentStatus,
    };
  };

  const handleSelectSchoolDistrictChange = (selection: any) => {
    const handlerResponseObj = selectSchoolDistrictChangeHandler(
      selection,
      fullListOfSchoolAuth,
      propertyTaxes,
      taxingAuthorities,
      fullListOfTaxingAuth
    );
    setBoundSelectedSchoolDistrict(handlerResponseObj.boundSelectedSchoolDistrict);
    setPropertyTaxes(handlerResponseObj.propertyTaxes);
    setSelectedSchoolDistrict(handlerResponseObj.selectedSchoolDistrict);
    setTaxingAuthorities(handlerResponseObj.taxingAuthorities);
  };
  const handlePropertyChange = (name: string, value: any) => {
    const newProperty = produce(property, (draft: any) => {
      draft[name] = value;
    });
    setProperty(newProperty);
  };

  const handleRemoveItemFromPropertyTaxesDetails = (
    taxIndex: number,
    propertyTaxName?: keyof SplitTaxCollected,
    itemIndex?: number
  ) => {
    const newState = produce(propertyTaxes, (draft: any) => {
      draft[taxIndex][propertyTaxName] = draft[taxIndex][propertyTaxName].filter(
        (i: any, index: number) => index !== itemIndex
      );
    });
    setPropertyTaxes(newState);
  };
  const handleRemoveItemFromPriorItems = (
    taxIndex: number,
    propertyTaxName?: keyof SplitTaxCollected,
    itemIndex?: number
  ) => {
    const newState = produce(priorItems, (draft: any) => {
      draft[taxIndex][propertyTaxName] = draft[taxIndex][propertyTaxName].filter(
        (i: any, index: number) => index !== itemIndex
      );
    });
    setPriorItems(newState);
  };

  const handlePropertyTaxesChanges = (
    propertyTaxName: keyof SplitTaxCollected,
    value: any,
    taxIndex: number,
    name?: string,
    nameIndex?: number,
    dateName?: string
  ) => {
    const newState = produce(propertyTaxes, (draft: any) => {
      if (!name) {
        draft[taxIndex][propertyTaxName] = value; //ex: propertyTaxes[0].fullTaxAmount = value
      } else if (!dateName) {
        draft[taxIndex][propertyTaxName][nameIndex][name] = value; //ex: propertyTaxes[0].installments[0].amount = value
      } else if (nameIndex === undefined || nameIndex == null) {
        draft[taxIndex][propertyTaxName][name][dateName] = value; //ex: propertyTaxes[0].singleInstallment.date.month = value
      } else {
        draft[taxIndex][propertyTaxName][nameIndex][name][dateName] = value; //ex: propertyTaxes[0].installments[0].date.month = value
      }
      if (
        propertyTaxName === 'exemptions' &&
        name === 'type' &&
        value.label?.toLowerCase().includes('star') &&
        draft[taxIndex][propertyTaxName][nameIndex]['vsText'].includes('School')
      ) {
        draft[taxIndex][propertyTaxName][nameIndex]['vsText'] = 'School';
      }
      if (
        propertyTaxName === 'exemptions' &&
        name === 'type' &&
        value.label?.toLowerCase().includes('condo') &&
        !draft[taxIndex][propertyTaxName][nameIndex]['vsText'].includes('School')
      ) {
        draft[taxIndex][propertyTaxName][nameIndex]['vsText'] = 'County';
      }
      if (name === 'status' && value?.specialStatus) {
        draft[taxIndex][propertyTaxName][nameIndex]['status'] = { value: 'Other', label: 'Other' };
        draft[taxIndex][propertyTaxName][nameIndex]['statusNote'] = value.label;
      }
      if (propertyTaxName === 'fullAmountStatus' && value?.specialStatus) {
        draft[taxIndex][propertyTaxName] = { value: 'Other', label: 'Other' };
        draft[taxIndex]['fullAmountStatusNote'] = value.label;
      }
    });
    setPropertyTaxes(newState);
  };
  const handleBasicStar = (checked: boolean, taxIndex: number) => {
    const vsText = 'School';
    const emptyIndex = propertyTaxes[taxIndex].exemptions.findIndex(
      (exemption: any) => !exemption?.type?.label
    );
    const exemptionToAdd = {
      type: { label: 'Basic Star', value: 1 },
      savings: '',
      vsText: vsText,
      isPriorItem: false,
    };
    const basicStarIndex = propertyTaxes[taxIndex].exemptions.findIndex(
      (exemption: any) => exemption?.type?.label === 'Basic Star'
    );
    const newState = produce(propertyTaxes, (draft: any) => {
      if (checked) {
        draft[taxIndex].additionalInformation = `${draft[taxIndex].additionalInformation ?? ''}${
          draft[taxIndex].additionalInformation !== '' ? '\n' : ''
        }Note: Basic Star rebate will be sent to owner.`;
        if (emptyIndex === -1) {
          draft[taxIndex].exemptions.push(exemptionToAdd);
        } else {
          draft[taxIndex].exemptions[emptyIndex] = exemptionToAdd;
        }
        draft[taxIndex].hasExemptions = true;
      } else {
        draft[taxIndex].additionalInformation = (draft[taxIndex].additionalInformation ?? '').replace(
          `${
            draft[taxIndex].additionalInformation !== '' ? '\n' : ''
          }Note: Basic Star rebate will be sent to owner.`,
          ''
        );
        draft[taxIndex].exemptions.splice(basicStarIndex, 1);
        draft[taxIndex].hasExemptions = draft[taxIndex].exemptions.length > 0;
      }
    });
    setPropertyTaxes(newState);
  };

  const applyAmountToAll = (name: string, value: any, otherValue: any, taxIndex: number) => {
    const newInstallments = produce(propertyTaxes, (draft: Array<SplitTaxCollected>) => {
      const obj = draft[taxIndex];
      if (obj.installments) {
        for (const installment of obj.installments) {
          (installment as any)[name] = value;
          if (name === 'status') {
            (installment as any).statusNote = otherValue;
          }
        }
      }
    });
    setPropertyTaxes(newInstallments);
  };
  const applyAmountToAllPi = (name: string, value: any, otherValue: any, taxIndex: number) => {
    const newInstallments = produce(priorItems, (draft: Array<SplitTaxCollected>) => {
      const obj = draft[taxIndex];
      if (obj.installments) {
        for (const installment of obj.installments) {
          (installment as any)[name] = value;
          if (name === 'status') {
            (installment as any).statusNote = otherValue;
          }
        }
      }
    });
    setPriorItems(newInstallments);
  };
  const handleWaterChange = (
    name: keyof WaterTax,
    value: any,
    waterIndex: number,
    installmentName?: string,
    installmentIndex?: number
  ) => {
    const newState = produce(waterTaxes, (draft: any) => {
      if (!installmentName) {
        draft[waterIndex][name] = value;
      } else {
        draft[waterIndex][name][installmentIndex][installmentName] = value;
      }
      if (installmentName === 'status' && value?.specialStatus) {
        draft[waterIndex][name][installmentIndex]['status'] = { value: 'Other', label: 'Other' };
        draft[waterIndex][name][installmentIndex]['statusNote'] = value.label;
      }
      if (name === 'type' && value?.specialStatus) {
        draft[waterIndex]['type'] = { value: 'Other', label: 'Other' };
        draft[waterIndex]['typeOther'] = value.label;
      }
      if (name === 'fullAmountStatus' && value?.specialStatus) {
        draft[waterIndex][name] = { value: 'Other', label: 'Other' };
        draft[waterIndex]['fullAmountStatusNote'] = value.label;
      }
    });
    setWaterTaxes(newState);
  };
  const handleRemoveWater = (waterIndex: number, installmentId?: number) => {
    const newState = produce(waterTaxes, (draft: any) => {
      if (!installmentId) {
        draft.splice(waterIndex, 1);
      } else {
        draft[waterIndex].installments = draft[waterIndex].installments.filter(
          (i: any) => i.id !== installmentId
        );
      }
    });
    setWaterTaxes(newState);
  };
  const handleRemoveWaterInstallment = (indexToRemove: number, taxIndex: number) => {
    const newInstallments = produce(waterTaxes, draft => {
      draft[taxIndex].installments.splice(indexToRemove, 1);
    });
    setWaterTaxes(newInstallments);
  };
  const handlePriorItemsChange = (
    name: keyof SplitTaxCollected,
    value: any,
    priorItemIndex: number,
    propertyName?: any,
    propertyIndex?: number,
    dateName?: keyof InstallmentDate
  ) => {
    if (name === 'taxDescriptionType') {
      handleSelectTaxType(priorItemIndex, value);
    } else {
      const newState = produce(priorItems, (draft: any) => {
        if (!propertyName) {
          draft[priorItemIndex][name] = value; //ex: propertyTaxes[0].fullTaxAmount = value
        } else if (!dateName) {
          draft[priorItemIndex][name][propertyIndex][propertyName] = value; //ex: propertyTaxes[0].installments[0].amount = value
        } else if (propertyIndex === undefined) {
          if (dateName === 'year') {
            draft[priorItemIndex].yearApplicable = value;
          } else if (dateName === 'month') {
            draft[priorItemIndex].taxPeriodStartingMonth = value.value;
          }
          draft[priorItemIndex][name][propertyName][dateName] = value; //ex: propertyTaxes[0].singleInstallment.date.month = value
        } else {
          draft[priorItemIndex][name][propertyIndex][propertyName][dateName] = value; //ex: propertyTaxes[0].installments[0].date.month = value
        }
      });
      setPriorItems(newState);
    }
  };

  const handleRemovePriorItem = (
    taxIndex: number,
    propertyTaxName?: keyof SplitTaxCollected,
    itemIndex?: number
  ) => {
    const newState = produce(priorItems, (draft: any) => {
      if (!itemIndex) {
        draft.splice(taxIndex, 1);
      } else {
        draft[taxIndex][propertyTaxName] = draft[taxIndex][propertyTaxName].filter(
          (i: any, index: number) => index !== itemIndex
        );
      }
    });
    setPriorItems(newState);
  };

  const handleSelectTaxType = (index: number, value: any) => {
    const propertyTaxType = propertyTaxes.find(pt => pt.id === value.value);
    const taxPeriodStartingDate = propertyTaxes.find(pt => pt.id === value.value).installmentMonths[0];
    const fullMonth = months.find(m => m.value === taxPeriodStartingDate.month);
    const fullDay = getDaysOfMonth(fullMonth.value, propertyTaxType.yearApplicable).find(
      (d: any) => d.value === taxPeriodStartingDate.day
    );
    const date = {
      month: fullMonth,
      day: fullDay,
      year: priorItems[index].yearApplicable,
    };
    const newState = produce(priorItems, (draft: any) => {
      draft[index].taxDescriptionType = propertyTaxType.taxDescriptionType;
      draft[index].taxAuthorityId = propertyTaxType.taxAuthorityId;
      draft[index].taxAuthorityName = propertyTaxType.taxAuthorityName;
      draft[index].taxPeriodStartingMonth = propertyTaxType.taxPeriodStartingMonth;
      draft[index].singleInstallment.date = date;
    });
    setPriorItems(newState);
  };
  const handleSelectedProperty = async (e: any) => {
    setSelectedProperty(e);
    setDocumentKey(documentKey + 1);
  };
  useEffect(() => {
    if (property.municipalityId) {
      const municipalLinkQueryString = qs.stringify({
        municipalityId: property.municipalityId,
        subMunicipalityId: property.subMunicipalityId,
      });
      axios.get(`/api/municipalities/GetMunicipalLinks?${municipalLinkQueryString}`).then(({ data }) => {
        setTaxLinks(data);
      });
    }
  }, [JSON.stringify(property)]);
  const getFormContent = async () => {
    let taxingAuthoritiesArray = [...taxingAuthorities];
    if (schoolTaxes && schoolTaxes.length > 1 && selectedSchoolDistrict) {
      //filter unused tax auths so they don't show on the deliverable
      taxingAuthoritiesArray = taxingAuthoritiesArray.filter(
        ta => propertyTaxes.some(s => s.taxAuthorityId === ta.id) || ta.countyId || ta.isWaterTaxingAuthority
      );
    }

    let fullPropertyTaxes: SplitTaxCollected[] = propertyTaxes.concat(priorItems);
    let combinedTaxes = fullPropertyTaxes
      .filter(pt => pt.taxDescriptionType !== null)
      .reduce((acc: any, curr: SplitTaxCollected) => {
        if (acc[curr.taxDescriptionType?.type]) {
          acc[curr.taxDescriptionType.type].push(curr);
        } else {
          acc[curr.taxDescriptionType.type] = [curr];
        }
        return acc;
      }, {});
    Object.values(combinedTaxes).forEach((current: SplitTaxCollected[]) => {
      current.sort((a, b) => b.yearApplicable - a.yearApplicable);
    });
    combinedTaxes = Object.values(combinedTaxes).flat();
    return {
      date: new Date(),
      searchType: 'Tax Search',
      searchTypeId,
      propertyDetails: { ...property },
      propertySize: propertyTaxVariables.propertySize,
      condo,
      buildingClass: propertyTaxVariables.buildingClass,
      landAssessment: propertyTaxVariables.landAssessment,
      totalAssessment: propertyTaxVariables.totalAssessment,
      taxingAuthorities: taxingAuthoritiesArray.filter(ta => !removedTaxingAuthorities.includes(ta.id)),
      propertyTaxes: massagePropertyTaxesForPdf(combinedTaxes),
      waterTaxes: massageWaterTaxesForPdf(waterTaxes),
      additionalWaterInformation,
      propertyAccountNumber,
      receiptNumber,
      itemNumber,
      arrearsInformation,
      arrearsOption,
      runDate: typeof runDate === 'string' ? runDate : runDate?.toLocaleDateString('en-US') || null,
      taxLinks,
      propertyId: +params.params.propertyid,
      isContin,
      overrideTaNames,
      removedTaxingAuthorities,
      parentParcel,
      priorItemsLength: uniqBy(priorItems, pi => pi.yearApplicable).length,
      searchId,
    };
  };

  const getAdditionalArrearNote = (property: any) => {
    const county = property.county;
    const muni = property.municipality;
    const countyNoteArrears = ['Saratoga', 'Sullivan', 'Rensselaer', 'Delaware', 'Schoharie', 'Madison', 'Delaware', 'Essex'].includes(
      county
    );
    const isReverse = ['Saratoga', 'Delaware'].includes(county);
    const isBeaconMuni = ['Beacon', 'Saratoga Springs', 'Troy'].includes(muni);
    const arrearsNote = `${
      (countyNoteArrears && !isBeaconMuni) ? `Note: County of ${county}` : isBeaconMuni ? `City of ${muni}` : ''
    } requires tax payments to be received in reverse chronological order. You must pay the most recent tax due, before they can accept prior taxes open. `;
    if (isBeaconMuni || countyNoteArrears) {
      return arrearsNote;
    }
    return '';
  };

  const getAppendedArrearsNote = (property: any, arrearNote: string) => {
    const additionalArrearsInfo = getAdditionalArrearNote(property);
    if (!additionalArrearsInfo) {
      return arrearNote;
    }
    let note = arrearNote.replace(`\n${additionalArrearsInfo}`, '') || '';

    return `${note}\n${additionalArrearsInfo}`;
  };

  const searchParams = new URLSearchParams(params.path);
  const isEdit = searchParams.get('isEdit');

  return (
    <NysTaxSearchContext.Provider
      value={{
        handleSelectSchoolDistrictChange,
        handlePropertyChange,
        handlePropertyTaxesChanges,
        handleRemoveItemFromPropertyTaxesDetails,
        handleWaterChange,
        handleRemoveWater,
        handleRemoveWaterInstallment,
        setSectionOpen,
        setPdfHtml,
        setDownloadForEdit,
        setEditButton,
        setLoaderStatus,
        setPropertyTaxVariables,
        setCondo,
        setPropertyAccountNumber,
        setItemNumber,
        setReceiptNumber,
        setParentParcel,
        setIframeOpen,
        setArrearsOption,
        setArrearsInformation,
        setSubstatus,
        setRunDate,
        setOverrideTaNames,
        setRemovedTaxingAuthorities,
        setWaterTaxes,
        setAdditionalWaterInformation,
        setPropertyTaxes,
        getFormContent,
        setPriorItems,
        handlePriorItemsChange,
        handleRemovePriorItem,
        setModalOpen,
        handleRemoveItemFromPriorItems,
        startTime,
        substatus,
        property,
        additionalWaterInformation,
        arrearsInformation,
        arrearsOption,
        condo,
        isContin,
        itemNumber,
        overrideTaNames,
        parentParcel,
        propertyAccountNumber,
        propertyTaxVariables,
        propertyTaxes,
        receiptNumber,
        removedTaxingAuthorities,
        runDate,
        schoolTaxes,
        selectedSchoolDistrict,
        waterTaxes,
        taxingAuthorities,
        rpadLoading,
        searchId,
        sectionOpen,
        boundSelectedSchoolDistrict,
        pdfHtml,
        iframeOpen,
        editButton,
        downloadForEdit,
        loaderStatus,
        fullListOfSchoolAuth,
        dragAndDropCounter,
        params,
        isEdit,
        priorItems,
        modalOpen,
        client,
        setClient,
        buildingClassModalOpen,
        setBuildingClassModalOpen,
        buildingClassTypes,
        getBuildingTypes,
        selectedProperty,
        setSelectedProperty,
        handleSelectedProperty,
        documentKey,
        dimensions,
        setDocumentKey,
        handleSelectSchool,
        handleBasicStar,
        priorItemsCollapseAll,
        setPriorItemsCollapseAll,
        applyAmountToAll,
        applyAmountToAllPi,
        searchStatus,
        getAppendedArrearsNote
      }}
    >
      {props.children}
    </NysTaxSearchContext.Provider>
  );
};

export function useNysTaxSearch() {
  const context = useContext(NysTaxSearchContext);
  return context;
}
