/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component } from 'react';
import { css } from 'emotion';
import axios from 'axios';
import Button from '../../../ui/Button';
import BinaryRadioButton from '../../ui/BinaryRadioButton';
import TextField from '../../../ui/TextField';
import ActionButton from '../../../ui/ActionButton';
import colors from '../../../../css/colors';
import { toolTipWrap, toolTip } from '../../SharedFormStyles';
import { showNextButton, getNextLink } from '../../FormStepperFunctions';
import withProperty from '../../WithProperty';
import CurrencyField from '../../../ui/CurrencyField';
import MultiFileUpload from '../../ui/MultiFileUpload';
import getBase64 from '../../../FileUploads';
import SaveAndHoldButton from '../../SaveAndHoldButton';
import SubmitFormButton from '../../SubmitFormButton';
import withUser from '../../../../lib/WithUser';
import AssessToInput from '../../AssessToInput';
import GoToProofingWorkspace from '../../GoToProofingWorkspace';
import WithDocumentStation from '../../ui/DocumentStation/WithDocumentStation';
import AlterationForm from './Components/AlterationForm';
import { Checkbox } from 'semantic-ui-react';
import ReusableTopOfForm from 'components/forms/ReusableTopOfForm';
import AdditionalPermitText from './AdditionalPermitText';
import PermitAutomationModal from './Components/PermitAutomation';

const detailsStyles = {
  marginTop: 32,
};

const searchTypeId = 59;

class MinorPermitAndAlterationSearch extends Component {
  state = {
    hasPermitAndAlterationRecords: true,
    alterationInfo: '',
    county: '',
    districtNumber: '',
    permitNumber: '',
    hookUpDate: '',
    additionalInfo: '',
    loaderStatus: false,
    attachedFiles: [],
    owners: '',
    searchId: 0,
    displayAddedText: false,
    selectedProperty: { value: null, label: '' },
    documentKey: 0,
    automationModalOpen: false,
    electricApplications: false
  };

  componentDidMount = async () => {
    axios
      .get(`/api/searches/GetSearchByType/${searchTypeId}/${+this.props.match.params.propertyid}`)
      .then(({ data }) => {
        this.props.setSearchId(data.id);
        this.setState({ searchId: data.id });
      });
    this.getSavedData(+this.props.match.params.propertyid);
  };
  getSavedData = async propertyId => {
    const { data: savedData } = await axios.get(
      `/api/searches/getSaved?propertyId=${propertyId}&searchTypeId=${searchTypeId}`
    );

    if (savedData) {
      this.setState({ ...savedData, alterationInfo: savedData.alterationInfo || '' });
    } else {
      const { data } = await axios.get(`/api/property/getProperty`, {
        params: { id: +this.props.match.params.propertyid },
      });
      this.setState({
        county: data.county,
      });
    }
  };
  onFilesAdded = files => {
    this.setState({ attachedFiles: [...this.state.attachedFiles, ...files] });
  };

  onFileRemoved = file => {
    this.setState({
      attachedFiles: this.state.attachedFiles.filter(f => f !== file),
    });
  };

  handlePreview = async () => {
    const { data: html } = await axios.post('/api/searches/CreatePDF', {
      template: 'permitAndAlterationSearch',
      data: await this.getFormContent(),
    });
    this.setState({ pdfHtml: html });
  };

  setAlterationInfo = alterationInfo => {
    this.setState({ alterationInfo });
  };

  handleDownload = async () => {
    this.setState({ loaderStatus: true });
    await axios.post('/api/searches/DownloadPDF', {
      template: 'PermitAndAlterationSearch',
      data: await this.getFormContent(),
    });
    const nextLink = getNextLink(
      this.props.property.orderId,
      searchTypeId,
      this.props.match.params.propertyid
    );
    if (nextLink == null) {
      this.props.history.push(`/orders/order-information/${this.props.property.orderId}`);
    } else {
      this.props.history.push(nextLink);
    }
    this.setState({ loaderStatus: false });
  };

  handleSelectedProperty = async e => {
    this.setState({ selectedProperty: e });
    this.getSavedData(e.value);
    this.setState({ documentKey: this.state.documentKey + 1 });
  };
  getFormContent = async () => {
    const { additionalInfo, hasPermitAndAlterationRecords, county, owners, alterationInfo, electricApplications } = this.state;

    const promises = this.state.attachedFiles.map(async f => {
      const base64 = await getBase64(f);
      return { name: f.name, base64String: base64 };
    });
    const files = await Promise.all(promises);
    return {
      date: new Date(),
      searchType: 'Minor Permit And Alteration Search',
      hasPermitAndAlterationRecords,
      alterationInfo,
      county,
      additionalInfo,
      countyName: county,
      additionalInformation: additionalInfo,
      propertyId: +this.props.match.params.propertyid,
      searchTypeId,
      searchDocumentType: 'Municipal Attachment',
      attachments: files,
      owners,
      runDate: this.props.runDate,
      displayAddedText: this.state.displayAddedText,
      electricApplications
    };
  };
  setAdditionalInfoText = text => {
    this.setState({ additionalInfo: text });
  };
  render() {
    const {
      hasPermitAndAlterationRecords,
      additionalInfo,
      pdfHtml,
      loaderStatus,
      owners,
      selectedProperty,
      electricApplications
    } = this.state;
    const showNext = showNextButton(this.props.property.orderId, searchTypeId);
    const params = new URLSearchParams(this.props.location.search);

    const isEdit = params.get('isEdit');

    return (
      <div css={{ display: 'flex', height: '100%' }}>
        <div css={{ width: '50%' }}>
          <div css={{ width: 654 }}>
            <ReusableTopOfForm
              handlePreview={this.handlePreview}
              searchHeader="Minor Permit And Alteration Search"
              propertyId={this.props.match.params.propertyid}
              searchTypeId={searchTypeId}
              property={this.props.property}
              getFormContent={this.getFormContent}
              template="PermitAndAlterationSearch"
              propertyDropdown={true}
              selectedProperty={selectedProperty}
              setSelectedProperty={e => this.handleSelectedProperty(e)}
              setDocumentKey={() => this.setState({ documentKey: this.state.documentKey + 1 })}
              searchId={this.state.searchId}
            />
            <AssessToInput
              value={owners}
              onChange={e => this.setState({ owners: e.target.value })}
              propertyId={+this.props.match.params.propertyid}
            />
            <Button onClick={() => this.setState({ automationModalOpen: true })}>View Automation</Button>
            <PermitAutomationModal
              propertyId={this.props.match.params.propertyid}
              open={this.state.automationModalOpen}
              onClose={() => this.setState({ automationModalOpen: false })}
              setAlterationInfo={this.setAlterationInfo}
              alterationInfo={this.state.alterationInfo}
            />
            <BinaryRadioButton
              headerText="Records Found?"
              trueText="Yes"
              falseText="No"
              checkedState={hasPermitAndAlterationRecords}
              onChange={checked => this.setState({ hasPermitAndAlterationRecords: checked })}
            />
            {hasPermitAndAlterationRecords && (
              <AlterationForm
                alterationInfo={this.state.alterationInfo}
                setAlterationInfo={this.setAlterationInfo}
              />
            )}
            {/* <div style={{ marginTop: 15 }}>
              <MultiFileUpload onFilesAdded={this.onFilesAdded} onFileRemoved={this.onFileRemoved} />
            </div> */}
            <BinaryRadioButton
              headerText="Open Electrical applications found?"
              trueText="Yes"
              falseText="No"
              checkedState={electricApplications}
              onChange={checked => this.setState({ electricApplications: checked, additionalInfo: `Active Electrical Applications attached ${additionalInfo}` })}
            />
            <div css={{ margin: '32px 0' }}>
              <textarea
                css={{
                  backgroundColor: colors.greyOrder,
                  borderRadius: 3,
                  width: 654,
                  height: 192,
                  marginBottom: 24,
                  ':focus': { outline: 0 },
                }}
                value={additionalInfo}
                onChange={evt => this.setState({ additionalInfo: evt.target.value })}
                placeholder="Additional Information"
              />
            </div>
            {this.state.searchId > 0 && (
              <WithDocumentStation searchId={this.state.searchId} key={this.state.documentKey} />
            )}
            <Checkbox
              style={{ marginTop: 16 }}
              checked={this.state.displayAddedText}
              onChange={(e, { checked }) => this.setState({ displayAddedText: checked })}
              label="Display CO text"
            />
            <div
              css={{
                marginTop: 43,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
                <SaveAndHoldButton
                  propertyId={+this.props.match.params.propertyid}
                  searchTypeId={searchTypeId}
                  formData={this.getFormContent}
                  afterSubmitRoute={() =>
                    this.props.history.push(`/orders/order-information/${this.props.property.orderId}`)
                  }
                  userId={this.props.user.id}
                  orderId={this.props.property.orderId}
                  disabled={loaderStatus}
                />
                <div css={{ marginLeft: 8 }}>
                  <SubmitFormButton
                    searchTypeId={searchTypeId}
                    onSubmit={this.handleDownload}
                    reactFormal={false}
                    userId={this.props.user.id}
                    orderId={this.props.property.orderId}
                    loadingStatus={loaderStatus}
                    propertyId={+this.props.match.params.propertyid}
                    buttonText={showNext ? 'Next' : `${isEdit ? 'Save' : 'Submit'}`}
                    getFormContent={this.getFormContent}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          css={{
            width: '100%',
            borderStyle: 'solid',
            borderWidth: 2,
            padding: 16,
            position: 'sticky',
            top: 25,
            marginLeft: 24,
          }}
        >
          <iframe style={{ width: '100%', height: '100%' }} srcDoc={pdfHtml} />
        </div>
      </div>
    );
  }
}
export default withProperty(withUser(MinorPermitAndAlterationSearch), searchTypeId, true);
