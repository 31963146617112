/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component } from 'react';
import axios from 'axios';
import BinaryRadioButton from '../ui/BinaryRadioButton';
import { showNextButton, getNextLink } from '../FormStepperFunctions';
import withProperty from '../WithProperty';
import getBase64 from '../../FileUploads';
import colors from '../../../css/colors';
import CurrencyField from '../../ui/CurrencyField';
import { Checkbox } from 'semantic-ui-react';
import SaveAndHoldButton from '../SaveAndHoldButton';
import SubmitFormButton from '../SubmitFormButton';
import withUser from '../../../lib/WithUser';
import AssessToInput from '../AssessToInput';
import { DatePicker } from 'react-widgets';
import EmailRequestLetterDropdown from '../EmailRequestLetterDropdown';
import WithDocumentStation from '../ui/DocumentStation/WithDocumentStation';
import ConfirmActionModal from '../../ui/ConfirmActionModal';
import produce from 'immer';
import moment from 'moment';
import ReusableTopOfForm from '../ReusableTopOfForm';
import HudproCheckbox from 'components/ui/CheckBox';
import MultiSelectDropdown from 'components/ui/MultiSelectDropdown';

const feeDetailMessage = '\n___ Detailed fees attached';
const searchTypeId = 4;

class EmergencyRepair extends Component {
  state = {
    isERFound: false,
    isERNotFound: true,
    isBasedOnMasterLot: false,
    amountDueForRepairs: '',
    additionalInfo: '',
    condo: false,
    attachedFiles: [],
    loaderStatus: false,
    doNotCheck: false,
    owners: '',
    lastPrintedLienBook: { date: '', id: 0 },
    searchId: 0,
    disableDate: true,
    displayChangeModal: false,
    selectedProperty: { value: null, label: '' },
    documentKey: 0,
    generateFeeDetailMessage: false,
    vacateOrderCheckedOff: false,
    vacateOrder: false,
    vacateCharges: false,
    vacateAdditionalInfo: false,
    feesFound: false,
    vacateOrderFound: false,
    vacateInfo: '',
    hasAttachments: true,
    selectedNotes: [],
    noteOptions: [
      {
        name: 'File xxx Disbursed\n',
        id: 0,
        isChecked: false,
      },
      {
        name: 'Demolition Lien found on property, Charges – if any - to follow\n',
        id: 1,
        isChecked: false,
      },
      {
        name: 'Demolition Lien Amount Due: \n',
        id: 2,
        isChecked: false,
      },
    ],
  };

  componentDidMount = async () => {
    axios
      .get(`/api/searches/GetSearchByType/${searchTypeId}/${+this.props.match.params.propertyid}`)
      .then(({ data }) => {
        this.props.setSearchId(data.id);
        this.setState({ searchId: data.id });
      });

    this.getSavedData(+this.props.match.params.propertyid);
    const { data } = await axios.get('/api/searches/GetLastLienBook');
    const correctFormat = new Date(moment(data.date).format('MM-DD-YYYY'));
    this.setState({ lastPrintedLienBook: { date: correctFormat, id: data.id } });
  };
  getSavedData = async propertyId => {
    const { data: savedData } = await axios.get(
      `/api/searches/getSaved?propertyId=${propertyId}&searchTypeId=${searchTypeId}`
    );

    if (savedData) {
      this.setState({ ...savedData });
    }
  };
  onFilesAdded = files => {
    this.setState({ attachedFiles: [...this.state.attachedFiles, ...files] });
  };

  onFileRemoved = file => {
    this.setState({
      attachedFiles: this.state.attachedFiles.filter(f => f !== file),
    });
  };

  handlePreview = async () => {
    const { data: html } = await axios.post('/api/searches/CreatePDF', {
      template: 'emergencyRepair',
      data: await this.getFormContent(),
    });
    this.setState({ pdfHtml: html });
  };

  handleDownload = async () => {
    this.setState({ loaderStatus: true });
    await axios.post('/api/searches/DownloadPDF', {
      template: 'emergencyRepair',
      data: await this.getFormContent(),
    });
    const nextLink = getNextLink(
      this.props.property.orderId,
      searchTypeId,
      this.props.match.params.propertyid
    );
    if (nextLink == null) {
      this.props.history.push(`/orders/order-information/${this.props.property.orderId}`);
    } else {
      this.props.history.push(nextLink);
    }
    this.setState({ loaderStatus: false });
  };

  setNewDate = async isForFuture => {
    if (isForFuture) {
      await axios.post('/api/searches/UpdateLastLienBook', this.state.lastPrintedLienBook);
    }
    this.setState({ displayChangeModal: false, disableDate: true });
  };

  onDateChange = e => {
    const nextState = produce(this.state, draftState => {
      draftState.lastPrintedLienBook['date'] = new Date(moment(e).format('MM-DD-YYYY'));
    });
    this.setState(nextState);
  };

  getFormContent = async () => {
    const {
      amountDueForRepairs,
      isERFound,
      isERNotFound,
      condo,
      isBasedOnMasterLot,
      additionalInfo,
      attachedFiles,
      doNotCheck,
      owners,
      lastPrintedLienBook,
      feesFound,
      vacateOrderFound,
      vacateInfo,
      hasAttachments,
      vacateCharges,
      vacateAdditionalInfo,
      vacateOrderCheckedOff,
      vacateOrder,
    } = this.state;
    const promises = attachedFiles.map(async f => {
      const base64 = await getBase64(f);
      return { name: f.name, base64String: base64 };
    });

    const files = await Promise.all(promises);
    return {
      date: new Date(),
      searchType: 'Emergency Repair',
      doNotCheck,
      amountDueForRepairs,
      isERFound,
      isERNotFound,
      isBasedOnMasterLot,
      additionalInfo,
      condo,
      additionalInformation: additionalInfo,
      propertyId: +this.props.match.params.propertyid,
      searchTypeId,
      searchDocumentType: 'Municipal Attachment',
      attachments: files,
      owners,
      lastPrintedLienBookDate: moment(lastPrintedLienBook.date).format('MMM DD YYYY'),
      runDate: this.props.runDate,
      feesFound,
      vacateOrderFound,
      vacateInfo,
      hasAttachments,
      vacateCharges,
      vacateAdditionalInfo,
      vacateOrderCheckedOff,
      vacateOrder,
    };
  };

  handleSelectedProperty = async e => {
    this.setState({ selectedProperty: e });
    this.getSavedData(e.value);
    this.setState({ documentKey: this.state.documentKey + 1 });
  };

  render() {
    const {
      amountDueForRepairs,
      isERFound,
      isERNotFound,
      isBasedOnMasterLot,
      additionalInfo,
      pdfHtml,
      loaderStatus,
      doNotCheck,
      owners,
      lastPrintedLienBook,
      disableDate,
      displayChangeModal,
      selectedProperty,
      documentKey,
      vacateOrderCheckedOff,
      vacateOrder,
      feesFound,
      vacateInfo,
      vacateCharges,
      vacateAdditionalInfo,
      hasAttachments,
      selectedNotes,
      noteOptions,
    } = this.state;
    const showNext = showNextButton(this.props.property.orderId, searchTypeId);
    const params = new URLSearchParams(this.props.location.search);
    const isEdit = params.get('isEdit');
    const dropdownStyles = {
      marginBottom: '16px',
    };
    const nycBoros = ['Manhattan', 'Bronx', 'Brooklyn', 'Queens', 'Staten Island'];
    const isNyc = nycBoros.includes(this.props.property.municipality);

    return (
      <div css={{ display: 'flex', height: '100%' }}>
        <div css={{ width: 654 }}>
          {isNyc && (
            <EmailRequestLetterDropdown style={dropdownStyles} orderId={this.props.property.orderId} />
          )}
          <ReusableTopOfForm
            handlePreview={this.handlePreview}
            searchHeader="Emergency Repair Search"
            propertyId={this.props.match.params.propertyid}
            searchTypeId={searchTypeId}
            property={this.props.property}
            getFormContent={this.getFormContent}
            template="emergencyRepair"
            setSelectedProperty={e => this.handleSelectedProperty(e)}
            setDocumentKey={() => this.setState({ documentKey: this.state.documentKey + 1 })}
            selectedProperty={selectedProperty}
            propertyDropdown={true}
            searchId={this.state.searchId}
          />
          <AssessToInput
            value={owners}
            onChange={e => this.setState({ owners: e.target.value })}
            propertyId={+this.props.match.params.propertyid}
          />
          <Checkbox
            checked={doNotCheck}
            label={'Do not check'}
            onChange={() => this.setState({ doNotCheck: !doNotCheck })}
          />
          {/* <div css={{ marginTop: 24, marginBottom: 24 }}>
            <BinaryRadioButton
              headerText="Results of search shows:"
              trueText="ER found"
              falseText="ER not found"
              checkedState={isERFound}
              onChange={checked => this.setState({ isERFound: checked })}
            />
          </div> */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              marginTop: 24,
              gap: 16,
            }}
          >
            <HudproCheckbox
              label="ER Not Found"
              checked={isERNotFound}
              onCheckChanged={checked => this.setState({ isERNotFound: checked, isERFound: false })}
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <HudproCheckbox
                label="ER Found"
                checked={isERFound}
                onCheckChanged={checked => this.setState({ isERFound: checked, isERNotFound: false })}
              />
              {isERFound && (
                <div style={{ marginTop: 4 }}>
                  <HudproCheckbox
                    label="Are there attachments?"
                    checked={hasAttachments}
                    onCheckChanged={checked => {
                      this.setState({
                        hasAttachments: checked,
                      });
                    }}
                  />
                </div>
              )}
            </div>
            <HudproCheckbox
              label="Fees found"
              checked={feesFound}
              onCheckChanged={checked => this.setState({ feesFound: checked })}
              // onCheckChanged={checked => {
              //   if (checked) {
              //     this.setState({ additionalInfo: this.state.additionalInfo + feeDetailMessage });
              //   } else {
              //     this.setState({ additionalInfo: this.state.additionalInfo.replace(feeDetailMessage, '') });
              //   }
              //   this.setState({ generateFeeDetailMessage: !this.state.generateFeeDetailMessage });
              // }}
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <HudproCheckbox
                label="Vacate Order Found"
                checked={vacateOrder}
                onCheckChanged={checked => {
                  this.setState({
                    vacateOrderCheckedOff: true,
                    vacateOrder: checked,
                    vacateOrderFound: checked,
                  });
                }}
              />
              {vacateOrderCheckedOff && (
                <div style={{ marginTop: 4 }}>
                  <HudproCheckbox
                    label="Relocation charges – if any – to follow"
                    checked={vacateCharges}
                    onCheckChanged={checked => {
                      if (checked) {
                        this.setState({
                          vacateCharges: true,
                          vacateOrder: checked,
                          vacateOrderFound: checked,
                          vacateInfo: `${vacateInfo}Relocation charges – if any – to follow \n`,
                        });
                      } else {
                        this.setState({
                          vacateInfo: this.state.vacateInfo.replace(
                            'Relocation charges – if any – to follow \n',
                            ''
                          ),
                          vacateCharges: false,
                        });
                      }
                    }}
                  />
                  <div style={{ marginTop: 4 }}>
                    <HudproCheckbox
                      label="Relocation Amount Note"
                      checked={vacateAdditionalInfo}
                      onCheckChanged={checked => {
                        if (checked) {
                          this.setState({
                            vacateAdditionalInfo: true,
                            vacateOrder: checked,
                            vacateOrderFound: checked,
                            vacateInfo: `${vacateInfo}HPD reports an active relocation lien for this property with $ due through `,
                          });
                        } else {
                          this.setState({
                            vacateInfo: this.state.vacateInfo.replace(
                              'HPD reports an active relocation lien for this property with $ due through ',
                              ''
                            ),
                            vacateAdditionalInfo: false,
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div css={{ marginTop: 24 }}>
            <CurrencyField
              value={amountDueForRepairs}
              onChange={e => this.setState({ amountDueForRepairs: e })}
              label="Amount of money due for repairs"
              width={295}
            />
          </div>
          <div style={{ marginTop: 24, width: '50%' }}>
            <span style={{ marginBottom: 8 }}>Last Printed Lien Book</span>
            <div style={{ display: 'flex' }}>
              <div
                css={{ '.rw-input[disabled], fieldset[disabled] .rw-input': { backgroundColor: '#F7F7F9' } }}
              >
                <DatePicker
                  format="MM/DD/YYYY"
                  time={false}
                  value={lastPrintedLienBook.date}
                  onChange={e => {
                    if (e) {
                      this.onDateChange(e);
                      this.setState({ displayChangeModal: true });
                    }
                  }}
                  disabled={disableDate}
                />
              </div>
              <div
                onClick={() => this.setState({ disableDate: false })}
                style={{
                  textDecorationLine: 'underline',
                  color: '#FF5252',
                  marginLeft: 28,
                  cursor: 'pointer',
                }}
              >
                Change
              </div>
            </div>
          </div>{' '}
          {/* {isERFound ? (
            <div css={{ marginTop: 24, marginBottom: 24 }}>
              <MultiFileUpload onFilesAdded={this.onFilesAdded} onFileRemoved={this.onFileRemoved} />
            </div>
          ) : null} */}
          <BinaryRadioButton
            headerText="Condo property based on a master lot"
            trueText="Yes"
            falseText="No"
            checkedState={isBasedOnMasterLot}
            onChange={checked => this.setState({ isBasedOnMasterLot: checked })}
          />
          {/* {!vacateOrderCheckedOff && (
            <div style={{ marginTop: 32 }}>
              <Checkbox
                label="Note: Vacate Order found on property. Charges – if any – to follow"
                checked={vacateOrder}
                onChange={checked => {
                  this.setState({
                    vacateOrderCheckedOff: true,
                    vacateOrder: checked,
                    additionalInfo: `${additionalInfo}Note: Vacate Order found on property. Charges – if any – to follow \n`,
                  });
                }}
              />
            </div>
          )} */}
          <div css={{ margin: '16px 0' }}>
            <textarea
              css={{
                resize: 'none',
                backgroundColor: colors.greyOrder,
                borderRadius: 3,
                width: 654,
                height: 100,
                marginTop: 8,
                marginBottom: 24,
                ':focus': { outline: 0 },
              }}
              value={vacateInfo}
              onChange={evt => this.setState({ vacateInfo: evt.target.value })}
              placeholder="Vacate Order Information"
            />
          </div>
          <MultiSelectDropdown
            value={'Select a note'}
            width="350px"
            onCheckChanged={idx => {
              const notesArray = [...noteOptions];
              notesArray[idx].isChecked = !notesArray[idx].isChecked;
              this.setState({ notesOptions: notesArray });
              notesArray[idx].isChecked
                ? this.setState({
                    additionalInfo:
                      additionalInfo === ''
                        ? notesArray[idx].name
                        : `${additionalInfo}${notesArray[idx].name}`,
                  })
                : this.setState({
                    additionalInfo: additionalInfo.replace(notesArray[idx].name, ''),
                  });
            }}
            itemList={noteOptions}
            caps={false}
          />
          <div css={{ margin: '16px 0' }}>
            <textarea
              css={{
                resize: 'none',
                backgroundColor: colors.greyOrder,
                borderRadius: 3,
                width: 654,
                height: 192,
                marginTop: 8,
                marginBottom: 24,
                ':focus': { outline: 0 },
              }}
              value={additionalInfo}
              onChange={evt => this.setState({ additionalInfo: evt.target.value })}
              placeholder="Additional Information"
            />
          </div>
          <div css={{ display: 'flex', justifyContent: 'flex-end', marginTop: 43 }}>
            <SaveAndHoldButton
              propertyId={+this.props.match.params.propertyid}
              searchTypeId={searchTypeId}
              formData={this.getFormContent}
              afterSubmitRoute={() =>
                this.props.history.push(`/orders/order-information/${this.props.property.orderId}`)
              }
              userId={this.props.user.id}
              orderId={this.props.property.orderId}
              disabled={loaderStatus}
            />
            <div css={{ marginLeft: 8 }}>
              <SubmitFormButton
                searchTypeId={searchTypeId}
                onSubmit={this.handleDownload}
                reactFormal={false}
                userId={this.props.user.id}
                orderId={this.props.property.orderId}
                loadingStatus={loaderStatus}
                buttonText={showNext ? 'Next' : `${isEdit ? 'Save' : 'Submit'}`}
                propertyId={+this.props.match.params.propertyid}
                getFormContent={this.getFormContent}
              />
            </div>
          </div>
          {this.state.searchId > 0 && (
            <WithDocumentStation key={documentKey} searchId={this.state.searchId} />
          )}
        </div>
        <div
          css={{
            width: '100%',
            borderStyle: 'solid',
            borderWidth: 2,
            padding: 16,
            position: 'sticky',
            top: 25,
            marginLeft: 24,
          }}
        >
          <iframe style={{ width: '100%', height: '100%' }} srcDoc={pdfHtml} />
        </div>
        {displayChangeModal && (
          <ConfirmActionModal
            openState={displayChangeModal}
            onClose={() => {
              this.setNewDate(false);
            }}
            header="Change For Future"
            info="Changing for future will use this date as the default date on all future Emergency Repair searches."
            actionFunction={() => this.setNewDate(true)}
            buttonText="Yes, change for future"
            cancelButtonText="No, just for now"
          />
        )}
      </div>
    );
  }
}
export default withProperty(withUser(EmergencyRepair), searchTypeId, true);
