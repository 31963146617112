/** @jsx jsx */
import { jsx, Global } from '@emotion/core';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Button from '../../ui/Button';
import colors from '../../../css/colors';
import Dropdown from '../../ui/Dropdown';
import MultiSelectDropdown from '../../ui/MultiSelectDropdown';
import '../../Extensions';
import Toggle from '../../ui/Toggle';
import convertToFileDownload from '../../FileDownloads';
import moment from 'moment';
import TextField from '../../ui/TextField';
import TextArea from '../../forms/ui/TextArea';
import CustomModal from '../../ui/CustomModal';
import CurrencyField from '../../ui/CurrencyField';
import isInNyc from '../../MunicipalityFunctions';
import Dropzone from 'react-dropzone';
import getBase64 from 'components/FileUploads';
import produce from 'immer';
import { XIcon } from 'components/ui/icons';
import { Checkbox } from 'semantic-ui-react';
import SemanticEmployeeDropdown from 'components/ui/SemanticEmployeeDropdown';
import ClientContactsDropdown from './ClientContactsDropdown';
import qs from 'qs';
import withUser from 'lib/WithUser';

const TOWN_TAX_INFO_REQUEST = 5;
const COUNTY_TAX_INFO_REQUEST = 7;

const overlayStyles = {
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  bottom: 0,
  height: '100%',
  left: 0,
  position: 'absolute',
  right: 0,
  top: 0,
  width: '100%',
  zIndex: 50,
};

const modalWrap = {
  backgroundColor: '#fff',
  border: `1px solid ${colors.greyBorder}`,
  borderRadius: '5px',
  left: '50%',
  padding: 40,
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 488,
  zIndex: 3000,
  maxHeight: '80vh',
  overflow: 'auto',
};
const titleStyles = {
  backgroundColor: colors.greyOrder,
  height: 72,
  width: 408,
  borderRadius: 5,
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 22,
  fontWeight: 'bold',
  fontSize: 20,
};

const dragBoxStyles = {
  alignItems: 'center',
  border: `1px dashed ${colors.grey}`,
  borderRadius: 4,
  color: 'black',
  display: 'flex',
  flexDirection: 'column',
  fontWeight: 'bold',
  height: 100,
  justifyContent: 'center',
  marginTop: 15,
};

const disabled = {
  pointerEvents: 'none',
  opacity: 0.5,
  cursor: 'not-allowed',
};

const tableRowWrap = {
  height: 80,
  display: 'flex',
  paddingLeft: 16,
  position: 'relative',
  '&:before': {
    position: 'absolute',
    top: 0,
    right: 0,
    borderRadius: 5,
    left: 0,
    width: '100%',
    content: '""',
    borderTop: `1px solid ${colors.grey}`,
  },
};

const cellWrap = {
  display: 'inline-flex',
  justifyContent: 'center',
  flexDirection: 'column',
  width: 134,
  ' span': { color: colors.greyDark },
};

const baseState = {
  open: false,
  documentType: { id: 1, name: 'Search' },
  requestType: null,
  properties: [],
  searches: [],
  filteredSearches: [],
  townFeeDocFee: '',
  townFeeDocSearchType: 'Municipal Info (Town)',
  errors: {
    docType: false,
    properties: false,
    searches: false,
    unassignedSearches: false,
    multipleAssignees: false,
    notaVendor: false,
    notanEmployee: false,
    requestType: false,
  },
  createButtonStatus: false,
  propertiesDropdownOpen: false,
  searchesDropdownOpen: false,
  selectedMunicipality: null,
  assigneeName: null,
  townFee: null,
  delayRange: '',
  additionalInfo: '',
  overridePayee: '',
  overrideAddress: '',
  overrideFee: '',
  delayLetterFiles: [],
  addToPrintQueue: false,
  replaceFee: false,
  reqAssigneeId: null,
  emailsUseToSend: [],
  note: '',
  buildingClass: '',
  uploadFtp: true,
  client: null,
  delayLetterSda: true,
  delayLetterInspection: true
};

const fieldStyles = {
  border: `1px solid ${colors.grey}`,
  display: 'block',
  borderRadius: 4,
  backgroundColor: '#fff',
  height: 62,
  width: 350,
  caretColor: colors.redLight,
  '::placeholder': {
    color: colors.greyBorder,
  },
  ':focus': {
    border: `1px solid ${colors.redLight}`,
  },
};

class CreateDocument extends React.Component {
  state = { ...baseState };

  componentDidMount = async () => {
    const queryString = qs.stringify({
      orderId: this.props.orderId,
    });
    const { data } = await axios.get(`/api/orders/includeInspectionWithRequest?${queryString}`);
    const { data: client } = await axios.get(`/api/orders/getClient/${this.props.orderId}`);
    this.setState({ includeInspection: data, client });
  };

  handleOnClick = async () => {
    this.setState({ open: true });
    const propertiesResponse = await axios.get(`/api/orders/getOrderProperties/${this.props.orderId}`);
    const municipalities = this.getMunicipalitiesList(propertiesResponse.data);
    await this.setState({
      properties: propertiesResponse.data.map(p => {
        return { ...p, selected: municipalities.length <= 1 };
      }),
    });

    this.handleOnClickSelectAllProperties(municipalities.length <= 1);
  };

  handleOnClickProperty = async index => {
    const properties = [...this.state.properties];
    properties[index].selected = !properties[index].selected;
    const selectedProperty = properties[index];
    if (properties[index].selected) {
      const propertySearches = (await axios.get(`/api/property/getSearches/${selectedProperty.id}`)).data.map(
        s => {
          return {
            ...s,
            propertyId: selectedProperty.id,
            includeInRequest: true,
          };
        }
      );
      await this.setState({
        searches: [...this.state.searches, ...propertySearches],
      });
    } else {
      await this.setState({
        searches: [...this.state.searches].filter(s => s.propertyId !== selectedProperty.id),
      });
    }
    const filteredSearches = this.narrowDownSearchList(this.state.searches);
    this.setState(state => ({
      errors: { ...state.errors, properties: false },
      properties,
      filteredSearches,
    }));
  };

  onDropFiles = async files => {
    const convertedFilesArr = [];
    for (let file of files) {
      convertedFilesArr.push({
        base64: await getBase64(file),
        fileName: file.name,
      });
    }
    const newFiles = produce(this.state.delayLetterFiles, draft => {
      for (let file of convertedFilesArr) {
        draft.push(file);
      }
    });
    this.setState({ delayLetterFiles: newFiles });
  };

  onDeleteFile = index => {
    const newFiles = produce(this.state.delayLetterFiles, draft => {
      draft.splice(index, 1);
    });
    this.setState({ delayLetterFiles: newFiles });
  };

  narrowDownSearchList = searches => {
    return searches.reduce((newArray, search) => {
      let foundSearch = newArray.find(s => s.searchTypeId === search.searchTypeId);
      if (foundSearch) {
        foundSearch = { ...foundSearch };
        if (foundSearch.propertyId === search.propertyId && moment(search.date) > moment(foundSearch.date)) {
          return newArray.map(s => (s.searchTypeId === search.searchTypeId ? { ...search } : { ...s }));
        } else return [...newArray];
      } else {
        return [...newArray, { ...search, includeInRequest: true }];
      }
    }, []);
  };

  handleOnClickSelectAllProperties = async selected => {
    this.setState({ propertiesDropdownOpen: false });
    let { properties: stateProperties, searches, filteredSearches, selectedMunicipality } = this.state;
    let properties =
      selectedMunicipality === null
        ? stateProperties
        : stateProperties.filter(p => {
            if (selectedMunicipality.isSubMunicipality) {
              return p.subMunicipalityId === selectedMunicipality.id;
            } else return p.municipalityId === selectedMunicipality.id && !p.subMunicipalityId;
          });
    if (!selected) {
      properties = properties.map(p => {
        return { ...p, selected: false };
      });
      searches = [];
      filteredSearches = [];
    } else {
      properties = properties.map(p => {
        return { ...p, selected: true };
      });
      searches = await properties.asyncReduce(async (arr, p) => {
        const { data } = await axios.get(`/api/property/getSearches/${p.id}`);
        return [...arr, ...data];
      }, []);
      searches = searches.map(s => {
        return { ...s, includeInRequest: true };
      });
      filteredSearches = this.narrowDownSearchList(searches);
    }
    this.setState({ properties, searches, filteredSearches });
  };

  handleOnClickSearch = async (item, value) => {
    const search = {
      ...this.state.filteredSearches.filter(s => s.id === item.id)[0],
    };
    await this.setState(state => ({
      filteredSearches: this.selectOtherSearchesOfSameType(search, state.filteredSearches, value),
      searches: this.selectOtherSearchesOfSameType(search, state.searches, value),
    }));
    this.setState(state => ({ errors: { ...state.errors, searches: false } }));
    this.validateSearchesAssignees(true);
  };

  selectOtherSearchesOfSameType = (search, searches, value) => {
    return searches.map(s => {
      if (this.state.documentType.id === 2 && this.state.requestType.id === 3 && s.searchTypeId === 5) {
        s.includeInRequest = false;
      }
      if (search.id === s.id) {
        return { ...s, selected: value ? value : !s.selected };
      } else if (
        search.searchTypeId === s.searchTypeId &&
        search.propertyId !== s.propertyId &&
        search.displayName == s.displayName
      ) {
        return { ...s, selected: value ? value : !s.selected };
      } else return { ...s };
    });
  };

  handleChangeIncludeRequest = searchTypeId => {
    this.setState(state => ({
      filteredSearches: state.filteredSearches.map(s =>
        s.searchTypeId === searchTypeId ? { ...s, includeInRequest: !s.includeInRequest } : { ...s }
      ),
      searches: state.searches.map(s =>
        s.searchTypeId === searchTypeId ? { ...s, includeInRequest: !s.includeInRequest } : { ...s }
      ),
    }));
    this.setState(state => ({ errors: { ...state.errors, searches: false } }));
  };

  handleOnClickSelectAllSearches = async (selected, viewableSearches) => {
    this.setState({ searchesDropdownOpen: false });
    viewableSearches.forEach(search => this.handleOnClickSearch(search, selected));
    this.validateSearchesAssignees(true);
  };

  validateSearchesAssignees(showErrors) {
    const { searches, requestType, documentType } = this.state;
    if (documentType && documentType.id !== 2) {
      return true;
    }
    let { assigneeName } = this.state;
    if (documentType !== null && documentType.id === 2 && requestType !== null) {
      const errors = { ...this.state.errors };
      const selectedSearches = searches.filter(s => s.selected);
      // if (!selectedSearches.every(s => s.assigneeVendorId || s.assigneeEmployeeId)) {
      //   errors.unassignedSearches = true;
      // } else errors.unassignedSearches = false;
      const vendors = selectedSearches.reduce(
        (arr, s) =>
          s.assigneeVendorId === null || arr.includes(s.assigneeVendorId)
            ? [...arr]
            : [...arr, s.assigneeVendorId],
        []
      );
      const employees = selectedSearches.reduce(
        (arr, s) =>
          s.assigneeEmployeeId === null || arr.includes(s.assigneeEmployeeId)
            ? [...arr]
            : [...arr, s.assigneeEmployeeId],
        []
      );
      if ((vendors.length > 0 && employees.length > 0) || vendors.length > 1 || employees.length > 1) {
        errors.multipleAssignees = true;
      } else errors.multipleAssignees = false;
      if (requestType.id == 1 && employees.length < 1) {
        //municipal in person
        errors.notanEmployee = true;
      } else errors.notanEmployee = false;
      if ((requestType.id == 2 || requestType.id == 4) && vendors.length < 1) {
        //municipal vendor or abstract
        errors.notaVendor = true;
      } else errors.notaVendor = false;
      if (
        !errors.unassignedSearches &&
        !errors.multipleAssignees &&
        !errors.notaVendor &&
        !errors.notanEmployee
      ) {
        if (requestType.id != 3 && requestType.id != 5) {
          assigneeName = selectedSearches[0]?.assignedTo;
        }
      }
      if (showErrors) {
        this.setState({ errors, assigneeName });
      } else
        return (
          !errors.unassignedSearches &&
          !errors.multipleAssignees &&
          !errors.notaVendor &&
          !errors.notanEmployee
        );
    }
  }

  validate = showErrors => {
    const { documentType, filteredSearches, properties, requestType } = this.state;
    const errors = { ...this.state.errors };
    if (documentType == null) {
      errors.docType = true;
    } else errors.docType = false;
    const selectedSearches = filteredSearches.filter(s => s.selected);
    if (
      documentType.id !== 3 &&
      documentType.id !== 6 &&
      documentType.id !== 7 &&
      selectedSearches.length === 0
    ) {
      errors.searches = true;
    } else errors.searches = false;
    const selectedProperties = properties.filter(p => p.selected);
    if (selectedProperties.length === 0) {
      errors.properties = true;
    } else errors.properties = false;
    if (
      documentType.id == 2 &&
      (requestType?.id === 2 || requestType?.id === 4) &&
      selectedSearches.filter(s => s.assignedTo === null || s.assignedTo === '').length > 0
    ) {
      errors.unassignedSearches = true;
    } else errors.unassignedSearches = false;
    if (documentType.id == 2 && requestType === null) {
      errors.requestType = true;
    } else errors.requestType = false;
    if (showErrors) {
      this.setState({ errors });
    } else
      return (
        !errors.docType &&
        !errors.searches &&
        !errors.properties &&
        !errors.unassignedSearches &&
        !errors.requestType
      );
  };

  onCreateClick = async () => {
    await this.validate(true);
    if (Object.values(this.state.errors).indexOf(true) === -1) {
      this.setState({ createButtonStatus: true });
      const {
        documentType,
        properties: stateProperties,
        selectedMunicipality,
        filteredSearches,
        searches,
        requestType,
        townFee,
        overridePayee,
        overrideAddress,
        overrideFee,
        addToPrintQueue: addToPrintQueue,
        replaceFee: replaceFee,
        reqAssigneeId,
        emailsUseToSend,
        note,
        includeInspection,
      } = this.state;
      const properties =
        selectedMunicipality === null
          ? stateProperties
          : stateProperties.filter(p => {
              if (selectedMunicipality.isSubMunicipality) {
                return p.subMunicipalityId === selectedMunicipality.id;
              } else return p.municipalityId === selectedMunicipality.id && !p.subMunicipalityId;
            });
      const { orderId, userId } = this.props;
      if (documentType.id === 1) {
        const { data: searchesResponse } = await axios.get(
          `/api/orders/getOrderSearches/${this.props.orderId}`
        );
        const documentLinks = await filteredSearches
          .filter(s => s.selected)
          .asyncReduce(async (arr, s) => {
            const filteredProperties = properties.filter(p =>
              searchesResponse.find(r => r.propertyId === p.id)
            );
            const links = await Promise.all(
              filteredProperties.map(async p => {
                return {
                  link: await decodeLink(p.id, s.documentLink),
                  searchTypeId: s.searchTypeId,
                  propertyId: p.id,
                };
              })
            );
            return [...arr, ...links];
          }, []);
        const fullInfo = {
          onProperty: this.props.match.params.propertyId,
          documentLinks,
        };
        localStorage.setItem(`orderDocumentLinks_${orderId}`, JSON.stringify(fullInfo));
        this.props.history.push(documentLinks[0].link);
      } else if (documentType.id === 2) {
        const vendorId = searches.filter(s => s.selected)[0]?.assigneeVendorId;
        const employeeId = searches.filter(s => s.selected)[0]?.assigneeEmployeeId || userId;
        if (requestType.id == 8) {
          const taxInfoTypes = [TOWN_TAX_INFO_REQUEST, COUNTY_TAX_INFO_REQUEST];
          for (const taxType of taxInfoTypes) {
            const response = await axios.post(`/api/orders/createRequestDocument/${orderId}`, {
              documentType: taxType,
              searchIds: searches.filter(s => s.selected).map(s => s.id),
              excludedSearches: searches.filter(s => s.selected && !s.includeInRequest).map(s => s.id),
              vendorId: vendorId,
              employeeId: employeeId,
              isResidentialFee: townFee?.selectedFeeType?.id == 1 || false,
              overridePayee,
              overrideAddress,
              overrideFee,
              note,
              includeInspection,
            });

            if (townFee?.selectedFeeType?.id === 1) {
              const promises = properties
                .filter(p => p.selected)
                .map(async p => {
                  await axios.post('/api/property/updateisresindential', {
                    propertyId: p.id,
                    isResidential: townFee.selectedFeeType.id === 1,
                  });
                });
              await Promise.all(promises);
            }
            if (response.data.message) {
              alert(response.data.message);
            }
            if (response.data.length) {
              response.data.forEach(f => convertToFileDownload(f.fileBase64, f.fileName));
            }
          }
        } else {
          const response = await axios.post(`/api/orders/createRequestDocument/${orderId}`, {
            documentType: requestType.id,
            searchIds: searches.filter(s => s.selected).map(s => s.id),
            excludedSearches: searches.filter(s => s.selected && !s.includeInRequest).map(s => s.id),
            vendorId: vendorId,
            employeeId: employeeId,
            isResidentialFee: townFee?.selectedFeeType?.id == 1 || false,
            overridePayee,
            overrideAddress,
            overrideFee,
            addToPrintQueue,
            replaceFee,
            reqAssigneeId,
            note,
            includeInspection,
            buildingClass: this.state.buildingClass?.name,
          });

          if (townFee?.selectedFeeType?.id === 1) {
            const promises = properties
              .filter(p => p.selected)
              .map(async p => {
                await axios.post('/api/property/updateisresindential', {
                  propertyId: p.id,
                  isResidential: townFee.selectedFeeType.id === 1,
                });
              });
            await Promise.all(promises);
          }
          if (response.data.message) {
            alert(response.data.message);
          }
          if (response.data.length) {
            response.data
              .filter(d => d.fileName.includes('.msg'))
              .forEach(f => convertToFileDownload(f.fileBase64, f.fileName));
          }
          // else {
          // convertToFileDownload(response.data.fileBase64, response.data.fileName);
          // }
        }
      } else if (documentType.id === 3) {
        const response = await axios.post(`/api/orders/createRequestDocument/${orderId}`, {
          documentType: 'sdaRequest',
          propertyIds: properties.filter(p => p.selected).map(p => p.id),
          excludedSearches: [],
          overridePayee,
          overrideAddress,
          overrideFee,
          emailsUseToSend,
          note,
          includeInspection,
          sendFtp: this.state.uploadFtp,
        });
        if (response.data.message) {
          alert(response.data.message);
        }
        if (response.data.length) {
          response.data
            .filter(d => d.fileName.includes('.msg'))
            .forEach(f => convertToFileDownload(f.fileBase64, f.fileName));
        }
      } else if (documentType.id === 4) {
        const response = await axios.post('/api/orders/createDelayLetter', {
          orderId: this.props.orderId,
          searchIds: searches.filter(s => s.selected).map(s => s.id),
          propertyIds: properties.filter(p => p.selected).map(p => p.id),
          range: this.state.delayRange,
          additionalInfo: this.state.additionalInfo,
          delayLetterFiles: this.state.delayLetterFiles,
          sendFtp: this.state.uploadFtp,
          includeSda: this.state.delayLetterSda,
          includeInspection: this.state.delayLetterInspection
        });
        if (response.data.message) {
          alert(response.data.message);
        }
        if (response.data.files.length) {
          response.data.files
            .filter(d => d.fileName.includes('.msg'))
            .forEach(f => convertToFileDownload(f.base64, f.fileName));
        }
      } else if (documentType.id === 5) {
        const vendorId = searches.filter(s => s.selected)[0]?.assigneeVendorId;
        const employeeId = searches.filter(s => s.selected)[0]?.assigneeEmployeeId || userId;
        const response = await axios.post(`/api/orders/createRequestDocument/${orderId}`, {
          documentType: 10,
          propertyIds: properties.filter(p => p.selected).map(p => p.id),
          excludedSearches: [],
          searchIds: searches.filter(s => s.selected).map(s => s.id),
          vendorId: vendorId,
          employeeId: employeeId,
          overridePayee,
          overrideAddress,
          overrideFee,
          note,
          includeInspection,
        });
        if (response.data.message) {
          alert(response.data.message);
        }
        if (response.data.length) {
          response.data
            .filter(d => d.fileName.includes('.msg'))
            .forEach(f => convertToFileDownload(f.fileBase64, f.fileName));
        }
      } else if (documentType.id === 6) {
        const vendorId = searches.filter(s => s.selected)[0]?.assigneeVendorId;
        const employeeId = searches.filter(s => s.selected)[0]?.assigneeEmployeeId || userId;
        const response = await axios.post(`/api/orders/createTownFeeDocument`, {
          orderId,
          searchType: this.state.townFeeDocSearchType,
          townFee: this.state.townFeeDocFee,
          propertyIds: properties.filter(p => p.selected).map(p => p.id),
        });
      } else if (documentType.id === 7) {
        const response = await axios.post(`/api/orders/createFireSearchRequest`, {
          orderId,
          propertyIds: properties.filter(p => p.selected).map(p => p.id),
        });
        if (response.data.length) {
          response.data
            .filter(d => d.fileName.includes('.msg'))
            .forEach(f => convertToFileDownload(f.fileBase64, f.fileName));
        }
      } else if (documentType.id === 8) {
        const response = await axios.post('/api/orders/createFileNotFoundDelayLetter', {
          orderId: this.props.orderId,
          searchIds: searches.filter(s => s.selected).map(s => s.id),
          propertyIds: properties.filter(p => p.selected).map(p => p.id),
          range: null,
          additionalInfo: this.state.additionalInfo,
          delayLetterFiles: this.state.delayLetterFiles,
        });
        if (response.data.length) {
          response.data
            .filter(d => d.fileName.includes('.msg'))
            .forEach(f => convertToFileDownload(f.base64, f.fileName));
        }
      }
      this.setState(baseState);
    }
    this.props.onFinish();
  };

  filterSearches = (searches, properties) => {
    const { documentType, requestType } = this.state;
    searches = searches.filter(s => properties.map(p => p.id).includes(s.propertyId));
    if (documentType !== null) {
      if (documentType.id == 1) {
        searches = searches.filter(s => s.documentLink !== null);
      } else if (requestType?.id == 13) {
        searches = searches.filter(s => s.searchTypeId === 12);
      } else if (documentType.id == 2) {
        if (requestType !== null) {
          if (requestType.id == 1 || requestType.id == 2) {
            //municipal in person, municipal vendor
            searches = searches.filter(s => s.searchCategoryId === 1 || s.searchCategoryId === 2);
          } else if (requestType.id == 3 || requestType.id == 9) {
            //municipal town
            //building violations, cert of occupancy, street report, survey locate
            searches = searches.filter(
              s =>
                s.searchTypeId === 17 ||
                s.searchTypeId === 3 ||
                s.searchTypeId === 5 ||
                s.searchTypeId === 13 ||
                s.searchTypeId === 14 ||
                s.searchTypeId === 4 ||
                s.searchTypeId === 49 ||
                s.searchTypeId === 10 ||
                s.searchTypeId === 59 ||
                s.searchTypeId === 9 ||
                s.searchTypeId === 66 ||
                s.searchTypeId === 12
            );
          } else if (requestType.id == 4) {
            //abstract
            searches = searches.filter(s => s.searchCategoryId === 3);
          } else if (requestType.id == 5 || requestType.id == 7 || requestType.id == 8) {
            //tax town, tax county, tax town and county
            searches = searches.filter(s => s.searchCategoryId === 2);
          }
        }
      } else if (documentType.id == 5) {
        searches = searches.filter(s => s.searchTypeId === 48);
      }
    }
    //sort
    let sortedSearches = [];
    sortedSearches.push(...searches.filter(s => s.searchTypeId == 2)); //TaxSearch
    searches = searches.filter(s => s.searchTypeId !== 2);
    sortedSearches.push(...searches.filter(s => s.searchTypeId == 3)); //Certificate Of Occupancy'
    searches = searches.filter(s => s.searchTypeId !== 3);
    sortedSearches.push(...searches.filter(s => s.searchTypeId == 17)); //Housing and Building Violation Search
    searches = searches.filter(s => s.searchTypeId !== 17);
    sortedSearches.push(...searches);
    return sortedSearches;
  };

  setTownFeeDocFee = async () => {
    const { properties } = this.state;
    const municipalities = this.getMunicipalitiesList(properties);
    const municipality = municipalities[0];
    let response;
    if (municipality.isSubMunicipality) {
      response = await axios.get(`/api/municipalities/getsubmunicipality/${municipality.id}`);
    } else {
      response = await axios.get(`/api/municipalities/getmunicipality/${municipality.id}`);
    }
    const { feeAmount } = response.data;

    this.setState({
      townFeeDocFee: feeAmount,
    });
  };

  setTownFeeInfo = async item => {
    const { selectedMunicipality, properties } = this.state;
    const municipalities = this.getMunicipalitiesList(properties);
    if (selectedMunicipality !== null || municipalities.length == 1 || item !== undefined) {
      const municipality = selectedMunicipality || item !== undefined ? item : municipalities[0];
      let response;
      if (municipality?.isSubMunicipality) {
        response = await axios.get(`/api/municipalities/getsubmunicipality/${municipality.id}`);
      } else {
        response = await axios.get(`/api/municipalities/getmunicipality/${municipality.id}`);
      }
      const {
        feeTypeId,
        feeAmount,
        commercialFeeAmount,
        taxInfoFeeAmount,
        countyTaxInfoFeeAmount,
      } = response.data;

      this.setState({
        selectedMunicipality: municipality,
        townFee: {
          feeTypeId,
          feeAmount,
          commercialFeeAmount,
          taxInfoFeeAmount,
          countyTaxInfoFeeAmount,
        },
      });
    } else return;
  };

  getMunicipalitiesList = properties => {
    return properties.reduce((arr, p) => {
      const isSubMunicipality = p.subMunicipality !== null;
      if (!isSubMunicipality) {
        const indexFound = arr.findIndex(n => !n.isSubMunicipality && n.id == p.municipalityId);
        const found = indexFound > -1;
        if (!found) {
          return [
            ...arr,
            {
              id: p.municipalityId,
              name: p.municipality,
              propertyCount: 1,
              isSubMunicipality: false,
            },
          ];
        } else {
          return arr.map((n, i) =>
            i === indexFound ? { ...n, propertyCount: n.propertyCount + 1 } : { ...n }
          );
        }
      } else {
        const indexFound = arr.findIndex(n => n.isSubMunicipality && n.id == p.subMunicipalityId);
        const found = indexFound > -1;
        if (!found) {
          return [
            ...arr,
            {
              id: p.subMunicipalityId,
              name: p.subMunicipality,
              propertyCount: 1,
              isSubMunicipality: true,
            },
          ];
        } else {
          return arr.map((n, i) =>
            i === indexFound ? { ...n, propertyCount: n.propertyCount + 1 } : { ...n }
          );
        }
      }
    }, []);
  };

  selectMunicipality = async selectedMunicipality => {
    const filteredProperties = this.state.properties.filter(p => {
      if (selectedMunicipality.isSubMunicipality) {
        return p.subMunicipalityId === selectedMunicipality.id;
      } else return p.municipalityId === selectedMunicipality.id && !p.subMunicipalityId;
    });

    if (filteredProperties.length === 1) {
      const { id } = filteredProperties[0];
      const index = this.state.properties.findIndex(p => p.id === id);
      const properties = this.state.properties.map(p => ({
        ...p,
        selected: false,
      }));
      properties[index].selected = !properties[index].selected;
      const selectedProperty = properties[index];
      const propertySearches = (await axios.get(`/api/property/getSearches/${selectedProperty.id}`)).data.map(
        s => {
          return { ...s, propertyId: selectedProperty.id };
        }
      );
      await this.setState({
        searches: [...this.state.searches, ...propertySearches],
      });

      const filteredSearches = propertySearches.map(search => ({
        ...search,
        includeInRequest: true,
      }));
      this.setState(state => ({
        errors: { ...state.errors, properties: false },
        properties,
        filteredSearches,
        searches: filteredSearches,
      }));
    }
  };
  handleToggleChange = e => {
    e.stopPropagation();
    this.setState({ includeInspection: !this.state.includeInspection });
  };

  render() {
    const {
      open,
      documentType,
      filteredSearches,
      properties,
      errors,
      createButtonStatus,
      selectedMunicipality,
      requestType,
      assigneeName,
      townFee,
      delayRange,
      additionalInfo,
      overridePayee,
      overrideAddress,
      overrideFee,
      note,
    } = this.state;
    const selectedSearches = filteredSearches.filter(s => s.selected).length;
    const municipalities = this.getMunicipalitiesList(properties);

    const filteredProperties =
      selectedMunicipality === null
        ? properties
        : properties.filter(p => {
            if (selectedMunicipality.isSubMunicipality) {
              return p.subMunicipalityId === selectedMunicipality.id;
            } else return p.municipalityId === selectedMunicipality.id && !p.subMunicipalityId;
          });

    const selectedProperties = filteredProperties.filter(p => p.selected).length;
    const formattedPropertiesList = filteredProperties.map((p, i) => {
      return {
        id: p.id,
        name: `${p.address} ${p.city}, ${p.state} ${p.zip} ${
          p.section != null && p.block != null && p.lot != null ? `${p.section}.${p.block}.${p.lot}` : ''
        }`,
        isChecked: p.selected,
        index: i,
      };
    });
    const sortedSearches = this.filterSearches(filteredSearches, filteredProperties);
    const formattedSearchList = sortedSearches.map((s, i) => {
      return { id: s.id, name: s.displayName, isChecked: s.selected, index: i };
    });

    const inWappinger =
      municipalities.length === 1 &&
      !municipalities[0].isSubMunicipality &&
      municipalities[0].name === 'Wappinger';
    const inWashington =
      municipalities.length === 1 &&
      !municipalities[0].isSubMunicipality &&
      municipalities[0].name === 'Washington';
    return (
      <div>
        <Button
          secondary
          overRidestyle={{ padding: '14px 24px', marginLeft: 16 }}
          onClick={this.handleOnClick}
        >
          Create Document
        </Button>
        <div>
          <CustomModal onClose={() => this.setState({ ...baseState })} open={open} notCloseOverlay={true}>
            <div css={titleStyles}>Create Document</div>
            <div>
              <div css={{ fontSize: 14, marginTop: 32 }}>Document Type</div>
              <div css={{ marginTop: 16 }}>
                <Dropdown
                  value={documentType?.name || 'Select document type'}
                  selectedId={documentType?.id || null}
                  width="350"
                  onClick={item => {
                    this.setState(state => ({
                      documentType: item,
                      requestType: null,
                      selectedMunicipality: null,
                      searches: state.searches.map(s => {
                        return { ...s, selected: false };
                      }),
                      filteredSearches: state.filteredSearches.map(s => {
                        return { ...s, selected: false };
                      }),
                    }));
                    if (item.id === 6) {
                      this.setTownFeeDocFee();
                    }
                  }}
                  handleToggleChange={this.handleToggleChange}
                  includeInspection={this.state.includeInspection}
                  itemList={[
                    { id: 1, name: 'Search' },
                    { id: 2, name: 'Municipal Request', showToggle: true },
                    {
                      id: 3,
                      name:
                        inWappinger || inWashington
                          ? 'Consent Form Request'
                          : 'Smoke Detector Affadavit Request',
                    },
                    { id: 4, name: 'Delay Letter' },
                    { id: 5, name: 'Survey Inspection Request' },
                    { id: 6, name: 'Town Fee Letter' },
                    { id: 7, name: 'Fire Search Info Request' },
                    { id: 8, name: 'File Not Found Delay Letter' },
                  ]}
                />
              </div>
              {errors.docType && <Error message="Document Type is required" />}

              {documentType.id == 2 && (
                <div>
                  <div css={{ fontSize: 14, marginTop: 32 }}>Request Type</div>
                  <div css={{ marginTop: 16 }}>
                    <Dropdown
                      value={requestType?.name || 'Select request type'}
                      selectedId={requestType?.id || null}
                      width="350"
                      onClick={item => {
                        if ((item.id === 3 || item.id == 9) && this.props.user?.initials != 'W.R') {
                          this.setState({ addToPrintQueue: true });
                        }
                        this.setState(state => ({
                          requestType: item,
                          assigneeName: null,
                          searches: state.searches.map(s => {
                            return { ...s, selected: false };
                          }),
                          filteredSearches: state.filteredSearches.map(s => {
                            return { ...s, selected: false };
                          }),
                        }));
                        if (item.id == 3 || item.id == 5 || item.id == 7 || item.id == 8) {
                          this.setTownFeeInfo();
                        }
                        if (item?.id === 13) {
                          this.setState({ overrideFee: 95 });
                        }
                      }}
                      itemList={[
                        { id: 1, name: 'Municipal In-Person Request' },
                        { id: 2, name: '  Vendor Request' },
                        { id: 3, name: 'Municipal Town Request' },
                        { id: 9, name: 'Municipal Payment Request' },
                        { id: 4, name: 'Abstract Request' },
                        { id: 5, name: 'Tax Town Request' },
                        { id: 7, name: 'Tax County Request' },
                        { id: 8, name: 'Tax Town and County Request' },
                        { id: 13, name: 'Nassau Sewer Request' },
                      ]}
                    />
                  </div>
                  {errors.requestType && <Error message="Request Type is required" />}
                </div>
              )}

              {documentType?.id == 2 && municipalities.length > 1 && (
                <div>
                  <div css={{ fontSize: 14, marginTop: 32 }}>Municipality</div>
                  <div css={{ marginTop: 16 }}>
                    <Dropdown
                      value={selectedMunicipality?.name || 'Select municipality'}
                      selectedId={selectedMunicipality?.id || null}
                      width="350"
                      onClick={item => {
                        this.selectMunicipality(item);
                        this.setState({
                          selectedMunicipality: item,
                        });
                        this.setTownFeeInfo(item);
                      }}
                      itemList={municipalities.map(m => {
                        return {
                          ...m,
                          name: `${m.name} (${m.propertyCount} ${
                            m.propertyCount > 1 ? 'properties' : 'property'
                          })`,
                        };
                      })}
                    />
                  </div>
                </div>
              )}
              {documentType.id === 3 && (
                <div style={{ marginTop: 20, display: 'flex', gap: 8, flexDirection: 'column' }}>
                  <div>Select Email</div>
                  <ClientContactsDropdown
                    onChange={emailsUseToSend => {
                      this.setState({ emailsUseToSend });
                    }}
                    orderId={this.props.orderId}
                    placeholder="Select client email"
                  />
                </div>
              )}
              {[3, 4].includes(documentType.id) && [41, 860].includes(this.props.clientId) && (
                <div style={{ marginTop: 16 }}>
                  <Checkbox
                    checked={this.state.uploadFtp}
                    onChange={(e, { checked }) => {
                      this.setState({ uploadFtp: checked });
                    }}
                    label="Upload Ftp?"
                  />
                </div>
              )}
              {[4].includes(documentType.id) && [41, 860].includes(this.props.clientId) && (
                <div style={{ display: 'flex', gap: 16, marginTop: 16 }}>
                  <div>
                    <Checkbox
                      checked={this.state.delayLetterSda}
                      onChange={(e, { checked }) => {
                        this.setState({ delayLetterSda: checked });
                      }}
                      label="include Sda?"
                    />
                  </div>
                  <div>
                    <Checkbox
                      checked={this.state.delayLetterInspection}
                      onChange={(e, { checked }) => {
                        this.setState({ delayLetterInspection: checked });
                      }}
                      label="include Inspection?"
                    />
                  </div>
                </div>
              )}

              {townFee &&
                requestType.id !== 5 &&
                requestType.id !== 7 &&
                requestType.id !== 8 &&
                requestType.id !== 9 && (
                  <div css={{ color: colors.red, marginTop: 16 }}>
                    Town Fee Info:
                    {townFee.feeTypeId == 1 ? (
                      <p>Commercial or Residential Fee</p>
                    ) : townFee.feeTypeId == 2 ? (
                      <p>The town has an overall fee of ${townFee.feeAmount}.</p>
                    ) : townFee.feeTypeId == 3 ? (
                      <p>The town fee is unknown. Enclose a blank check or research check amount.</p>
                    ) : townFee.feeTypeId == 4 ? (
                      <p>The town does not have a fee.</p>
                    ) : (
                      <p>There is no fee information</p>
                    )}
                  </div>
                )}

              {townFee && requestType.id == 5 && (
                <div css={{ color: colors.red, marginTop: 16 }}>
                  Tax Info Fee: {!!townFee.taxInfoFeeAmount ? `$${townFee.taxInfoFeeAmount}` : ''}
                  {!townFee.taxInfoFeeAmount && <p>The tax info fee has not been set</p>}
                </div>
              )}

              {townFee && requestType.id == 7 && (
                <div css={{ color: colors.red, marginTop: 16 }}>
                  County Tax Info Fee:{' '}
                  {!!townFee.countyTaxInfoFeeAmount ? `$${townFee.countyTaxInfoFeeAmount}` : ''}
                  {!townFee.countyTaxInfoFeeAmount && <p>The County tax info fee has not been set</p>}
                </div>
              )}

              {townFee && requestType.id == 8 && (
                <React.Fragment>
                  <div css={{ color: colors.red, marginTop: 16 }}>
                    Tax Info Fee: {!!townFee.taxInfoFeeAmount ? `$${townFee.taxInfoFeeAmount}` : ''}
                    {!townFee.taxInfoFeeAmount && <p>The tax info fee has not been set</p>}
                  </div>
                  <div css={{ color: colors.red, marginTop: 16 }}>
                    County Tax Info Fee:{' '}
                    {!!townFee.countyTaxInfoFeeAmount ? `$${townFee.countyTaxInfoFeeAmount}` : ''}
                    {!townFee.countyTaxInfoFeeAmount && <p>The County tax info fee has not been set</p>}
                  </div>
                </React.Fragment>
              )}
              {townFee && [1, 2, 3].includes(requestType.id) && townFee.feeTypeId == 1 && (
                <div>
                  <div css={{ fontSize: 14, marginTop: 32 }}>Fee</div>
                  <div css={{ marginTop: 16 }}>
                    <Dropdown
                      value={townFee.selectedFeeType?.name || 'Select Fee'}
                      selectedId={townFee.selectedFeeType?.id || null}
                      itemList={[
                        {
                          id: 1,
                          name: `Residential ($${townFee.feeAmount})`,
                        },
                        {
                          id: 2,
                          name: `Commercial ($${townFee.commercialFeeAmount || 0})`,
                        },
                      ]}
                      onClick={item =>
                        this.setState({
                          townFee: { ...townFee, selectedFeeType: item },
                        })
                      }
                    />
                  </div>
                  {properties.some(p => p.municipality === 'Orangetown') && (
                    <div>
                      <div css={{ fontSize: 14, marginTop: 32 }}>Building Class</div>
                      <div css={{ marginTop: 16 }}>
                        <Dropdown
                          value={this.state.buildingClass?.name || 'Select Building Class'}
                          selectedId={this.state.buildingClass?.id || null}
                          itemList={[
                            {
                              id: 1,
                              name: `Residential`,
                            },
                            {
                              id: 2,
                              name: `Commercial`,
                            },
                          ]}
                          onClick={item =>
                            this.setState({
                              buildingClass: item,
                            })
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
              {requestType && requestType.id == 9 && (
                <div css={{ marginTop: 25 }}>
                  <CurrencyField
                    value={overrideFee}
                    onChange={v => this.setState({ overrideFee: v })}
                    label="Fee:"
                  />
                  <div css={{ marginTop: 25, display: 'flex', gap: 20, alignItems: 'center' }}>
                    <Checkbox
                      label="Replace old fee amount with new fee amount"
                      value={this.state.replaceFee}
                      checked={this.state.replaceFee}
                      onChange={() => this.setState(prevState => ({ replaceFee: !prevState.replaceFee }))}
                    />
                  </div>
                </div>
              )}

              {formattedPropertiesList.length > 1 && (
                <div>
                  <div css={{ fontSize: 14, marginTop: 32 }}>Property</div>
                  <div css={[{ marginTop: 16 }, documentType === '' ? disabled : '']}>
                    <MultiSelectDropdown
                      value={
                        selectedProperties > 0
                          ? `${selectedProperties} ${
                              selectedProperties > 1 ? 'Properties' : 'Property'
                            } Selected`
                          : 'Select properties'
                      }
                      width="350px"
                      onCheckChanged={this.handleOnClickProperty}
                      itemList={formattedPropertiesList}
                      includeSelectAll
                      onSelectAllChanged={this.handleOnClickSelectAllProperties}
                      isOpen={this.state.propertiesDropdownOpen}
                      onOpenChanged={o => this.setState({ propertiesDropdownOpen: o })}
                    />
                  </div>
                </div>
              )}

              {errors.properties && <Error message="Property is required" />}

              {documentType && documentType.id !== 3 && documentType.id !== 6 && documentType.id !== 7 && (
                <div>
                  <div css={{ fontSize: 14, marginTop: 32 }}>Search</div>
                  <div css={[{ marginTop: 16 }, formattedSearchList.length === 0 ? disabled : '']}>
                    <MultiSelectDropdown
                      value={
                        selectedSearches > 0
                          ? `${selectedSearches} ${selectedSearches > 1 ? 'Searches' : 'Search'} Selected`
                          : 'Select searches'
                      }
                      width="350px"
                      onCheckChanged={index => this.handleOnClickSearch(formattedSearchList[index])}
                      itemList={formattedSearchList}
                      includeSelectAll
                      onSelectAllChanged={selected =>
                        this.handleOnClickSelectAllSearches(selected, sortedSearches)
                      }
                      isOpen={this.state.searchesDropdownOpen}
                      onOpenChanged={o => this.setState({ searchesDropdownOpen: o })}
                    />
                  </div>
                </div>
              )}
              {documentType.id === 4 && (
                <div>
                  <div css={{ fontSize: 14, marginTop: 32 }}>Delay Range (number of days)</div>
                  <div css={{ marginTop: 16 }}>
                    <TextField
                      value={delayRange}
                      onChange={e => this.setState({ delayRange: e.target.value })}
                    />
                  </div>
                  <div>
                    <Dropzone onDrop={this.onDropFiles}>
                      {({ getRootProps, getInputProps, isDragActive }) => {
                        return (
                          <div style={dragBoxStyles} {...getRootProps()}>
                            <input {...getInputProps()} />
                            {isDragActive ? (
                              <p>Drag and drop to import file</p>
                            ) : (
                              <p>Drag and drop files here, or click to upload.</p>
                            )}
                          </div>
                        );
                      }}
                    </Dropzone>
                    {this.state.delayLetterFiles.map((f, index) => (
                      <div
                        key={index}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          backgroundColor: 'lightgray',
                          borderRadius: 5,
                          padding: 15,
                          gap: 4,
                          marginTop: 10,
                        }}
                      >
                        <div>{f.fileName}</div>
                        <button onClick={() => this.onDeleteFile(index)}>
                          <XIcon />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {errors.searches && <Error message="Search is required" />}
              {errors.unassignedSearches && (
                <Error message="One or more of the selected searches is unassigned" />
              )}
              {errors.multipleAssignees && (
                <Error message="All selected searches must be assigned to the same vendor or employee" />
              )}
              {errors.notaVendor && <Error message="The selected searches must be assigned to a vendor" />}
              {errors.notanEmployee && (
                <Error message="The selected searches must be assigned to an employee" />
              )}
              {assigneeName !== null && (
                <div css={{ marginTop: 16 }}>This request is being sent to: {assigneeName}</div>
              )}
            </div>
            {documentType.id == 2 && (
              <div css={{ marginTop: 16 }}>
                <div key={-1} /*css={tableRowWrap}*/>
                  <div css={cellWrap}>Search</div>
                  <div css={cellWrap}>Include in Request</div>
                </div>
                {sortedSearches
                  .filter(s => s.selected)
                  .map((s, i) => (
                    <div key={i} css={{ marginTop: 8 }} /*css={tableRowWrap}*/>
                      <div css={cellWrap}>{s.searchType}</div>
                      <div css={cellWrap}>
                        <Toggle value={s.includeInRequest} handleOnClick={() => this.handleToggleChange()} />
                      </div>
                    </div>
                  ))}
              </div>
            )}
            {(documentType.id == 4 || documentType.id == 8) && (
              <div>
                <div css={{ fontSize: 14, marginTop: 32 }}>Additional Information</div>
                <textarea
                  value={additionalInfo}
                  onChange={e => this.setState({ additionalInfo: e.target.value })}
                  css={fieldStyles}
                />
              </div>
            )}
            {(requestType?.id === 3 ||
              requestType?.id === 5 ||
              requestType?.id === 7 ||
              requestType?.id == 9) && (
              <div css={{ marginTop: 25, display: 'flex', gap: 20, alignItems: 'center' }}>
                <Checkbox
                  label="Add Document to Print Queue"
                  value={this.state.addToPrintQueue}
                  checked={this.state.addToPrintQueue}
                  onChange={() =>
                    this.setState(prevState => ({ addToPrintQueue: !prevState.addToPrintQueue }))
                  }
                />
              </div>
            )}
            {(requestType?.id === 3 || requestType?.id === 5) && (
              <div css={{ marginTop: 25 }}>
                <TextField
                  value={overridePayee}
                  onChange={e => this.setState({ overridePayee: e.target.value })}
                  label="Override Payee:"
                />
              </div>
            )}
            {(requestType?.id === 3 || requestType?.id === 5) && (
              <div css={{ marginTop: 8 }}>
                <TextArea
                  value={overrideAddress}
                  onChange={e => this.setState({ overrideAddress: e.target.value })}
                  label="Override Address:"
                />
              </div>
            )}
            {(requestType?.id === 3 || requestType?.id === 5 || requestType?.id === 13) && (
              <div css={{ marginTop: 25 }}>
                <CurrencyField
                  value={overrideFee}
                  onChange={v => this.setState({ overrideFee: v })}
                  label="Override Fee:"
                />
              </div>
            )}
            {documentType?.id === 6 && (
              <div>
                <div css={{ fontSize: 14, marginTop: 32 }}>Request Type</div>
                <div css={{ marginTop: 16 }}>
                  <Dropdown
                    value={this.state.townFeeDocSearchType}
                    width="350"
                    onClick={item => {
                      this.setState({ townFeeDocSearchType: item.name });
                    }}
                    itemList={[
                      { id: 1, name: 'Municipal Info (Town)' },
                      { id: 2, name: 'Municipal Info (In Person)' },
                    ]}
                  />
                </div>
                <div css={{ marginTop: 25 }}>
                  <CurrencyField
                    value={this.state.townFeeDocFee}
                    onChange={v => this.setState({ townFeeDocFee: v })}
                    label="Town Fee:"
                  />
                </div>
              </div>
            )}
            {documentType?.id !== 8 && (
              <div css={{ marginTop: 25 }}>
                <TextArea
                  value={note}
                  onChange={e => this.setState({ note: e.target.value })}
                  label="Note:"
                />
              </div>
            )}
            <div
              css={{
                marginTop: 80,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button secondary onClick={() => this.setState({ ...baseState })} disabled={createButtonStatus}>
                Cancel
              </Button>
              <Button
                onClick={this.onCreateClick}
                overRidestyle={{ marginLeft: 10 }}
                loadingStatus={createButtonStatus}
                disabled={!this.validate(false) || !this.validateSearchesAssignees(false)}
              >
                Create
              </Button>
            </div>
          </CustomModal>
        </div>
      </div>
    );
  }
}

function Error({ message }) {
  return <div css={{ color: colors.red }}>{message}</div>;
}

export const decodeLink = async (propertyId, link) => {
  const { data } = await axios.get(`/api/property/getProperty/${propertyId}`);
  const { county } = data;
  let region = '';
  if (data.state === 'New York') {
    if (isInNyc(county)) {
      region = 'nyc';
    } else {
      region = 'upstate';
    }
  }
  if (data.state === 'New Jersey') {
    region = 'nj';
  }
  const newString = (link || '').replace('{propertyId}', propertyId).replace('{region}', region);
  return newString;
};

export default withUser(withRouter(CreateDocument));
