import React, { useState } from 'react';
import produce from 'immer';
import orderBy from 'lodash.orderby';
import moment from 'moment';
import Select from 'react-select';
import Button from 'components/ui/Button';
import { Checkbox, Input, Loader, Table } from 'semantic-ui-react';
import { PermitInfo } from './types';
import { getSelectCustomStyles } from 'lib/PublicFunctions';
import { uniqBy } from 'lodash';
import DateInputPaste from 'components/ui/DateInputPaste';
import { ReducerAction } from './PermitInfoTable';
import { lastActions } from '../AlterationForm';
import CreatableSelect from 'react-select/creatable';
import colors from 'css/colors';

const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: '175px',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    border: state.isFocused ? '1px solid #ff5252' : `1px solid ${colors.grey}`,
    boxShadow: state.isFocused && '1px solid #ff5252',
    height: 38,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
  menuPortal: (provided: any, state: any) => ({
    ...provided,
    zIndex: 10000
  })
};

const TableRow = ({
  rowData,
  dispatch,
}: {
  rowData: PermitInfo;
  dispatch: React.Dispatch<ReducerAction>;
}) => {
  const [date, setDate] = useState(
    moment(rowData.filingDate).isValid() ? moment(rowData.filingDate).format('YYYY-MM-DD') : ''
  );
  const [description, setDescription] = useState(rowData.description || '');
  return (
    <Table.Row>
      <Table.Cell>
        <Checkbox
          checked={rowData.selected}
          onChange={(e, { checked }) => {
            dispatch({ type: 'UPDTATE_CHECKED', key: rowData.key });
          }}
        />
      </Table.Cell>
      <Table.Cell collapsing>
        <Input
          onChange={e =>
            dispatch({
              field: 'filingNumber',
              filterData: e.target.value,
              key: rowData.key,
              type: 'UPDATE_FIELD',
            })
          }
          value={rowData.filingNumber}
        />
      </Table.Cell>
      <Table.Cell collapsing>{rowData.jobType}</Table.Cell>
      <Table.Cell collapsing>
        <CreatableSelect
          onChange={e => {
            dispatch({
              type: 'UPDATE_FIELD',
              key: rowData.key,
              field: 'filingStatus',
              filterData: e.label,
            });
          }}
          value={
            lastActions.find(la => la.label === rowData.filingStatus) || {
              value: rowData.filingStatus,
              label: rowData.filingStatus,
            }
          }
          menuPosition="absolute"
          options={lastActions}
          styles={customStyles}
        />
      </Table.Cell>
      <Table.Cell collapsing>
        <DateInputPaste
          onBlur={date =>
            dispatch({
              type: 'UPDATE_FIELD',
              key: rowData.key,
              filterData: date,
              field: 'filingDate',
            })
          }
          onChange={date => setDate(date)}
          value={date}
        />
      </Table.Cell>
      <Table.Cell collapsing>
        <Input
          style={{ width: 2727 }}
          value={description}
          onChange={e => setDescription(e.target.value)}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
            dispatch({
              type: 'UPDATE_FIELD',
              key: rowData.key,
              filterData: e.target.value,
              field: 'description',
            })
          }
        />
      </Table.Cell>
      <Table.Cell collapsing>{rowData.table}</Table.Cell>
      <Table.Cell collapsing>{rowData.bin}</Table.Cell>
      <Table.Cell collapsing>{rowData.houseNumber}</Table.Cell>
      <Table.Cell collapsing>{rowData.streetName}</Table.Cell>
      <Table.Cell collapsing>{rowData.borough}</Table.Cell>
      <Table.Cell collapsing>{rowData.block}</Table.Cell>
      <Table.Cell collapsing>{rowData.lot}</Table.Cell>
    </Table.Row>
  );
};
export default TableRow;
