/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Button from 'components/ui/Button';
import MunicipalCheckNumbers from './MunicipalCheckNumbers';
import { Link } from 'react-router-dom';
import ErDisbursements from './ErDisbursements';
import Relocations from './Relocations';
import { Modal } from 'semantic-ui-react';
import EmailPopup from './EmailPopup';
import TpInvoice from './TpInvoice';
import JudicialInvoice from './JudicialInvoice';

const Wrapper = styled.div`
  margin-top: 40px;
`;
const UploadStyles = styled.div`
  font-weight: 700;
  font-size: 24px;
  margin-right: 32px;
  margin-bottom: 32px;
`;
const linkStyles = {
  alignItems: 'center',
  borderRadius: 5,
  display: 'inline-flex',
  fontWeight: 600,
  height: 48,
  justifyContent: 'center',
  outline: 'none',
  transition: 'all .2s',
  backgroundColor: '#fff',
  color: '#333',
  border: '1px solid #e5e5ea',
  width: 176,
  marginLeft: 24,
  marginRight: 24,
  '&:hover': {
    color: '#ff5252',
  },
};
const linkStyles2 = {
  alignItems: 'center',
  borderRadius: 5,
  display: 'inline-flex',
  fontWeight: 600,
  height: 48,
  justifyContent: 'center',
  outline: 'none',
  transition: 'all .2s',
  backgroundColor: '#fff',
  color: '#333',
  border: '1px solid #e5e5ea',
  width: 200,
  marginRight: 24,
  '&:hover': {
    color: '#ff5252',
  },
};
const UploadCenter = () => {
  const [openMunicipalCheck, setOpenMunicipalCheck] = useState(false);
  const [openErDisbursments, setOpenErDisbursements] = useState(false);
  const [openRelocations, setOpenRelocations] = useState(false);
  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const [openTpInvoice, setOpenTpInvoice] = useState(false);
  const [openJudicialInvoice, setOpenJudicialInvoice] = useState(false);
  const [emailMessage, setEmailMessage] = useState();
  return (
    <Wrapper>
      <UploadStyles>Upload Center</UploadStyles>
      <div style={{ display: 'flex' }}>
        <Button secondary large onClick={() => setOpenMunicipalCheck(true)} overRidestyle={{ width: 200 }}>
          Municipal Check Numbers
        </Button>
        <Link css={linkStyles} to={'/abstract-uploads'}>
          Abstract Uploads
        </Link>
        <Button secondary large onClick={() => setOpenErDisbursements(true)} overRidestyle={linkStyles2}>
          ER Disbursement Receipts
        </Button>
        <Button secondary large onClick={() => setOpenRelocations(true)} overRidestyle={{ width: 200 }}>
          Relocations
        </Button>
        <Button
          secondary
          large
          onClick={() => setOpenTpInvoice(true)}
          overRidestyle={{ width: 200, marginLeft: 24 }}
        >
          Upload TrackerPro Invoice
        </Button>
        <Link css={linkStyles} to={'/upload-contin'}>
          Upload Contin
        </Link>
        <Button
          secondary
          large
          onClick={() => setOpenJudicialInvoice(true)}
          overRidestyle={{ width: 200 }}
        >
          Judicial Invoice
        </Button>
      </div>
      <MunicipalCheckNumbers onClose={() => setOpenMunicipalCheck(false)} modalOpen={openMunicipalCheck} />
      <ErDisbursements
        onClose={() => setOpenErDisbursements(false)}
        modalOpen={openErDisbursments}
        setShowEmailModal={setShowEmailPopup}
      />
      <Relocations
        onClose={() => setOpenRelocations(false)}
        modalOpen={openRelocations}
        setShowEmailModal={setShowEmailPopup}
      />
      <TpInvoice
        onClose={() => setOpenTpInvoice(false)}
        modalOpen={openTpInvoice}
        setShowEmailModal={setShowEmailPopup}
        setMessage={setEmailMessage}
      />
      <JudicialInvoice
        onClose={() => setOpenJudicialInvoice(false)}
        modalOpen={openJudicialInvoice}
        setShowEmailModal={setShowEmailPopup}
        setMessage={setEmailMessage}
      />
      {showEmailPopup && (
        <EmailPopup
          message={emailMessage}
          showEmailPopup={showEmailPopup}
          setShowEmailPopup={setShowEmailPopup}
        />
      )}
    </Wrapper>
  );
};

export default UploadCenter;
