import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Dropdown, Input, Loader } from 'semantic-ui-react';
import axios from 'axios';
import moment from 'moment';
import qs from 'qs';
import { ArrowleftIcon } from 'components/ui/icons';

const formatDate = (date: string) => moment(date).format('M/D/YYYY');

const FilterWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const FilterItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 160px;
`;

const SubmitButton = styled.button`
  width: 128px;
  height: 40px;
  background: #ff5252;
  border-radius: 5px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  margin-top: 21px;
`;

interface DropdownOption {
  text: string;
  value: number;
  key: number;
}

const mapToDropDownOptions = (arr: any, textField: string, idField: string): DropdownOption[] => {
  return arr.map((a: any) => ({ value: a[idField], text: a[textField], key: a[idField] }));
};

interface PropTypes {
  setReqString: Dispatch<SetStateAction<string>>;
  loading: boolean;
  onSubmit: (queryString: string) => void;
}

const NjFilters = (props: PropTypes) => {
  const [assignees, setAssignees] = useState<DropdownOption[]>([]);
  const [statuses, setStatuses] = useState<DropdownOption[]>([]);
  const [fromDate, setFromDate] = useState<string>(moment().subtract(364, 'days').format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState<string>(moment().format('YYYY-MM-DD'));
  const [assigneeFilter, setAssigneeFilter] = useState<number[]>([]);
  const [statusesFilter, setStatusesFilter] = useState<number[]>([]);
  const [excludedAssigneesFilter, setExcludedAssigneesFilter] = useState<number[]>([]);
  const [excludedStatuses, setExcludedStatuses] = useState<number[]>([]);

  useEffect(() => {
    axios.get(`/api/users/forassignment`).then(({ data }) => {
      setAssignees(mapToDropDownOptions(data, 'name', 'id'));
    });
    axios.get(`/api/reports/getSearchStatuses`).then(({ data }) => {
      setStatuses(mapToDropDownOptions(data.concat([{status: 'Proofing', id: 5}]), 'status', 'id'));
    });
  }, []);

  const handleSubmit = async () => {
    const queryString = qs.stringify({
      fromDate,
      toDate,
      assignees: assigneeFilter,
      statuses: statusesFilter,
      excludedAssignees: excludedAssigneesFilter,
      excludedStatuses
    });
    props.setReqString(queryString);
    props.onSubmit(queryString);
  };

  return (
    <FilterWrapper>
      <FilterItem>
        <label>From</label>
        <Input
          style={{ width: 170, height: 40 }}
          value={fromDate}
          type="date"
          onChange={e => setFromDate(e.target.value)}
          fluid
        />
      </FilterItem>
      <FilterItem>
        <label>To</label>
        <Input
          style={{ width: 170, height: 40 }}
          type="date"
          value={toDate}
          onChange={e => setToDate(e.target.value)}
          fluid
        />
      </FilterItem>
      <FilterItem>
        <label>Assignee</label>
        <Dropdown
          multiple
          selection
          search
          placeholder="Assignee"
          options={assignees}
          onChange={(e, { value }) => setAssigneeFilter(value as number[])}
          closeOnChange
          compact
        />
      </FilterItem>
      <FilterItem>
        <label>Exclude Assignee</label>
        <Dropdown
          multiple
          selection
          search
          placeholder="Exclude Assignee"
          options={assignees}
          onChange={(e, { value }) => setExcludedAssigneesFilter(value as number[])}
          closeOnChange
          compact
        />
      </FilterItem>
      <FilterItem>
        <label>Status</label>
        <Dropdown
          multiple
          selection
          search
          placeholder="Status"
          options={statuses}
          onChange={(e, { value }) => setStatusesFilter(value as number[])}
          closeOnChange
          compact
        />
      </FilterItem>
      <FilterItem>
          <label>Ex Status</label>
          <Dropdown
            value={excludedStatuses}
            multiple
            selection
            search
            placeholder="Ex Status"
            options={statuses}
            onChange={(e, { value }) => setExcludedStatuses(value as number[])}
            closeOnChange
            compact
          />
        </FilterItem>
      <FilterItem>
        <label />
        <SubmitButton onClick={handleSubmit}>
          {props.loading ? <Loader active inline size="small" /> : 'Submit'}
        </SubmitButton>
      </FilterItem>
    </FilterWrapper>
  );
};

export default NjFilters;
