import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Table, Input, Loader } from 'semantic-ui-react';
import styled from '@emotion/styled';
import moment from 'moment';
import axios from 'axios';
import { QbLineItem } from './types';
import _ from 'lodash';
import convertToFileDownload from '../../FileDownloads';
import qs from 'qs';
import { CheckIcon, OutlookNotesIcon } from '../../ui/icons';
import { Link } from 'react-router-dom';
import QbPricingReport from './pricingReport';
import { FixedHeaderStyle } from 'css/fixedHeaderStyle';

const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FilterItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const Wizzywig = styled.div`
  li,
  ul,
  ol {
    margin-left: 15px !important;
    list-style: revert;
  }
  strong {
    font-weight: revert;
  }
  img {
    max-width: 350px !important;
    max-height: 345px !important;
    width: auto;
    height: auto;
    display: block;
    margin: 25px;
  }
`;

interface DropdownOption {
  text: string;
  value: number;
  key: number;
}

const mapToDropDownOptions = (arr: any, textField: string, idField: string): DropdownOption[] => {
  return arr.map((a: any) => ({ value: a[idField], text: a[textField], key: a[idField] }));
};

type Direction = 'ascending' | 'descending' | null;

type ColumnNames = keyof QbLineItem;

interface SortState {
  column: string;
  direction: Direction;
}

const QbBllingReport = () => {
  const [fromDate, setFromDate] = useState<string>(moment().subtract(2, 'weeks').format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState<string>(moment().format('YYYY-MM-DD'));
  const [clients, setClients] = useState<DropdownOption[]>([]);
  const [timeFrameClients, setTimeFrameClients] = useState<number>(1);
  const [clientFilter, setClientFilter] = useState<number[]>([]);
  const [orderFilter, setOrderFilter] = useState<string>();
  const [lineItems, setLineItems] = useState<QbLineItem[]>([]);
  const [showPricingReport, setShowPricingReport] = useState(false);
  const [pricingReport, setPricingReport] = useState([]);
  const [loading, setLoading] = useState(false);

  function sortReducer(state: SortState, action: { type: string; column?: ColumnNames }) {
    switch (action.type) {
      case 'CHANGE_SORT':
        if (state.column === action.column) {
          const data = lineItems.slice().reverse();
          setLineItems(data);
          return {
            ...state,
            direction: state.direction === 'ascending' ? 'descending' : 'ascending',
          } as SortState;
        } else {
          const data = _.sortBy(lineItems, [action.column, 'hdsnumber']);
          setLineItems(data);
          return {
            column: action.column,
            direction: 'ascending',
          } as SortState;
        }
      default:
        throw new Error();
    }
  }
  const [state, dispatch] = React.useReducer(sortReducer, {
    column: 'clientName',
    direction: 'ascending',
  });

  useEffect(() => {
    axios.get('/api/clients/getclientnames').then(({ data }) => {
      setClients(mapToDropDownOptions(data, 'name', 'id'));
    });
  }, []);

  const getQbBillingReport = async () => {
    setLoading(true);
    setShowPricingReport(false);
    const queryString = qs.stringify({
      orderFilter,
      fromDate,
      toDate,
      timeFrame: clientFilter.length > 0 || orderFilter?.length > 0 ? null : timeFrameClients,
    });
    axios.post(`/api/reports/qbBillingReport?${queryString}`, clientFilter).then(({ data }) => {
      setLineItems(data);
      setLoading(false);
    });
  };

  const getQbPricingReport = async () => {
    setLoading(true);
    const queryString = qs.stringify({ fromDate, toDate });
    const report = await axios.get(`/api/billing/GetQbPricingReport?${queryString}`);
    const download = await axios.post(`/api/billing/downloadQbPricingReport?${queryString}`, report.data);
    setLoading(false);
    setPricingReport(report.data);
    setShowPricingReport(true);
    await convertToFileDownload(download.data.fileBase64, download.data.fileName);
  };

  const downloadCsv = async () => {
    setLoading(true);
    const queryString = qs.stringify({
      orderFilter,
      fromDate,
      toDate,
      timeFrame: clientFilter.length > 0 || orderFilter?.length > 0 ? null : timeFrameClients,
    });
    axios
      .post(`/api/reports/qbBillingReport?${queryString}`, clientFilter)
      .then(async ({ data: lineItems }) => {
        setLineItems(lineItems);
        const { data } = await axios.post(`/api/billing/billorders`, lineItems);
        await convertToFileDownload(data.base64, data.fileName);
        setLoading(false);
      });
  };

  const selectClient = (clientIds: number[]) => {
    const closingClientsIds = clientIds.some(
      id => clients.find(c => c.value === id)?.text === 'Closing USA, LLC'
    )
      ? clients.filter(c => c.text.includes('Closing USA')).map(c => c.value)
      : [];
    const benchmarkClientsIds = clientIds.some(
      id => clients.find(c => c.value === id)?.text === 'Benchmark Title Agency, LLC'
    )
      ? clients.filter(c => c.text.includes('Benchmark Title Agency, LLC')).map(c => c.value)
      : [];
   
    const trueNorthClientIds = clientIds.some(id =>
      clients
        .find(c => c.value === id)
        ?.text.toLowerCase()
        .includes('true north abstract')
    )
      ? clients.filter(c => c.text.toLowerCase().includes('true north abstract')).map(c => c.value)
      : [];
    const madisonNjClientIds = clientIds.some(id => {
      var clientText = clients.find(c => c.value === id)?.text.toLowerCase();
      if (!clientText) {
        return false;
      }
      return (
        clientText.includes('madison title agency, llc - nj') ||
        clientText.includes('madison title – nj commercial')
      );
    })
      ? clients
          .filter(
            c =>
              c.text.toLowerCase().includes('madison title – nj commercial') ||
              c.text.toLowerCase().includes('madison title agency, llc - nj')
          )
          .map(c => c.value)
      : [];

    const selectedClientIds = [
      ...clientIds,
      ...closingClientsIds,
      ...benchmarkClientsIds,
      ...trueNorthClientIds,
      ...madisonNjClientIds,
    ];

    const distinctClientIds = [...new Set(selectedClientIds)];
    setClientFilter(distinctClientIds);
  };

  function onlyUnique(value: any, index: any, self: any) {
    return self.indexOf(value) === index;
  }

  return (
    <>
      <h1 style={{ marginBottom: 16 }}>QB Billing Report</h1>
      <FilterWrapper>
        <div style={{ display: 'flex', gap: 16 }}>
          <FilterItem>
            <Input
              style={{ width: 170, height: 40 }}
              value={fromDate}
              type="date"
              onChange={e => setFromDate(e.target.value)}
              fluid
            />
          </FilterItem>
          <div style={{ height: 40, paddingTop: 10 }}>To</div>
          <FilterItem>
            <Input
              style={{ width: 170, height: 40 }}
              type="date"
              value={toDate}
              onChange={e => setToDate(e.target.value)}
              fluid
            />
          </FilterItem>
          <FilterItem>
            <div style={{ width: 350 }}>
              <Dropdown
                multiple
                selection
                search
                placeholder="Client"
                options={clients}
                value={clientFilter}
                onChange={(e, { value }) => selectClient(value as number[])}
                closeOnChange
                fluid
                clearable
              />
            </div>
          </FilterItem>
          <FilterItem>
            <Input
              placeholder="Search HDS/Title"
              value={orderFilter}
              onChange={e => setOrderFilter(e.target.value)}
            />
          </FilterItem>
          <FilterItem>
            <div style={{ width: 150 }}>
              <Dropdown
                options={[
                  { value: 1, text: 'Bi-Weekly', key: 'Bi-Weekly' },
                  { value: 2, text: 'Monthly', key: 'Monthly' },
                ]}
                disabled={clientFilter.length > 0 || orderFilter?.length > 0}
                placeholder="Billing Period"
                value={timeFrameClients}
                selection
                search
                fluid
                onChange={(e, { value }) => {
                  setTimeFrameClients(value as number);
                  if (value === 2) {
                    setFromDate(moment().subtract(4, 'weeks').format('YYYY-MM-DD'));
                  } else {
                    setFromDate(moment().subtract(2, 'weeks').format('YYYY-MM-DD'));
                  }
                }}
              />
            </div>
          </FilterItem>

          <FilterItem>
            <Button content="Search" onClick={() => getQbBillingReport()} />
          </FilterItem>
        </div>
        <div style={{ display: 'flex', gap: 16 }}>
          <FilterItem>
            <Button content="Download" onClick={() => downloadCsv()} />
          </FilterItem>
          <FilterItem>
            <Button content="Download Client Pricing" onClick={() => getQbPricingReport()} />
          </FilterItem>
        </div>
      </FilterWrapper>
      {loading && <Loader active size="large" />}
      {!loading && !showPricingReport && (
        <Table size="small" compact className="tableSeven" sortable>
          <Table.Header
            style={FixedHeaderStyle}
          >
            <Table.Row>
              <Table.HeaderCell
                sorted={state.column === 'clientName' ? state.direction : null}
                onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'clientName' })}
              >
                Client
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={state.column === 'hdsNumber' ? state.direction : null}
                onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'hdsNumber' })}
              >
                Hds
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={state.column === 'titleNumber' ? state.direction : null}
                onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'titleNumber' })}
              >
                Title
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={state.column === 'sbl' ? state.direction : null}
                onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'sbl' })}
              >
                SBL
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={state.column === 'feeType' ? state.direction : null}
                onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'feeType' })}
              >
                Fee For
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={state.column === 'continOrUpdateNumber' ? state.direction : null}
                onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'continOrUpdateNumber' })}
              >
                Contin #
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={state.column === 'rate' ? state.direction : null}
                onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'rate' })}
              >
                Rate
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={state.column === 'quantity' ? state.direction : null}
                onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'quantity' })}
              >
                Quantity
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={state.column === 'price' ? state.direction : null}
                onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'price' })}
              >
                Price
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={state.column === 'hasNote' ? state.direction : null}
                onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'hasNote' })}
              >
                Billing Note
              </Table.HeaderCell>{' '}
              <Table.HeaderCell
                sorted={state.column === 'isBilled' ? state.direction : null}
                onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'isBilled' })}
              >
                Billed
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {lineItems.map((lineItem, idx) => {
              return (
                <>
                  <Table.Row key={idx}>
                    <Table.Cell>{lineItem.clientName}</Table.Cell>
                    <Table.Cell>
                      <Link
                        to={`/orders/order-information/${lineItem.hdsNumber}/${lineItem.propertyId}`}
                        target="_blank"
                      >
                        {lineItem.hdsNumber}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>{lineItem.titleNumber}</Table.Cell>
                    <Table.Cell>{lineItem.sbl}</Table.Cell>
                    <Table.Cell>{lineItem.feeType}</Table.Cell>
                    <Table.Cell>{lineItem.continOrUpdateNumber}</Table.Cell>
                    <Table.Cell textAlign="right">{`$${lineItem.rate.toFixed(2)}`}</Table.Cell>
                    <Table.Cell textAlign="right">{lineItem.quantity}</Table.Cell>
                    <Table.Cell textAlign="right">{`$${lineItem.price.toFixed(2)}`}</Table.Cell>
                    <Table.Cell textAlign="center">
                      {lineItem.note != null ? <OutlookNotesIcon /> : ''}
                    </Table.Cell>
                    <Table.Cell textAlign="center">{lineItem.isBilled ? <CheckIcon /> : ''}</Table.Cell>
                  </Table.Row>
                  {lineItem.note && (
                    <Wizzywig dangerouslySetInnerHTML={{ __html: `<body>${lineItem.note}</body>` }} />
                  )}
                </>
              );
            })}
          </Table.Body>
        </Table>
      )}
      {showPricingReport && <QbPricingReport report={pricingReport} />}
    </>
  );
};

export default QbBllingReport;
