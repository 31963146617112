/** @jsx jsx */
import { jsx } from '@emotion/core';
import axios from 'axios';
import React, { useState, useRef, useEffect } from 'react';
import produce from 'immer';
import _ from 'lodash';
import { FaStar } from "react-icons/fa6";
import { DatePicker } from 'react-widgets';
import { Link } from 'react-router-dom';
import moment from 'moment';
import styled from '@emotion/styled';
import { Grid, GridColumn, Table, Icon, Popup, List, Checkbox, Dropdown } from 'semantic-ui-react';
import ReactToPrint from 'react-to-print';
import GridRow from 'semantic-ui-react/dist/commonjs/collections/Grid/GridRow';
import qs from 'qs';
import Select from 'react-select';

import 'semantic-ui-css/semantic.min.css';
import ReportAssignModal from './ReportAssignModal';
import ReportUnassignModal from './ReportUnassignModal';
import CurrentAssignment from './CurrentAssignment';
import { CheckIcon } from '../ui/icons';
import Button from '../ui/Button';
import colors from '../../css/colors';
import SearchTypeDropdown from '../ui/SearchTypeDropdown';
import SaveSearchesDropdown from './SaveSearchesDropdown';
import KeyPressAssign from './KeyPressAssign';

const fromStyles = {
  width: 300,
  display: 'inline-block',
};
const toStyles = {
  width: 300,
  marginLeft: 16,
  display: 'inline-block',
};
const totalResults = {
  display: 'none',
  '@media print': {
    display: 'block',
  },
};
const rushIcon = {
  color: colors.redActive,
  '&:hover': {
    color: colors.redLight,
    cursor: 'pointer',
  },
  '@media print': {
    display: 'none',
  },
};
const superRushIcon = {
  color: '#c50f2a',
  '&:hover': {
    color: 'red',
    cursor: 'pointer',
  },
  '@media print': {
    display: 'none',
  },
};
const priorityIcon = {
  color: colors.redActive,
  '&:hover': {
    color: colors.redLight,
    cursor: 'pointer',
  },
  '@media print': {
    display: 'none',
  },
};

const noPrint = {
  '@media print': {
    display: 'none',
  },
};

const hoverStylesWrap = {
  padding: 32,
  '.hover_overrides': {
    cursor: 'pointer',
    '&:hover': {
      borderBottom: '1px solid #ff5252',
    },
  },
};

const getInitials = str => {
  if (!str) return;

  return str
    .split(' ')
    .map(n => n[0].toUpperCase())
    .join('.');
};

const CurrentAssignWrapper = styled.div`
  margin-bottom: 24px;
  margin-top: 24px;
`;

const locations = [
  { id: 0, text: 'All', value: 0 },
  { id: 1, text: 'NYS', value: 1 },
  { id: 2, text: 'NYC', value: 2 },
  { id: 3, text: 'NJ', value: 3 },
];

export default function DataEntryReport() {
  const [reportData, setReportData] = useState([]);
  const [showReport, setShowReport] = useState(false);
  const [fromDate, setFromDate] = useState(new Date(moment().subtract(1, 'years').format()));
  const [toDate, setToDate] = useState(new Date());
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [selectedSearchType, setSelectedSearchtype] = useState(null);
  const [assignModal, setAssignModal] = useState(false);
  const [unassignModal, setUnassignModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [searchesToAssign, setSearchestoAssign] = useState([]);
  const [loading, setLoading] = useState(false);
  const [taxOnly, setTaxOnly] = useState(false);
  const [state, dispatch] = React.useReducer(sortReducer, {
    column: '',
    direction: '',
  });
  const [selectedUser, setSelectedUser] = useState('');
  const [searchTypeName, setSearchTypeName] = useState('');

  const formatDate = date => moment(date).format('M/D/YYYY');

  function sortReducer(state, action) {
    switch (action.type) {
      case 'CHANGE_SORT':
        if (state.column === action.column) {
          var newReportData = _.orderBy(
            [...reportData],
            [action.column],
            [state.direction === 'ascending' ? 'desc' : 'asc']
          );
          setReportData(newReportData);
          return {
            ...state,
            direction: state.direction === 'ascending' ? 'descending' : 'ascending',
          };
        } else {
          const data = _.sortBy(reportData, [action.column]);
          setReportData(data);
          return {
            column: action.column,
            direction: 'ascending',
          };
        }
      default:
        throw new Error();
    }
  }

  const onClickAssignment = name => {
    if (selectedUser === name) {
      setSelectedUser('');
      handleSubmitClick('');
    } else {
      setSelectedUser(name);
      handleSubmitClick(name);
    }
  };

  useEffect(() => {
    axios.get(`/api/clients/getclientnames`).then(({ data }) => {
      setClients(data);
    });
  }, []);

  const updateOrder = (order, updateRush, updateSuperRush) => {
    axios.post(`/api/orders/updategeneralinfo/${order.orderId}`, {
      clientId: order.clientId,
      titleNumber: order.titleNumber,
      isRush: updateRush ? true : order.isRush,
      isSuperRush: updateSuperRush ? true : order.isSuperRush,
    });

    setReportData(rd =>
      rd.map(data =>
        data.orderId === order.orderId
          ? {
              ...data,
              isRush: updateRush ? true : data.isRush,
              isSuperRush: updateSuperRush ? true : data.isSuperRush,
            }
          : data
      )
    );
  };

  //if more priority levels are added, '1' can't be hard coded like this
  const updatePriority = async order => {
    await axios.post(`/api/searches/UpdatePriorityLevel/${order.orderId}/1`);
    setReportData(rd =>
      rd.map(data =>
        data.orderId === order.orderId
          ? {
              ...data,
              priorityLevelId: 1,
              priorityLevel: 'Priority',
            }
          : data
      )
    );
  };

  const filesUploadedBy = reportData => {
    const names = reportData.reduce((pre, cur) => {
      if (!pre.includes(cur.uploadedBy)) return pre.concat([cur.uploadedBy]);
      return pre;
    }, []);

    return (
      <React.Fragment>
        {names.map((n, i) => (
          <List divided relaxed key={i}>
            <List.Item>
              <List.Content>
                <List.Header>{n}</List.Header>
                <List.Description>{`${
                  reportData.filter(r => r.uploadedBy === n).length
                } Files Uploaded`}</List.Description>
              </List.Content>
            </List.Item>
          </List>
        ))}
      </React.Fragment>
    );
  };

  async function handleSubmitClick(name = '', filtersDict, isFiltered) {
    setLoading(true);
    const queryString = qs.stringify({
      from: formatDate(fromDate),
      to: formatDate(toDate),
      selectedClient: isFiltered ? filtersDict?.client : selectedClient,
      taxOnly,
      selectedSearchType: isFiltered ? filtersDict?.searchType : selectedSearchType,
      selectedUser: name,
      location: isFiltered ? filtersDict?.location : selectedLocation
    });
    const { data } = await axios.get(`/api/reports/dataentryreport?${queryString}`);
    setReportData(data);

    setLoading(false);
    setShowReport(true);
  }

  const onAssign = () => {
    handleSubmitClick();
    setSearchestoAssign([]);
    setAssignModal(false);
    setUnassignModal(false);
  };

  const handleCheckChange = (searchId, idx) => (e, { checked }) => {
    if (e.shiftKey && checked && searchesToAssign.length > 0) {
      const lastChecked = searchesToAssign[searchesToAssign.length - 1];
      const reportDataIndexOfLastChecked = reportData.findIndex(r => r.searchId === lastChecked);
      const searchIds = reportData
        .filter((r, i) => {
          if (idx > reportDataIndexOfLastChecked) {
            return i > reportDataIndexOfLastChecked && i <= idx;
          } else {
            return i >= idx && i < reportDataIndexOfLastChecked;
          }
        })
        .map(r => r.searchId);
      const newSearchewstoAssign = [...searchesToAssign].concat(searchIds);
      setSearchestoAssign([...new Set(newSearchewstoAssign)]);
    } else {
      setSearchestoAssign(searches =>
        checked ? searches.concat([searchId]) : searches.filter(s => s !== searchId)
      );
    }
  };

  const tableRef = useRef(null);
  const setLocation = data => {
    if (selectedLocation === 1) {
      return data.filter(
        d =>
          !d.state ||
          ((d.municipalityTypeId === 1 || d.municipalityTypeId === 2 || d.municipalityTypeId === 3) &&
            d.state !== 'NJ')
      );
    } else if (selectedLocation === 2) {
      return data.filter(d => !d.state || (d.municipalityTypeId === 4 && d.state !== 'NJ'));
    } else if (selectedLocation === 3) {
      return data.filter(d => !d.state || d.state === 'NJ');
    } else {
      return data;
    }
  };

  const renderResults = () => {
    return (
      <Table size="small" compact className="tableTwelve" sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell css={noPrint} />
            <Table.HeaderCell css={noPrint} />
            <Table.HeaderCell
              sorted={state.column === 'clientName' ? state.direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'clientName' })}
            >
              Client
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={state.column === 'orderId' ? state.direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'orderId' })}
            >
              Hds#
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={state.column === 'titleNumber' ? state.direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'titleNumber' })}
            >
              Title Number
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={state.column === 'orderDate' ? state.direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'orderDate' })}
            >
              Order Date
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={state.column === 'uploadDate' ? state.direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'uploadDate' })}
            >
              Upload date
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={state.column === 'fileUploaded' ? state.direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'fileUploaded' })}
            >
              File Uploaded
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={state.column === 'initials' ? state.direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'initials' })}
            >
              By
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={state.column === 'assignedTo' ? state.direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'assignedTo' })}
            >
              Assigned To
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={state.column === 'continOrUpdateNumber' ? state.direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'continOrUpdateNumber' })}
            >
              Is Contin
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={state.column === 'isSuperRush' ? state.direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'priorityLevelId' })}
            >
              Rush Status
            </Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell />
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {reportData
            .filter(rd => rd['assignedTo'] === selectedUser || selectedUser === '')
            .map((d, idx) => {
              return (
                <Table.Row key={d.id}>
                  <Table.Cell css={noPrint} collapsing>
                    <Checkbox
                      checked={searchesToAssign.includes(d.searchId)}
                      onChange={handleCheckChange(d.searchId, idx)}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    {d.isLocked && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-lock"
                      >
                        <path
                          d="M13.0401 7.2793H2.96046C2.1652 7.2793 1.52051 7.92399 1.52051 8.71925V13.7591C1.52051 14.5543 2.1652 15.199 2.96046 15.199H13.0401C13.8354 15.199 14.4801 14.5543 14.4801 13.7591V8.71925C14.4801 7.92399 13.8354 7.2793 13.0401 7.2793Z"
                          stroke="#333333"
                          strokeWidth="1.43995"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M4.40039 7.28057V4.40066C4.40039 3.44592 4.77966 2.53027 5.45477 1.85516C6.12988 1.18005 7.04553 0.800781 8.00027 0.800781C8.95502 0.800781 9.87067 1.18005 10.5458 1.85516C11.2209 2.53027 11.6002 3.44592 11.6002 4.40066V7.28057"
                          stroke="#333333"
                          strokeWidth="1.43995"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                  </Table.Cell>
                  <Table.Cell>{d.isPriorityClient && <FaStar color='red' />}{d['clientName']}</Table.Cell>
                  <Table.Cell>{`HDS${d['orderId']}`}</Table.Cell>
                  <Table.Cell>
                    {' '}
                    <Link
                      to={{
                        pathname: `/orders/order-information/${d.orderId}/${d.propertyId}`,
                      }}
                      target="none"
                    >
                      {d['titleNumber']}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>{formatDate(d['orderDate'])}</Table.Cell>
                  <Table.Cell>{formatDate(d['uploadDate'])}</Table.Cell>
                  <Table.Cell>{d['fileUploaded']}</Table.Cell>
                  <Table.Cell>{d.initials}</Table.Cell>
                  <Table.Cell>{d['assignedTo'] || 'Unassigned'}</Table.Cell>
                  <Table.Cell textAlign="center">{d.continOrUpdateNumber > 0 && <CheckIcon />}</Table.Cell>
                  <Table.Cell>
                    {d.priorityLevelId > 0
                      ? d.priorityLevel
                      : d.isSuperRush
                      ? 'Super Rush'
                      : d.isRush
                      ? 'Rush'
                      : ''}
                  </Table.Cell>
                  <Table.Cell css={!d.isRush ? rushIcon : {}}>
                    {!d.isRush && !d.isSuperRush ? (
                      <Popup
                        content="Mark Rush"
                        trigger={
                          <Icon
                            name="lightning"
                            circular
                            onClick={() => {
                              if (d.isRush) return;
                              updateOrder(d, true, false);
                            }}
                          />
                        }
                      ></Popup>
                    ) : (
                      ''
                    )}
                  </Table.Cell>
                  <Table.Cell css={!d.isSuperRush ? superRushIcon : {}}>
                    {!d.isSuperRush ? (
                      <Popup
                        content="Mark Super Rush"
                        trigger={
                          <Icon
                            name="warning"
                            circular
                            onClick={() => {
                              if (d.isSuperRush) return;
                              updateOrder(d, false, true);
                            }}
                          />
                        }
                      ></Popup>
                    ) : (
                      ''
                    )}
                  </Table.Cell>
                  <Table.Cell css={d.priorityLevelId === 0 ? priorityIcon : {}}>
                    {d.priorityLevelId === 0 ? (
                      <Popup
                        content="Mark Priority"
                        trigger={
                          <Icon
                            name="bell"
                            circular
                            onClick={() => {
                              updatePriority(d);
                            }}
                          />
                        }
                      ></Popup>
                    ) : (
                      ''
                    )}
                  </Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
    );
  };

  const formatFilters = () => {
    const filtersDict = {
      client: clients.find(c => c.id === selectedClient),
      searchType: searchTypeName ? { label: searchTypeName, value: selectedSearchType } : undefined,
      location: locations.find(l => l.id === selectedLocation),
      selectedUser: selectedUser,
    };

    const entries = Object.entries(filtersDict).filter(o => {
      if (Array.isArray(o[1]) && o[1].length > 0) {
        return true;
      } else if (!Array.isArray(o[1]) && o[1]) {
        return true;
      }
      return false;
    });
    return entries;
  };
  const handleFilter = (filterId, usersFilters) => {
    const filtersDict = {
      client: null,
      searchType: null,
      location: null,
      selectedUser,
    };
    if (!filterId) {
      handleSubmitClick('', filtersDict, true);
      setSelectedClient(null);
      setSelectedSearchtype(null);
      setSearchTypeName('');
      setSelectedLocation(null);
      setSelectedUser('')
      return;
    }
    const filter = usersFilters.find(f => f.id === filterId);
    const jsonObj = JSON.parse(filter.stringifiedFilters);

    for (const filter of jsonObj) {
      filtersDict[filter[0]] = filter[1];
    }

    filtersDict.client = filtersDict.client?.id;
    setSelectedSearchtype(filtersDict.searchType?.value);
    setSearchTypeName(filtersDict.searchType?.label);
    filtersDict.searchType = filtersDict.searchType?.value;
    setSelectedLocation(filtersDict.location?.value);
    filtersDict.location = filtersDict.location?.id;
    setSelectedClient(filtersDict.client)
    handleSubmitClick(filtersDict.selectedUser, filtersDict, true);
  };

  return (
    <div css={hoverStylesWrap}>
      <KeyPressAssign
        searches={searchesToAssign}
        onAssign={onAssign}
      />
      <div className="report">
        <style>
          {`.report {padding: 15px} .totalResults {display: block;} .tableTwelve td {max-width: 160px;overflow: hidden; text-overflow: ellipsis;white-space: nowrap; padding: .2em .3em !important;}`}
        </style>
        <h1 css={{ marginBottom: 16 }}>Data Entry Report</h1>
        <CurrentAssignWrapper>
          <CurrentAssignment
            selectedUser={selectedUser}
            onClick={onClickAssignment}
            searchStatus="Data Entry"
            orders={reportData}
          />
        </CurrentAssignWrapper>
        <Grid>
          <GridRow columns={2}>
            <GridColumn width={8}>
              <div style={fromStyles}>
                <label>From</label>
                <DatePicker format="M/D/YYYY" value={fromDate} time={false} onChange={e => setFromDate(e)} />
              </div>
              <div style={toStyles}>
                <label>To</label>
                <DatePicker format="M/D/YYYY" value={toDate} time={false} onChange={e => setToDate(e)} />
              </div>
            </GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn width={4}>
              <Select
                options={clients.map(s => ({
                  label: `${s.name}`,
                  value: s.id,
                }))}
                value={clients.map((c) => ({value: c.id, label: c.name})).find(c => c.value === selectedClient)}
                styles={{ control: styles => ({ ...styles, height: 48 }) }}
                onChange={selection => {
                  setSelectedClient(selection?.value || '');
                }}
                placeholder="Select Client"
                isClearable={true}
              />
            </GridColumn>
            <Grid.Column width={4}>
              <SearchTypeDropdown
                style={{ height: 42, width: '100%' }}
                setSelectedSearchType={setSelectedSearchtype}
                selectedSearchType={selectedSearchType}
                placeholder="Filter Search Category"
                setSearchTypeName={e => setSearchTypeName(e)}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <Dropdown
                style={{ height: 42, minWidth: '13em' }}
                options={locations}
                onChange={(__, { value }) => setSelectedLocation(value)}
                placeholder="Select Location"
                clearable
                selection
                value={selectedLocation}
              />
            </Grid.Column>
          </GridRow>
          <GridRow>
            <Grid.Column width={6}>
              <div>
                <SaveSearchesDropdown
                  formatFilters={formatFilters}
                  reportId={4}
                  handleFilter={handleFilter}
                  // padding={11}
                />
              </div>
            </Grid.Column>
          </GridRow>
          <GridRow>
            <span
              css={{
                marginLeft: 16,
                verticalAlign: 'bottom',
                textAlign: 'right',
              }}
            >
              <Button loadingStatus={loading} onClick={() => handleSubmitClick()}>
                Submit
              </Button>
            </span>
          </GridRow>
        </Grid>
        {showReport && !reportData.length && (
          <Grid>
            <GridRow>No items are in Data Entry.</GridRow>
          </Grid>
        )}
        {Boolean(showReport && reportData.length) && (
          <React.Fragment>
            <Grid style={{ marginTop: 15 }}>
              <GridRow colums={2}>
                <Grid.Column width={6}>
                  <ReactToPrint
                    trigger={() => <Button secondary>Print</Button>}
                    content={() => tableRef.current}
                  />
                </Grid.Column>
                {/* <Grid.Column width={3}>
                  <label>Sort By</label>
                  <Select options={sortingOptions} value={sortBy} onChange={e => setSortBy(e)} />
                </Grid.Column> */}
              </GridRow>
              <GridRow>
                <GridColumn width={2}>
                  <div style={{ display: 'flex', gap: 10, flexDirection: 'column' }}>
                    <div>{`Total Items: ${reportData.length}`}</div>
                    <div style={{ fontWeight: 'bold', fontSize: 18 }}>{`Total Orders: ${
                      reportData.reduce((pre, rd) => {
                        if (!pre.includes(rd.orderId)) {
                          pre.push(rd.orderId);
                        }
                        return pre;
                      }, []).length
                    }`}</div>
                  </div>
                </GridColumn>
                <GridColumn width={2}>
                  <ReportAssignModal
                    trigger={
                      <Button disabled={searchesToAssign.length === 0} onClick={() => setAssignModal(true)}>
                        Assign
                      </Button>
                    }
                    searchesToAssign={searchesToAssign}
                    onAssign={onAssign}
                    setAssignModalOpen={setAssignModal}
                    assignModalOpen={assignModal}
                  />
                </GridColumn>
                <GridColumn width={2}>
                  <ReportUnassignModal
                    trigger={
                      <Button
                        disabled={searchesToAssign.length === 0}
                        onClick={() => setUnassignModal(true)}
                        secondary
                      >
                        Unassign
                      </Button>
                    }
                    searchesToAssign={searchesToAssign}
                    onAssign={onAssign}
                    setUnassignModalOpen={setUnassignModal}
                    unassignModalopen={unassignModal}
                  />
                </GridColumn>
              </GridRow>
            </Grid>
            <div style={{ display: 'flex' }}>
              <Grid>
                <Grid.Row columns={2}>
                  <GridColumn width={12}>
                    <div className="report" ref={tableRef}>
                      {renderResults()}
                      <div css={totalResults}>{`Total Items: ${reportData.length}`}</div>
                    </div>
                  </GridColumn>
                </Grid.Row>
              </Grid>
              <div>{filesUploadedBy(reportData)}</div>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
