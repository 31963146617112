import { FieldsData } from '@kenjiuno/msgreader';
import axios from 'axios';
import { getMsgFileMetaData } from 'components/MsgReader';
import ImportedFileTag from 'components/new-order-entry/Documents/ImportedFileTag';
import TextField from 'components/ui/TextField';
import produce from 'immer';
import { convertToBase64 } from 'lib/PublicFunctions';
import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import AsyncSelect from 'react-select/async';
import PropertiesAndSearchesContin from './PropertiesAndSearchesContin';
import { customStyles, DocumentOptions, UploadButton } from './styles';
import { AddedSearches, AddSearchesViewModel, FileUpload, OrderDetails } from './types';
import { uniqBy } from 'lodash';
import { Checkbox, Dropdown, Input } from 'semantic-ui-react';
import Button from 'components/ui/Button';
import AssignmentDropdown from 'components/ui/AssignmentDropdown';
import colors from 'css/colors';

type ClientType = 'Accutitle' | 'Riverside' | '';

export default function UploadContin() {
  const [orderDetails, setOrderDetails] = useState<OrderDetails>({
    id: null,
    titleNumber: '',
    clientId: null,
    integrationType: null,
  });
  const [properties, setProperties] = useState<any[]>([]);
  const [allSearchesOfOrder, setAllSearchesOfOrder] = useState([]);
  const [files, setFiles] = useState<FileUpload[]>([]);
  const [msgData, setMsgData] = useState<FieldsData>();
  const [continReceiver, setContinReceiver] = useState('');
  const [assignTo, setAssignTo] = useState(null);
  const [isRush, setIsRush] = useState(false);
  const [sixMonthContin, setSixMonthContin] = useState(false);
  const [uniqueSearchesOfOrder, setUniqueSearchesOfOrder] = useState([]);
  const [assignees, setAssignees] = useState([]);
  const [propertyId, setPropertyId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [savePopup, setSavePopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [dueBy, setDueBy] = useState<string>(null);
  const [clientType, setClientType] = useState<ClientType>('');
  const [additionalSendEmails, setAdditionalSendEmails] = useState<number[]>([]);
  const [requestNotes, setRequestNotes] = useState('');

  useEffect(() => {
    if (orderDetails?.id) {
      getAccutitleInfo();
      getRiversideOrderInfo();
    }
  }, [orderDetails]);

  const onDrop = async (newFiles: any) => {
    const arr: FileUpload[] = [];
    for (let file of newFiles) {
      const base64 = (await convertToBase64(file)) as string;
      const tempFile: FileUpload = {
        file: base64,
        actualFileName: file.name,
      };
      const extension = file?.name?.substring(file?.name?.lastIndexOf('.'));

      arr.push(tempFile);
    }

    const newArray = produce(files, draft => {
      for (let file of arr) {
        draft.push(file);
      }
    });
    setFiles(newArray);
    const msgData = await getMsgFileMetaData(newFiles[0]);
    parseMsgFile(msgData);
    setMsgData(msgData);
  };

  const onRemoveFileClick = (index: any) => {
    setFiles(files.filter((f: any, i: any) => i !== index));
  };
  const changeCustomFileName = (index: number) => (newName: string) => {
    const newFiles = produce(files, draft => {
      const fileName = draft[index].actualFileName;
      const extension = fileName.substring(fileName.lastIndexOf('.'));
      var startExtension = newName.lastIndexOf('.');
      const newExtension = newName.substring(startExtension);
      const removedExtensionFromName = startExtension > -1 ? newName.substring(0, startExtension) : newName;
      draft[index].actualFileName = `${removedExtensionFromName}${
        startExtension === -1 || newExtension.length > 5 ? extension : newExtension
      }`;
    });
    setFiles(newFiles);
  };
  const getSearchesOfOrder = async () => {
    const { data: allSearches } = await axios.get(`/api/orders/getOrderSearches/${orderDetails.id}`);
    const { data: properties } = await axios.get(`/api/orders/getOrderProperties/${orderDetails.id}`);
    const completedSearches = allSearches?.filter((cs: any) => cs.searchStatusType === 'Completed');
    const propertiesWithSearches = properties.map((p: any) => {
      const searches = completedSearches.filter((s: any) => {
        return s.propertyId === p.id;
      });
      return {
        ...p,
        searches: searches.map((s: any) => {
          const searchesOfType = allSearches.filter(
            (all: any) => all.searchTypeId === s.searchTypeId && all.propertyId === s.propertyId
          );
          return { ...s, selectable: searchesOfType.every((search: any) => search.searchStatusId === 6) };
        }),
      };
    });
    setProperties(propertiesWithSearches);
    setAllSearchesOfOrder(
      completedSearches.map((cs: any) => ({
        ...cs,
        selected: false,
        accutitleNumber: null,
        riversideSearchId: null,
        selectable: allSearches
          .filter((all: any) => all.searchTypeId === cs.searchTypeId && all.propertyId === cs.propertyId)
          .every((search: any) => search.searchStatusId === 6),
      }))
    );
    setUniqueSearchesOfOrder(
      uniqBy(
        completedSearches.map((cs: any) => ({
          ...cs,
          selected: false,
          accutitleNumber: null,
          riversideSearchId: null,
        })),
        'searchTypeId'
      )
    );
  };
  const searchOrders = async (searchTerm: string, urlPart: string = 'simpleOrderSearch') => {
    const { data } = await axios.get(`/api/orders/${urlPart}?searchTerm=${searchTerm}`);
    return data.map((order: any) => ({
      ...order,
      label: `HDS${order.id} - ${order.titleNumber}`,
      value: order.id,
    }));
  };

  const parseMsgFile = async (fieldsData: FieldsData) => {
    const subject = fieldsData.subject;
    const promiseArray = subject.split(' ').map(async word => {
      const orders = await searchOrders(word, 'SearchOrderByTitleNumber');
      if (orders.length === 1) {
        return {
          order: orders[0],
          continReceiver:
            !fieldsData.senderEmail?.toLowerCase().includes('closingusa') &&
            !fieldsData.senderEmail?.toLowerCase().includes('no-reply') &&
            !fieldsData.senderEmail?.toLowerCase().includes('exchange') &&
            fieldsData.senderEmail?.includes('@')
              ? fieldsData.senderEmail
              : '',
        };
      } else {
        return null;
      }
    });
    const orders = await Promise.all(promiseArray);
    const orderFound = orders.find(o => o);
    if (orderFound) {
      setOrderDetails(orderFound.order);
      setContinReceiver(orderFound.continReceiver);
    }
  };
  const setSelectedSearch = (searchId: number, propertyId: number) => {
    const newSearches = produce(allSearchesOfOrder, draft => {
      const index = draft.findIndex(s => s.id === searchId);
      draft[index].selected = !draft[index].selected;
    });
    setAllSearchesOfOrder(newSearches);
    setPropertyId(propertyId);
  };
  const setSearchIntegrationId = (searchId: number, key: keyof AddedSearches, value: string) => {
    const newSearches = produce(allSearchesOfOrder, draft => {
      const index = draft.findIndex(s => s.id === searchId);
      draft[index][key] = value;
    });
    setAllSearchesOfOrder(newSearches);
    setPropertyId(propertyId);
  };
  const setSelectedSearches = (searchType: string, selected: boolean) => {
    const newSearches = produce(allSearchesOfOrder, draft => {
      const searchTypes = draft.filter(st => st.searchCode === searchType);
      for (let search of searchTypes) {
        const index = draft.findIndex(s => s.id === search.id);
        if (draft[index].selectable) {
          draft[index].selected = selected;
        }
      }
    });
    setAllSearchesOfOrder(newSearches);
  };
  const addOrderDoc = async () => {
    var orderDocBody = {
      fileName: 'Added Contin.pdf',
      files: files,
      addNotes: false,
    };
    if (files.length > 0) {
      await axios.post(`/api/orders/uploadOrderDocument/${orderDetails.id}`, orderDocBody);
    }
  };

  const submit = async () => {
    const searchesSelected = allSearchesOfOrder.filter(s => s.selected && s.selectable);
    const submitObj: AddSearchesViewModel = {
      searchTypeIds: uniqBy(
        searchesSelected.map(s => {
          return {
            calculateContinNumber: true,
            isSixMonthContin: sixMonthContin,
            searchTypeId: s.searchTypeId,
            priorityLevelId: isRush ? 1 : null,
            propertyId: s.propertyId,
            dueBy,
            accutitleNumber: s.accutitleNumber,
            riversideSearchId: s.riversideSearchId,
          } as AddedSearches;
        }),
        search => `${search.propertyId}${search.searchTypeId}`
      ),
      assigneeId: assignTo?.id,
      assigneeIsEmployee: assignTo?.vendorId > 0 ? false : true,
      continReceiver,
      fromContinUpload: true,
      requestNotes,
      additionalSendEmails,
    };
    if (searchesSelected.length === 0) {
      setErrorMessage('No Searches Selected');
      return;
    }
    if (!assignTo?.id) {
      setErrorMessage('No Assignee');
      return;
    }
    setIsLoading(true);
    await axios.post(`/api/orders/addSearches/${orderDetails.id}`, submitObj);
    addOrderDoc();
    setIsLoading(false);
    setOrderDetails({ id: null, titleNumber: '', clientId: null, integrationType: null });
    setAssignTo(null);
    setProperties([]);
    setIsRush(false);
    setSixMonthContin(false);
    setFiles([]);
    setContinReceiver('');
    setUniqueSearchesOfOrder([]);
    setAllSearchesOfOrder([]);
    setPropertyId(null);
    setSavePopup(true);
    setErrorMessage('');
    setDueBy('');
    setClientType('');
    setAdditionalSendEmails([]);
    setRequestNotes('');
    setTimeout(() => {
      setSavePopup(false);
    }, 1500);
  };

  const getRiversideOrderInfo = () => {
    if ([50, 439, 323, 321, 306].includes(orderDetails.clientId)) {
      setClientType('Riverside');
    }
  };

  const getAccutitleInfo = () => {
    if (orderDetails.integrationType === 3) {
      setClientType('Accutitle');
    }
  };

  const getAssignees = async () => {
    const usersPromise = axios.get('/api/users/forassignment');
    const vendorsPromise = axios.get('/api/vendors/getVendorsWithContacts');
    const [users, vendors] = await Promise.all([usersPromise, vendorsPromise]);

    setAssignees([
      { category: 'Employees', items: [...users.data] },
      { category: 'Vendors', items: [...vendors.data] },
    ]);
  };

  useEffect(() => {
    if (orderDetails?.id) {
      getSearchesOfOrder();
    }
    getAssignees();
  }, [orderDetails]);
  return (
    <>
      <div style={{ fontWeight: 'bold', fontSize: 24, paddingBottom: 16 }}>Upload Contin</div>
      <Dropzone onDrop={onDrop}>
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <DocumentOptions>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div style={{ textAlign: 'center' }}>
                  {isDragActive ? <span>drop here</span> : <span>Drag and drop files</span>}

                  <div style={{ color: '#e5e5ea', paddingTop: 8, paddingBottom: 8 }}>Or</div>
                  <UploadButton>Select File</UploadButton>
                </div>
              </div>
            </DocumentOptions>
          );
        }}
      </Dropzone>
      {files.length > 0 && <div style={{ paddingTop: 16 }}>File Uploaded to order: </div>}
      {files.map((file: any, index: any) => {
        return (
          <div key={file.fileName} style={{ marginLeft: '-200px' }}>
            <ImportedFileTag
              fileNameToDisplay={file.actualFileName || file.fileName}
              onRemoveClick={() => onRemoveFileClick(index)}
              changeCustomFileName={changeCustomFileName(index)}
            />
          </div>
        );
      })}
      <div style={{ display: 'flex' }}>
        <div style={{ flexDirection: 'column', marginRight: 16, marginTop: 16 }}>
          <span style={{ marginBottom: 8, display: 'inline-block' }}>File Number:</span>
          <AsyncSelect
            value={
              orderDetails?.id
                ? { value: orderDetails?.id, label: `HDS${orderDetails?.id} - ${orderDetails?.titleNumber}` }
                : null
            }
            loadOptions={searchTerm => searchOrders(searchTerm)}
            styles={customStyles}
            isClearable
            placeholder="Start Typing..."
            onChange={(e: any) => {
              setOrderDetails(e);
            }}
          />
        </div>
        {properties.length > 0 && (
          <TextField
            value={continReceiver}
            onChange={e => setContinReceiver(e.target.value)}
            label="Contin Receiver:"
            styles={{ marginTop: 16 }}
            width={'295px'}
          />
        )}
      </div>
      <PropertiesAndSearchesContin
        properties={properties}
        searches={allSearchesOfOrder}
        uniqueSearchesOfOrder={uniqueSearchesOfOrder}
        setSelectedSearch={setSelectedSearch}
        setSelectedSearches={setSelectedSearches}
        clientType={clientType}
        setSearchIntegrationId={setSearchIntegrationId}
      />
      {properties.length > 0 && (
        <div style={{ marginTop: 24 }}>
          <div style={{ display: 'flex', gap: 20 }}>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 16 }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                <label>Due By</label>
                <Input
                  style={{ width: 300, height: 48 }}
                  value={dueBy}
                  type="date"
                  onChange={e => setDueBy(e.target.value)}
                  fluid
                />
              </div>
            </div>
          </div>
          <div style={{ marginTop: 24, display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
              <AssignmentDropdown
                assignees={assignees}
                buttonText="Select employee/vendor"
                onClick={(e: any) => {
                  setAssignTo(e);
                }}
              />
            </div>
            <div style={{ width: 300, marginLeft: 16 }}>
              <Dropdown
                multiple
                selection
                search
                placeholder="Additional users"
                options={
                  (assignees || [])[0]?.items.map((i: any) => ({ value: i.id, text: i.name, key: i.id })) ||
                  []
                }
                onChange={(e, { value }) => setAdditionalSendEmails(value as number[])}
                closeOnChange
                fluid
                style={{ minHeight: 48 }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', marginTop: 24 }}>
            <div>
              <Checkbox
                label="Is Priority"
                checked={isRush}
                onChange={(e, { checked }) => setIsRush(checked)}
              />
            </div>
            <div style={{ marginLeft: 16 }}>
              <Checkbox
                label="Charge Full"
                checked={sixMonthContin}
                onChange={(e, { checked }) => setSixMonthContin(checked)}
              />
            </div>
          </div>
          {assignTo && (
            <div style={{ marginTop: 24 }}>
              <div style={{ marginBottom: 8 }}>Request Notes</div>
              <textarea
                style={{
                  resize: 'none',
                  borderRadius: 3,
                  width: 616,
                  height: 96,
                  border: `1px solid ${colors.grey}`,
                }}
                value={requestNotes}
                onChange={e => setRequestNotes(e.target.value)}
              ></textarea>
            </div>
          )}
        </div>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginRight: 60,
          marginTop: 30,
          position: 'relative',
        }}
      >
        {errorMessage && (
          <div style={{ color: '#ff5252', position: 'absolute', right: 0, top: -20 }}>{errorMessage}</div>
        )}
        <Button onClick={submit} loadingStatus={isLoading}>
          Submit
        </Button>
        {savePopup && (
          <div
            style={{
              color: '#fff',
              fontWeight: 'bold',
              position: 'absolute',
              bottom: -40,
              right: 50,
              backgroundColor: '#333',
              padding: 8,
              borderRadius: 4,
            }}
          >
            Saved!
          </div>
        )}
      </div>
    </>
  );
}
