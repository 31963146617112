import * as React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import DefaultLayout from './components/layout/DefaultLayout';
import OrdersPage from './components/orders/OrdersPage';
import OrderEntryPage from './components/order-entry/OrderEntryPage';
import SuccessPage from './components/order-entry/SuccessPage';
import SearchFormsRoutes from './components/forms/search-forms-routes';
import OrderInformation from './components/orders/order-information/OrderInformation';
import NewMunicipalityRoutes from './components/municipality-entry/new-municipality-routes';
import SearchLinksTest from './components/forms/SearchLinksTest';
import ClientsPage from './components/clients/ClientsPage';
import NotFound from './components/NotFound';
import InvalidInternalLogin from './components/InvalidInternalLogin';
import { UserContextProvider } from './UserContext';
import BillingContinsUpdates from './components/reports/BillingContinsUpdates';
import Reports from 'components/reports/Reports';
import SearchMapping from 'components/SearchMapping/Container';
import ProofingWorkspace from './components/ProofingWorkspace/Container';
import StreetReportForm from './components/StreetReportForm/Container';
import ArchivedData from './components/ArchivedData/Container';
import LegacyOrders from 'components/LegacyOrders';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { OrderEntryProvider } from 'components/new-order-entry/OrderEntryContext';
import OrderEntry from 'components/new-order-entry';
import UserPermissions from 'components/Users/UserPermissions';
import StagingBanner from 'components/layout/StagingBanner';
import UploadCenter from './components/UploadCenter';
import AbstractUploads from './components/UploadCenter/AbstractUploads/Uploads';
import FeedbackButton from 'components/layout/FeedbackButton';
import MergeClientContainer from './components/MergeClients/Container';
import { OrderNoteProvider } from 'components/orders/order-information/NewNotesTabSection/OrderNoteContext';
import MunicipalityFormComponents from 'components/MunicipalityFormComponents';
import { MunicipalityComponentsProvider } from 'components/MunicipalityFormComponents/MunicipalityComponentsContext';
import { SettingsContextProvider } from 'components/Settings/SettingsContext';
import Settings from 'components/Settings';
import RulesPage from 'components/ui/Rules';
import { RulesContextProvider } from 'components/ui/Rules/RulesContext';
import AdminSettings from 'components/ui/AdminSettings';
import NJUtilitiesPage from 'components/NjUtlities';
import RoadSearchersTaskList from 'components/reports/RoadSearchersTaskList/RoadSearchersTaskList';
import RoadSearchersTaxForm from 'components/forms/TaxSearches/RoadSearchersTaxForm';
import { RoadSearchersTaxFormProvider } from 'components/forms/TaxSearches/RoadSearchersTaxForm/RoadSearchersTaxFormContext';
import { OpenData } from 'components/OpenData';
import UploadContin from 'components/UploadCenter/UploadContin';
import ScrollButton from 'components/ui/ScrollButton';
import RoadSearchersPage from 'components/reports/RoadSearchers/RoadSearchersPage';
export const routes = (
  <Router>
    <DndProvider backend={HTML5Backend}>
      <UserContextProvider>
        <RulesContextProvider>
          <DefaultLayout>
            <StagingBanner />
            <Switch>
              <Route exact path="/" render={() => <Redirect to="/logininternal" />} />
              <Route exact path="/unauthorized" component={InvalidInternalLogin} />
              <Route exact path="/logininternal" render={() => <Redirect to="/Orders/1" />} />
              <Route path="/street-report" component={StreetReportForm} />
              <Route exact path="/Orders/new-order" component={OrderEntryPage} />
              <Route
                exact
                path="/new-order-entry"
                render={() => (
                  <OrderEntryProvider>
                    <OrderEntry />
                  </OrderEntryProvider>
                )}
              />
              <Route exact path="/Orders" render={() => <Redirect to="/Orders/1" />} />
              <Route
                exact
                path="/Orders/:page(\d\d?\d?)/:searchValue?/:secondPart?/:thirdPart?"
                component={OrdersPage}
              />
              <Route exact path="/Orders/new-order/success/:orderId/:error?" component={SuccessPage} />
              <Route
                exact
                path="/Orders/order-information/:orderId/:propertyId?"
                component={OrderInformation}
              />

              <Route
                exact
                path="/Orders/order-information/:orderId/:propertyId/:tab"
                component={OrderInformation}
              />
              <Route path="/searches/:propertyid" component={SearchFormsRoutes} />
              <Route path="/searchlinks" component={SearchLinksTest} />
              <Route path="/municipalities" component={NewMunicipalityRoutes} />
              <Route path="/proofingWorkspace/:orderId/:searchId" component={ProofingWorkspace} />
              <Route path="/clients/:page?" component={ClientsPage} />
              <Route path="/reports/billingContinsUpdates" component={BillingContinsUpdates} />
              <Route exact path="/reports" component={Reports} />
              <Route exact path="/reports/types/:type" component={Reports} />
              <Route exact path="/reports/types/:type/orders/:fromDate?/:toDate?" component={Reports} />
              <Route path="/map-searches" component={SearchMapping} />
              <Route exact path="/resources" component={ArchivedData} />
              <Route exact path="/archived-orders" component={LegacyOrders} />
              <Route exact path="/users" component={UserPermissions} />
              <Route exact path="/admin-settings" component={AdminSettings} />
              <Route exact path="/upload-center" component={UploadCenter} />
              <Route path="/merge-clients/:mappingType/:clientId/:orderId" component={MergeClientContainer} />
              <Route exact path="/abstract-uploads" component={AbstractUploads} />
              <Route exact path="/upload-contin" component={UploadContin} />
              <Route exact path="/nj-utilities" component={NJUtilitiesPage} />
              <Route exact path="/rules/:id?" component={RulesPage} />
              {/* <Route exact path="/road-searchers-task-list" component={RoadSearchersTaskList} /> */}
              <Route exact path="/road-searchers" component={RoadSearchersPage} />
              <Route
                exact
                path="/new-tax-form/:orderid/:propertyid"
                render={() => (
                  <RoadSearchersTaxFormProvider>
                    <RoadSearchersTaxForm />
                  </RoadSearchersTaxFormProvider>
                )}
              />
              <Route
                exact
                path="/muni-form-components"
                render={() => (
                  <MunicipalityComponentsProvider>
                    <MunicipalityFormComponents />
                  </MunicipalityComponentsProvider>
                )}
              />
              <Route
                exact
                path="/settingsPage"
                render={() => (
                  <SettingsContextProvider>
                    <Settings />
                  </SettingsContextProvider>
                )}
              />
              <Route exact path="/openData" render={() => <OpenData />} />
              <Route component={NotFound} />
            </Switch>
            <FeedbackButton />
          </DefaultLayout>
        </RulesContextProvider>
      </UserContextProvider>
      <ScrollButton />
    </DndProvider>
  </Router>
);
