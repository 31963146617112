/** @jsx jsx */
import { jsx } from '@emotion/core';
import { css } from 'emotion';
import * as React from 'react';
import { findDOMNode } from 'react-dom';
import colors from '../../css/colors';
import { GetDefaultAssignee } from '../../lib/PublicFunctions';
import { expandItems, searchBoxWrap } from '../order-entry/stepFourStyles';
import { LargeSearchGlassIcon } from '../ui/icons';
import { DownCarotIcon, RightCarotIcon } from './icons';

const dropdownWrap = {
  alignItems: 'center',
  border: `1px solid ${colors.grey}`,
  borderRadius: 5,
  color: colors.greyBorder,
  cursor: 'pointer',
  display: 'flex',
  height: 48,
  justifyContent: 'space-between',
  padding: '0 16px',
  width: '100%',
  '&:hover': {
    span: { color: colors.black },
    svg: { color: colors.black },
  },
};

const filterWrap = {
  backgroundColor: colors.white,
  display: 'none',
  left: '0',
  width: '100%',
  overflow: 'hidden',
  position: 'absolute',
  top: '64px',
  zIndex: '151',
};

const innerDropDown = {
  border: `1px solid ${colors.greyBorder}`,
  borderRadius: 5,
  padding: 24,
  //height: 500,
  maxHeight: 300,
  overflowY: 'scroll',
};

const openFilter = {
  position: 'absolute',
  display: 'block',
};

const labelWrap = {
  height: 40,
  display: 'block',
  lineHeight: '40px',
  paddingLeft: 16,
  borderRadius: 5,
  '&:hover': {
    backgroundColor: colors.greyOrder,
    cursor: 'pointer',
  },
};

const rotataIcon = {
  display: 'inline-block',
  transform: 'rotate(180deg)',
};

export default class AssignmentDropdown extends React.Component {
  state = {
    isDropdown: false,
    selectedItem: this.props.prevSelected || null,
    searchText: ''
  };

  componentDidMount = () => {
    this.assignDefault();
  };

  assignDefault = async () => {
    if (this.props.default === true && !this.props.prevSelected) {
      const assignee = await GetDefaultAssignee();
      if (!assignee) {
        return;
      }
      const { id, firstName, lastName } = assignee;
      const item = {
        categoryIndex: 0,
        employeeId: id,
        id: id,
        name: firstName + ' ' + lastName,
      };
      this.handleItemSelection(item);
    }
  };

  handleDropdown = () => {
    const { isDropdown } = this.state;
    this.setState({ isDropdown: !isDropdown });

    if (isDropdown === false) {
      document.addEventListener('mousedown', this.handleOutsideClick, false);
    } else {
      this.innerDropDown.scrollTo(0, 0);
      this.setState({ searchText: '' });
      document.removeEventListener('mousedown', this.handleOutsideClick, false);
    }
  };

  handleOutsideClick = e => {
    const domNode = findDOMNode(this.domNode);
    if (domNode && !domNode.contains(e.target)) {
      this.handleDropdown();
    }
  };

  handleSearchTextChange = searchText => {
    this.setState({ searchText });
  };

  handleItemSelection = item => {
    this.setState({ selectedItem: item });
    this.props.onClick(item);
  };

  render() {
    const { assignees, onClick, label, width, buttonText, expandedListId } = this.props;
    const fieldWidth = { width: width || 300 };
    const { isDropdown, selectedItem } = this.state;
    const isPlaceHolder = selectedItem || false;

    const getSelectedFirst = (list, selected, categoryIndex) => {
      return {
        ...list,
        items: [
          ...(selected && selected.categoryIndex === categoryIndex ? [selected] : []),
          ...list.items.filter(item => selected?.id !== item?.id),
        ],
      };
    };

    return (
      <>
      <div
        ref={node => (this.domNode = node)}
        css={[fieldWidth, { display: 'inline-block', verticalAlign: 'top' }]}
      >
        {label && <div css={{ marginBottom: 8 }}>{label}</div>}
        <div css={{ position: 'relative' }}>
          <div className={css(dropdownWrap)} onClick={() => this.handleDropdown()}>
            <p css={isPlaceHolder ? { color: colors.blackText } : { color: colors.greyBorder }}>
              {' '}
              {selectedItem?.name || buttonText || 'Select...'}
            </p>
            <span css={isDropdown && rotataIcon}>
              <DownCarotIcon />
            </span>
          </div>

          <div className={css(filterWrap, isDropdown && openFilter)}>
            <div className={css(innerDropDown)} ref={node => (this.innerDropDown = node)}>
              <div css={searchBoxWrap}>
                <LargeSearchGlassIcon />
                <input
                  type="text"
                  placeholder="Search"
                  onChange={e => this.handleSearchTextChange(e.target.value.toLowerCase())}
                  value={this.state.searchText}
                  autoFocus
                />
              </div>

              {assignees?.map((list, index) => (
                <div key={index}>
                  <ListItem
                    list={getSelectedFirst(list, selectedItem, index)}
                    selectedItem={selectedItem}
                    searchText={this.state.searchText}
                    isDropdown={isDropdown}
                    isExpanded={expandedListId === index}
                    categoryIndex={index}
                    handleOnClick={this.handleItemSelection}
                    handleDropdown={this.handleDropdown}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

class ListItem extends React.Component {
  state = {
    isExpanded: this.props.isExpanded,
    isDropdown: this.props.isDropdown,
    searchText: this.props.searchText,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { searchText, list, selectedItem } = nextProps;

    if (
      ((searchText !== '' || selectedItem) && !nextProps.isDropdown) ||
      nextProps.searchText !== prevState.searchText
    ) {
      const listContainsSelectedItem =
        selectedItem && list.items.some(item => item.name === selectedItem.name);
      const listContainsSearchText =
        searchText !== '' &&
        list.items.filter(item => item.name.toLowerCase().includes(searchText.toLowerCase())).length > 0;

      return { isExpanded: listContainsSelectedItem || listContainsSearchText };
    }
    return null;
  }

  render() {
    const { isExpanded } = this.state;
    const { list, selectedItem, searchText, categoryIndex, handleOnClick, handleDropdown } = this.props;
    return (
      <React.Fragment>
        <div css={expandItems} onClick={() => this.setState({ isExpanded: !isExpanded })}>
          {list.category}
          {isExpanded ? <DownCarotIcon /> : <RightCarotIcon />}
        </div>

        {isExpanded && (
          <React.Fragment>
            {list.items.map((item, index) => {
              item = { ...item, categoryIndex };
              if (item.name.toLowerCase().includes(searchText.toLowerCase())) {
                return (
                  <div key={index}>
                    <span
                      className={css(
                        labelWrap,
                        selectedItem?.id === item.id && {
                          color: colors.redLight,
                        }
                      )}
                      onClick={() => {
                        handleOnClick({
                          ...item,
                          [`${list.category.toLowerCase().slice(0, -1)}Id`]: item.id,
                        });
                        handleDropdown();
                      }}
                    >
                      {item.name}
                    </span>
                  </div>
                );
              }
            })}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
