/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component } from 'react';
import axios from 'axios';
import qs from 'qs';
import { Multiselect } from 'react-widgets';
import BinaryRadioButton, { RadioButton } from '../ui/BinaryRadioButton';
import Button from '../../ui/Button';
import MultiFileUpload from '../ui/MultiFileUpload';
import colors from '../../../css/colors';
import ActionButton from '../../ui/ActionButton';
import getBase64 from '../../FileUploads';
import { showNextButton, getNextLink } from '../FormStepperFunctions';
import { toolTipWrap, toolTip, selectStyles } from '../SharedFormStyles';
import withProperty from '../WithProperty';
import CurrencyField from '../../ui/CurrencyField';
import { Checkbox, Icon, Loader } from 'semantic-ui-react';
import WithDocumentStation from '../ui/DocumentStation/WithDocumentStation';
import SubmitFormButton from '../SubmitFormButton';
import withUser from 'lib/WithUser';
import Select from 'react-select';
import TextField from 'components/ui/TextField';
import { PlusIcon, XIcon } from 'components/ui/icons';
import produce from 'immer';
import ReusableTopOfForm from '../ReusableTopOfForm';
import BkInfoModal from './BkSearchComponents/BkInfoModal';
import CopyIconComponent from 'components/ui/CopyIconComponent';

const searchTypeId = 35;

const separatePdfClientIds = [315];

const districtOptions = [
  { value: 'East', label: 'East' },
  { value: 'North', label: 'North' },
  { value: 'South', label: 'South' },
  { value: 'West', label: 'West' },
];

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: 296,
  }),
  control: (provided, state) => ({
    ...provided,
    border: `1px solid ${colors.grey}`,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused && '1px solid #ff5252',
    height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};

class BankruptcySearch extends Component {
  state = {
    nameOptions: [],
    names: [],
    hasOpenBankruptcy: false,
    townFee: null,
    attachedFiles: [],
    loaderStatus: false,
    selectedCounty: {},
    selectAllNames: false,
    doNotCheck: false,
    searchId: 0,
    loadingNames: false,
    countyOptions: [],
    openBkModal: false,
    bkReportInfo: {},
    namesToAutomate: [
      {
        firstName: null,
        middleName: null,
        lastName: null,
        ran: false,
        loading: false,
        failed: false,
        id: Math.random(),
      },
    ],
  };

  componentDidMount = async () => {
    axios
      .get(`/api/searches/GetSearchByType/${searchTypeId}/${+this.props.match.params.propertyid}`)
      .then(({ data }) => {
        this.props.setSearchId(data.id);
        this.setState({ searchId: data.id });
      });
    const queryString = qs.stringify({ searchType: 'Bankruptcy' });
    this.setState({ loadingNames: true });
    const { data: savedData } = await axios.get(
      `/api/property/GetPropertyContacts/${+this.props.match.params.propertyid}?${queryString}`
    );
    const { data: property } = await axios.get(
      `/api/property/getProperty/${this.props.match.params.propertyid}`
    );
    const { data: countiesResponse } = await axios.get(`/api/municipalities/GetAllCounties`);
    const countyOptions = countiesResponse
      .filter(c => c.bankruptcyDistrict)
      .map(c => ({ ...c, value: c.Id, label: `${c.name} - ${c.bankruptcyDistrict}` }));
    const selectedCounty = countyOptions.find(c => c.id === property.countyId) || {};

    this.setState({
      nameOptions: savedData,
      selectedCounty: { ...selectedCounty, stateCode: property.stateCode },
      loadingNames: false,
      countyOptions,
      namesToAutomate: [
        { firstName: null, lastName: null, ran: false, loading: false, failed: false, id: Math.random() },
      ],
    });
  };

  onFilesAdded = files => {
    this.setState({ attachedFiles: [...this.state.attachedFiles, ...files] });
  };

  onFileRemoved = file => {
    this.setState({
      attachedFiles: this.state.attachedFiles.filter(f => f !== file),
    });
  };

  handlePreview = async () => {
    const { data: html } = await axios.post('/api/searches/CreatePDF', {
      template: 'namesearchtemplate',
      data: await this.getFormContent(),
    });
    this.setState({ pdfHtml: html });
  };

  handleDownload = async () => {
    this.setState({ loaderStatus: true });
    let data = await this.getFormContent();
    if (separatePdfClientIds.includes(this.props.property.clientId)) {
      for (let name of data.names) {
        await axios.post('/api/searches/DownloadPDF', {
          template: 'namesearchtemplate',
          data: { ...data, names: [name] },
        });
      }
    } else {
      await axios.post('/api/searches/DownloadPDF', {
        template: 'namesearchtemplate',
        data,
      });
    }

    const nextLink = getNextLink(
      this.props.property.orderId,
      searchTypeId,
      this.props.match.params.propertyid
    );
    if (nextLink == null) {
      this.props.history.push(`/orders/order-information/${this.props.property.orderId}`);
    } else {
      this.props.history.push(nextLink);
    }
    this.setState({ loaderStatus: false });
  };

  getAdditionalInfoText(county) {
    const baseInfo = 'U.S. Bankruptcy Court,';
    if (county?.stateCode === 'NJ') {
      return `${baseInfo} District of New Jersey`;
    }

    return `${baseInfo} ${county?.bankruptcyDistrict}ern District`;
  }

  getFormContent = async () => {
    const { attachedFiles, hasOpenBankruptcy, townFee, district, names, doNotCheck } = this.state;
    const promises = attachedFiles.map(async f => {
      const base64 = await getBase64(f);
      return { name: f.name, base64String: base64 };
    });
    const files = await Promise.all(promises);
    return {
      date: new Date(),
      searchType: 'Bankruptcy',
      fieldOne: {
        selected: !hasOpenBankruptcy,
        text:
          'There is no record of an open bankruptcy filing for the above mentioned individual, corporation or business.',
      },
      fieldTwo: {
        selected: hasOpenBankruptcy,
        text: `The following information has been found: ${hasOpenBankruptcy ? '(See attached)' : ''}`,
      },
      doNotCheck,
      additionalInfoTitle: 'The following office(s) have been checked:',
      additionalInfo: this.getAdditionalInfoText(this.state.selectedCounty),
      countyOverride: this.state.selectedCounty?.name,
      names,
      townFee,
      propertyId: +this.props.match.params.propertyid,
      searchTypeId,
      searchDocumentType: 'Bankruptcy Attachment',
      attachments: files,
      namesToAutomate: this.state.namesToAutomate,
    };
  };

  handleCreate(name) {
    const { nameOptions, names } = this.state;

    const newOption = {
      name,
      id: nameOptions.length + 1,
    };

    this.setState({
      names: [...names, newOption],
      nameOptions: [...nameOptions, newOption],
    });
  }
  handleDelete = async indexToRemove => {
    const newNamesToAutomate = this.state.namesToAutomate.filter((tl, index) => index !== indexToRemove);
    this.setState({ namesToAutomate: newNamesToAutomate });
  };
  handleRunBkAutomation = async () => {
    const next = produce(this.state.namesToAutomate, draft => {
      draft.map(d => ((d.loading = true), (d.ran = false)));
    });
    this.setState({ namesToAutomate: next });
    const splitSelectedCounty = this.state.selectedCounty?.label.split('-');
    const data = await axios.post(`/api/automation/runBkAutomation`, {
      namesToRun: this.state.namesToAutomate,
      orderId: this.props.property.orderId,
      propertyId: this.props.property.id,
      searchId: this.state.searchId,
      bkDistrict: splitSelectedCounty.length > 1 ? splitSelectedCounty[1].trim() : undefined,
    });
    this.setState({ openBkModal: true });
    const next2 = produce(this.state.namesToAutomate, draft => {
      draft.map(d => ((d.ran = true), (d.loading = false), (d.failed = data.status !== 200)));
    });
    this.setState({ namesToAutomate: next2, bkReportInfo: data });
  };
  handleNameChange = (value, label, id) => {
    const nextState = produce(this.state.namesToAutomate, draft => {
      const thisOne = draft.find(x => x.id === id);
      thisOne[label] = value;
    });
    this.setState({ namesToAutomate: nextState });
  };

  render() {
    const {
      names,
      nameOptions,
      hasOpenBankruptcy,
      townFee,
      officesChecked,
      pdfHtml,
      loaderStatus,
      doNotCheck,
    } = this.state;
    const showNext = showNextButton(this.props.match.params.orderId, searchTypeId);
    const params = new URLSearchParams(this.props.location.search);
    const isEdit = params.get('isEdit');
    return (
      <div css={{ display: 'flex', height: '100%' }}>
        <div css={{ width: 654 }}>
          <ReusableTopOfForm
            handlePreview={this.handlePreview}
            searchHeader="Bankruptcy Search"
            propertyId={+this.props.match.params.propertyid}
            searchTypeId={searchTypeId}
            property={this.props.property}
            getFormContent={this.getFormContent}
            template="namesearchtemplate"
            searchId={this.state.searchId}
          />
          <div css={{ marginBottom: 32 }}>
            <span
              css={{
                marginBottom: 8,
                fontWeight: 'bold',
                display: 'inline-block',
              }}
            >
              Names
            </span>
            {this.state.loadingNames ? (
              <div>
                <Loader active inline size="small" />
              </div>
            ) : (
              <RadioButton
                checked={this.state.selectAllNames}
                text="Select All Names"
                addedStyles={{ marginBottom: 10 }}
                onChange={() => {
                  const stateValue = !this.state.selectAllNames;
                  if (stateValue) {
                    this.setState({ names: nameOptions });
                  }
                  this.setState({ selectAllNames: stateValue });
                }}
              />
            )}
            <div css={selectStyles}>
              <Multiselect
                value={names}
                data={nameOptions}
                onChange={editedNames => this.setState({ names: editedNames })}
                onCreate={name => this.handleCreate(name)}
                allowCreate="onFilter"
                valueField="id"
                textField="name"
                tagComponent={({ item }) => {
                  return (
                    <div style={{ display: 'flex', gap: 8 }}>
                      <div>{item.name}</div>
                      <Icon
                        style={{ cursor: 'pointer' }}
                        name="copy outline"
                        onClick={() => {
                          navigator.clipboard.writeText(item.name);
                        }}
                      />
                    </div>
                  );
                }}
              />
            </div>
            <div style={{ display: 'flex', gap: 8, marginTop: 16, flexDirection: 'column' }}>
              <div>Bankruptcy County/District</div>
              <Select
                options={this.state.countyOptions}
                value={this.state.selectedCounty}
                onChange={selectedCounty => this.setState({ selectedCounty })}
                styles={customStyles}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span
                css={{
                  marginBottom: 8,
                  marginTop: 32,
                  fontWeight: 'bold',
                  display: 'inline-block',
                }}
              >
                Names To Automate
              </span>
              {this.state.namesToAutomate?.map((na, idx) => {
                return (
                  <div style={{ display: 'flex', gap: 8, marginTop: idx > 0 ? 16 : 0 }} key={na.id}>
                    <div
                      style={{ marginTop: 14, cursor: 'pointer', zIndex: 50 }}
                      onClick={() => this.handleDelete(idx)}
                    >
                      <XIcon />
                    </div>
                    <TextField
                      placeholder="First Name"
                      value={na.firstName}
                      onChange={e => this.handleNameChange(e.target.value, 'firstName', na.id)}
                    />
                    <TextField
                      placeholder="Middle Name"
                      value={na.middleName}
                      onChange={e => this.handleNameChange(e.target.value, 'middleName', na.id)}
                      width="170px"
                    />
                    <TextField
                      width="270px"
                      placeholder="Last/Business/Estate Name"
                      value={na.lastName}
                      onChange={e => this.handleNameChange(e.target.value, 'lastName', na.id)}
                    />
                    {na.ran && !na.failed && (
                      <div>
                        <div style={{ marginTop: 14 }}>
                          <Icon name="check" color="green" />
                        </div>
                      </div>
                    )}
                    {na.ran && na.failed && (
                      <div style={{ marginTop: 14 }}>
                        <Icon name="x" color="red" />
                      </div>
                    )}
                    {na.loading && (
                      <div style={{ marginTop: 14 }}>
                        <Loader inline size="small" active />
                      </div>
                    )}
                    {this.state.openBkModal && (
                      <BkInfoModal
                        openState={this.state.openBkModal}
                        onClose={() => this.setState({ openBkModal: false })}
                        info={this.state.bkReportInfo.data}
                      />
                    )}
                  </div>
                );
              })}
              <div style={{ display: 'flex', marginTop: 16 }}>
                <div
                  style={{ display: 'flex', color: 'red', cursor: 'pointer' }}
                  onClick={() =>
                    this.setState({
                      namesToAutomate: [
                        ...this.state.namesToAutomate,
                        { firstName: null, lastName: null, id: Math.random() },
                      ],
                    })
                  }
                >
                  Add Names
                </div>
                {this.state.namesToAutomate.length > 1 && (
                  <div
                    onClick={() =>
                      this.setState({
                        namesToAutomate: [
                          {
                            firstName: null,
                            middleName: null,
                            lastName: null,
                            ran: false,
                            loading: false,
                            failed: false,
                            id: Math.random(),
                          },
                        ],
                      })
                    }
                    style={{ color: '#333', cursor: 'pointer', marginLeft: 40, fontWeight: 'bold' }}
                  >
                    Clear All Names
                  </div>
                )}
              </div>
            </div>

            <div style={{ display: 'flex' }}>
              <div
                style={{
                  border: '1px solid #E5E5EA',
                  borderRadius: '10px',
                  width: 'fit-content',
                  padding: '8px 16px',
                  height: 'fit-content',
                  marginTop: 10,
                  cursor: 'pointer',
                }}
                onClick={() => this.handleRunBkAutomation()}
              >
                Search Court Records
              </div>
              {this.state.bkReportInfo.data && (
                <div
                  style={{
                    border: '1px solid #E5E5EA',
                    borderRadius: '10px',
                    width: 'fit-content',
                    padding: '8px 16px',
                    height: 'fit-content',
                    marginTop: 10,
                    cursor: 'pointer',
                    marginLeft: 8,
                  }}
                  onClick={() => this.setState({ openBkModal: true })}
                >
                  See Results
                </div>
              )}
            </div>
          </div>

          <Checkbox
            checked={doNotCheck}
            label={'Do not check'}
            onChange={() => this.setState({ doNotCheck: !doNotCheck })}
          />
          {!doNotCheck && (
            <BinaryRadioButton
              headerText="Have any open bankruptcies been found?"
              trueText="Yes"
              falseText="No"
              checkedState={hasOpenBankruptcy}
              onChange={checked => this.setState({ hasOpenBankruptcy: checked })}
            />
          )}
          <div>
            <CurrencyField
              width="296px"
              label="Town Fee"
              styles={{ marginTop: 24 }}
              placeholder=""
              value={townFee}
              onChange={v => this.setState({ townFee: v })}
            />
          </div>
          {this.state.searchId > 0 && <WithDocumentStation searchId={this.state.searchId} />}
          <div css={{ marginTop: 43, display: 'flex', justifyContent: 'flex-end' }}>
            <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                secondary
                onClick={() =>
                  this.props.history.push(`/orders/order-information/${this.props.property.orderId}`)
                }
                disabled={loaderStatus}
              >
                Cancel
              </Button>
              {/* <div css={{ marginLeft: 8 }}>
                <Button onClick={this.handleDownload} loadingStatus={loaderStatus}>
                  {showNext ? 'Next' : `${isEdit ? 'Save' : 'Submit'}`}
                </Button>
              </div> */}
              <div css={{ marginLeft: 8 }}>
                <SubmitFormButton
                  searchTypeId={searchTypeId}
                  onSubmit={this.handleDownload}
                  reactFormal={false}
                  userId={this.props.user.id}
                  orderId={this.props.property.orderId}
                  loadingStatus={loaderStatus}
                  buttonText={showNext ? 'Next' : `${isEdit ? 'Save' : 'Submit'}`}
                  propertyId={+this.props.match.params.propertyid}
                  getFormContent={this.getFormContent}
                />
              </div>
            </div>
          </div>
        </div>
        <div css={{ width: '100%' }}>
          <iframe style={{ width: '100%', height: '100%' }} srcDoc={pdfHtml} />
        </div>
      </div>
    );
  }
}

export default withUser(withProperty(BankruptcySearch, searchTypeId, false, true));
